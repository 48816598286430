var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "md-title" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c(
          "div",
          {
            staticClass:
              "ml-auto d-flex align-items-center justify-content-center",
          },
          [_c("div", { staticClass: "p-2" }, [_c("edit-2-icon")], 1)]
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", { staticClass: "d-flex my-2" }, [
          _vm._v(_vm._s(_vm.dateParse(_vm.component.endDate))),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "d-flex my-2 font-italic" }, [
          _vm._v(
            _vm._s(
              _vm.component.project
                ? _vm.component.project.name
                : "Project Name"
            )
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex component-badges my-2" }),
      _vm._v(" "),
      _c("div", { staticClass: "my-2" }, [
        _c("p", [
          _vm._v(
            _vm._s(
              _vm.component.project
                ? _vm.component.project.description
                : "Description"
            )
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex" }, [
      _c("div", [
        _c(
          "span",
          [
            _vm.component.type &&
            _vm.component.type.toLowerCase() === _vm.STATUS_CODERS_FIXED_PRICE
              ? _c("tag-icon", { staticClass: "p-1" })
              : _c("inbox-icon", { staticClass: "p-1" }),
            _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.component.type) + "\n\t\t\t\t"),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ml-auto" }, [
        _c("h1", { staticClass: "font-accent" }, [
          _vm._v(
            "$" + _vm._s(_vm.component.dayRate || _vm.component.fixedPrice)
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }