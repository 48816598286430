<template>
  <div class="card py-4 pl-2 user-data">
    <div class="d-flex">
      <h4 class="mx-1 font-accent">Total Earnings <span style="font-size: 14px">(Last 12 Month)</span></h4>
    </div>
    <div class="d-flex justify-content-center p-4">
      <h1 class="font-semibold font-accent"><span class="px-2">$</span>{{payment.total.toLocaleString()}}</h1>
    </div>
    <div class="p-2">
      <div class="mx-auto d-flex">
        <highcharts :options="chartOptions" class="payment-chart"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Chart
  } from 'highcharts-vue'

  export default {
    props: ['payment'],
    data: function() {
      return {
        lastPaidMonthsCount: this.payment.data["amounts"].length,
        chartOptions: {
          credits: {
            enabled: false
          },
          chart: {
            height: 250,
            type: 'column',
            backgroundColor: 'transparent',
            marginLeft: 0,
          },
          title:{
            text: ''
          },
          plotOptions: {
            column: {
              pointWidth: 10,
              pointPadding: 5,
              borderWidth: 0,
              color: '#F79A00',
              borderRadius: 3
            }
          },
          series: [{
            showInLegend: false,
            data: this.payment.data['amounts']
          }],
          xAxis: {
            categories: this.payment.data['periods'],
            tickWidth: 0,
            title: {text: 'Last '+this.lastPaidMonthsCount+' Months'},
            labels: {
              //step: 2,
              style: {
                color: '#ffffff'
              }
            }
          },
          yAxis: {
            labels: {
              enabled: false,
            },
            gridLineWidth: 0
          }
        }
      }
    },
    components: {
      Chart,
    },
    name: 'CoderPaymentInfo',
    watch: {
      setChartOpt(){
        this.chartOptions.series[0].data = this.payment.data["amounts"];
        this.chartOptions.xAxis.categories = this.payment.data["periods"];
        this.chartOptions.xAxis.title.text = this.payment.data["periods"].length  === 1 ? "Last Month earnings" : (this.payment.data["periods"].length ? "Last "+this.payment.data["periods"].length+" Month(s) earnings" : "");
      }
    },
    computed: {
      setChartOpt(){
        this.chartOptions.series[0].data = this.payment.data["amounts"];
        this.chartOptions.xAxis.categories = this.payment.data["periods"];
        this.chartOptions.xAxis.title.text = this.payment.data["periods"].length  === 1 ? "Last Month earnings" : (this.payment.data["periods"].length ? "Last "+this.payment.data["periods"].length+" Month(s) earnings" : "");
      }
    },
  };
</script>
