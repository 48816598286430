<template>
  <div class="auth-wrap">
    <div class="auth-content center">
      <div class="auth-container reset">
        <div class="auth-logo-wrap">
          <img src="/images/logo.png" />
        </div>
        <div class="auth-form">
          <div v-if="isLoading" class="text-center py-4">
            <b-spinner label="Spinning"></b-spinner>
          </div>
          <b-alert variant="info" :show="isLoading" class="text-center">Validating Token ...</b-alert>
          <b-alert variant="danger" :show="!isLoading && hasError" class="text-center">Token Not Valid!</b-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'confirmToken',
    computed: {
      isLoading() {
        return this.$store.getters['security/isLoading'];
      },
      hasError() {
        return this.$store.getters['security/hasError'];
      },
      error() {
        return this.$store.getters['security/error'];
      },
    },
    mounted() {
      if (this.$route.params.token) {
        this.$store.dispatch('security/confirmCoderToken', {
          token: this.$route.params.token,
          callback: (res) => {
            //return to reset password
            if(res.data.confirmCoderToken.asAccepted){
              this.$router.push({
                path: '/reset-password'});
            }
            else {
              this.$router.push({
                path: '/terms-condition'
              });
            }
          }
        })
      } else {
        this.$router.push({
          path: '/'
        });
      }
    },
  }
</script>
