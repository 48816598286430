import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Connection from '../views/page/security/Connection';
import TermsCondition from '../views/page/security/TermsCondition';
import ResetPassword from '../views/page/security/ResetPassword';
import ConfirmToken from '../views/page/security/ConfirmToken';
import DashboardUsers from '../views/page/admin/Users';
import AdminContainer from '../views/page/admin/AdminContainer';
import DashboardAdmin from '../views/page/admin/Dashboard';
import CoderDashboard from '../views/page/admin/CoderDashboard';
import CreateCoder from '../views/page/admin/CreateCoder';
import CreateComponent from '../views/page/admin/CreateComponent';
import EditComponent from '../views/page/admin/EditComponent';
import DashboardComponents from '../views/page/admin/Components';
import DashboardPayer from '../views/page/payer/Dashboard';
import CoderContainer from '../views/page/coder/CoderContainer';
import DashboardCoder from '../views/page/coder/Dashboard';
import AcceptComponent from '../views/page/coder/AcceptComponent';
import CoderProfile from '../views/page/coder/CoderProfile';
import CoderComponent from '../views/page/coder/CoderComponent';
import ConfirmEmail from '../views/page/security/ConfirmEmail';
import DashboardPayment from '../views/page/payer/payment.vue'
import DashboardInvoice from '../views/page/payer/invoice'
import InvoiceCoder from "../views/page/coder/InvoiceCoder";

Vue.use(VueRouter);

let router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/connection',
      name: 'connection',
      component: Connection
    },
    {
      path: '/disconnection',
      name: 'logout'
    },
    {
      path: '/reset/confirm-email',
      name: 'confirmEmail',
      component: ConfirmEmail,
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: ResetPassword
    },
    {
      path: '/confirmation/:token',
      name: 'confirmToken',
      component: ConfirmToken,
    },
    {
      path: '/dashboard',
      component: AdminContainer,
      meta: {
        requiresAuth: true,
        roles: ['admin', 'project_manager']
      },
      children: [
        {
          path: '',
          name: 'admin',
          component: DashboardAdmin,
          meta: {
            requiresAuth: true,
            roles: ['admin', 'project_manager']
          },
        },
        {
          path: 'users',
          name: 'dashboardUsers',
          component: DashboardUsers,
          meta: {
            requiresAuth: true,
            roles: ['admin', 'project_manager']
          },
        },
        {
          path: '/components',
          name: 'dashboardComponents',
          component: DashboardComponents,
          meta: {
            requiresAuth: true,
            roles: ['admin', 'project_manager']
          }
        },
        {
          path: 'create-coder',
          name: 'createCoder',
          component: CreateCoder,
          meta: {
            requiresAuth: true,
            roles: ['admin'],
            rights: ['manage.users']
          },
        },
        {
          path: 'edit-coder/:uuid',
          name: 'editCoder',
          component: CreateCoder,
          meta: {
            requiresAuth: true,
            roles: ['admin'],
            rights: ['manage.users']
          },
        },
        {
          path: 'create-component',
          name: 'createComponent',
          component: CreateComponent,
          meta: {
            requiresAuth: true,
            roles: ['admin', 'project_manager'],
            rights: ['manage.components', 'own.component']
          },
        },
        {
          path: 'edit-component/:uuid',
          name: 'editComponent',
          component: EditComponent,
          meta: {
            requiresAuth: true,
            roles: ['admin', 'project_manager'],
            rights: ['manage.components', 'own.component']
          },
        },
        {
          path: 'preview-coder/:uuid',
          name: 'coderDashboard',
          component: CoderDashboard,
          meta: {
            requiresAuth: true,
            roles: ['admin', 'project_manager'],
            rights: ['manage.components', 'own.component']
          },
        }
      ]
    },
    {
      path: '/coder',
      component: CoderContainer,
      meta: {requiresAuth: true, roles: ['coder']},
      children: [
        {
          path: '',
          name: 'coder',
          component: DashboardCoder,
          meta: {
            requiresAuth: true,
            roles: ['coder']
          },
        },
        {
          path: 'profile',
          name: 'profile',
          component: CoderProfile,
          meta: {
            requiresAuth: true,
            roles: ['coder']
          },
        },
        {
          path: 'contract/:id/:notification_id',
          name: 'contract',
          component: AcceptComponent,
          meta: {
            requiresAuth: true,
            roles: ['coder']
          },
        },
        {
          path: 'component/:uuid',
          name: 'coderComponent',
          component: CoderComponent,
          meta: {
            requiresAuth: true,
            roles: ['coder']
          },
        }
      ]
    },
    {
      path: '/payer',
      name: 'dashboardPayer',
      component: DashboardPayer,
      meta: {requiresAuth: true, roles: ['admin', 'payer']},
      children: [
        {
          path: 'payment',
          name: 'payment',
          component: DashboardPayment,
          meta: {requiresAuth: true, roles: ['admin', 'payer']}
        },
        {
          path: 'invoice',
          name: 'invoice',
          component: DashboardInvoice,
          meta: {requiresAuth: true, roles: ['admin', 'payer']}
        },
        {path: '*', redirect: {name: 'payment'}}
      ]
    },
    {
      path: '/invoice',
      name: 'InvoiceCoder',
      component: InvoiceCoder,
    },
    {
      path: '/terms-condition',
      name: 'termscondition',
      component: TermsCondition,
      meta: {requiresAuth: true}
    },
    {path: '*', redirect: 'connection'}
  ]
});

router.beforeEach((to, from, next) => {
  if (to.name === 'logout') {
    store.dispatch('security/logout');
    next({
      path: '/connection'
    });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['security/isAuthenticated'] && store.getters['security/isAgreed']) {
      const role = store.getters['security/getRole'];
      const rights = store.getters['security/getRights'];

      const toRoles = to.meta.roles || [];

      if (toRoles.indexOf(role) > -1) {
        if (to.meta.rights) {
          if (to.meta.rights.filter(value => rights.includes(value))) {
            next();
          } else {
            next({
              path: '/dashboard',
            });
          }
        } else {
          next();
        }
      } else {
        if (role === 'coder') {
          if (to.name === 'editComponent') {
            Promise.all([
              store.dispatch('coders/getCoderComponent'),
              store.dispatch('coders/getAdmins'),
            ]).then(() => {
              next({
                path: '/coder/component/' + to.params.uuid
              });
            });
          } else {
            next({
              path: '/coder',
            });
          }

        } else if (role === 'payer') {
          next({
            path: '/payer',
          });
        } else if (['admin', 'project_manager'].indexOf(role) > -1) {
          if (to.name === 'coderComponent') {
            console.log('you are lickign thiest');
            let uuid = to.params.uuid;
            Promise.all([
              store.dispatch('coders/getCoders'),
            ]).then(() => {
              next({
                path: '/dashboard/edit-component/' + uuid
              });
            });

          } else {
            next({
              path: '/dashboard',
            });
          }
        } else {
          next({
            path: '/connection',
            query: {redirect: to.fullPath}
          });
        }
      }

    } else if (store.getters['security/isAuthenticated'] && !store.getters['security/isAgreed']) {
      if (to.path !== '/terms-condition') {
        next({
          path: '/terms-condition',
        });
      } else {
        next();
      }
    } else {
      next({
        path: '/connection',
        query: {redirect: to.fullPath}
      });
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
