var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-form", { on: { submit: _vm.onSavePayment } }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _vm._l(_vm.payments, function (payment, index) {
              return _c(
                "div",
                { key: index, staticClass: "row mb-3 align-items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-8" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-hours",
                            "label-cols": "4",
                            "label-cols-lg": "4",
                            label: "Total Hours",
                            "label-for": `payment_hours_${index}`,
                            state: _vm.validateState(`payment_hours_${index}`),
                            "invalid-feedback": _vm.validationMsg.hours,
                          },
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true, decimal: true },
                                expression: "{ required: true, decimal: true }",
                              },
                            ],
                            staticClass: "px-2",
                            attrs: {
                              id: `payment_hours_${index}`,
                              name: `payment_hours_${index}`,
                              state: _vm.validateState(
                                `payment_hours_${index}`
                              ),
                              placeholder: "Enter Hours",
                            },
                            model: {
                              value: _vm.payments[index].hours,
                              callback: function ($$v) {
                                _vm.$set(_vm.payments[index], "hours", $$v)
                              },
                              expression: "payments[index].hours",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-2" }, [
                    _vm._v(
                      "\n          $" +
                        _vm._s(_vm.totalPrice(index)) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c("trash-2-icon", {
                        staticClass: "trash-icon",
                        on: {
                          click: function ($event) {
                            return _vm.onRemovePayment(index)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "float-right m-2 add-payment-button",
                attrs: { variant: "primary" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onAddPayment.apply(null, arguments)
                  },
                },
              },
              [_c("plus-icon")],
              1
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "w-100",
          attrs: { slot: "modal-footer" },
          slot: "modal-footer",
        },
        [
          _c(
            "b-button",
            {
              staticClass: "float-right m-2",
              attrs: { type: "submit", variant: "primary", size: "sm" },
              on: { click: _vm.onSavePayment },
            },
            [_vm._v("\n      Save\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "float-right m-2",
              attrs: { size: "sm" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onCancelPayment.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }