<template>
  <div class="">
    <div class="d-flex  align-items-center page-title mb-3">
      <div>
        <button class="btn btn-secondary btn-back" @click.prevent="onBack">
          <arrow-left-icon class="custom-class"></arrow-left-icon>Back
        </button>
      </div>
      <div class="ml-4">
        <h2 class="dash-header-title m-0" v-if="forCreate">Create User</h2>
        <h2 class="dash-header-title m-0" v-if="!forCreate">Edit User</h2>
      </div>
    </div>
    <div class="border-bottom my-3"></div>
    <user-form
        v-if="forCreate"
        viewer="admin"
        :editMode="false"
        :error="error"
        v-on:submit="onSubmit"
    ></user-form>
    <user-form
        v-else
        viewer="admin"
        :user="editCoder"
        :editMode="true"
        :error="error"
        v-on:submit="onSubmit"
    ></user-form>
  </div>
</template>

<script>
import UserForm from '../../../components/admin/UserForm';
import {
  ArrowLeftIcon
} from 'vue-feather-icons';

  export default {
    name: 'CreateCoder',
    components: {
      UserForm,
      ArrowLeftIcon
    },
    data() {
      return {
        forCreate: true
      }
    },
    created() {
      if (this.$route.params.uuid) {
        this.$store.dispatch('coders/getCoderById', {uuid: this.$route.params.uuid});
        this.forCreate = false
      }
    },
    computed: {
      hasError() {
        return this.$store.getters['coders/hasError'];
      },
      error() {
        return this.$store.getters['coders/error'];
      },
      editCoder() {
        return this.$store.getters['coders/getCoder']
      },
    },
    methods: {
      onAccept (e) {
        const maskRef = e.detail;
        this.coder.birthday = maskRef.value;
      },
      onSubmit(coder) {
        this.$store.dispatch('coders/saveCoder', {
          coder,
          callback: () => {
            this.$router.push({
              name: 'dashboardUsers'
            });
          }
        });
      },
      onBack() {
        this.$router.go(-1);
      },
    }
  }
</script>
