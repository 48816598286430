<template>
  <div class="d-flex h-100 admin-wrap">
    <admin-menu class="active"></admin-menu>
    <div class="admin-dash flex-grow-1 flex-shrink-1">
      <dash-header></dash-header> 
      <div class="admin-inner" v-bind:class="[isPayer ? 'payer-margin' : 'pb-5']">
          <div class="container">
              <slot/>
          </div>
      </div>
    </div>

  </div>
</template>
<script>
import AdminMenu from '../components/admin/AdminMenu'
import DashHeader from '../components/Header'
import DashFooter from "../components/Footer";
export default {
    props:['pageName', 'mode'],
    name:'AdminWrapper',
    components:{
        DashFooter,
        DashHeader,
        AdminMenu,     
    },
    computed: {
        isAdmin: function(){
            return this.$store.getters['security/hasRole']('admin');
        },

        isPayer: function(){
            return this.$store.getters['security/hasRole']('payer');
        }
    }
}
</script>
