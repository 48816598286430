var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "navbar d-flex justify-content-center mt-3" }, [
      _c("div", { staticClass: "navbar-brand mx-auto" }, [
        _c(
          "a",
          {
            attrs: { href: "/" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goHome.apply(null, arguments)
              },
            },
          },
          [
            _c("img", {
              staticClass: "brand-logo",
              attrs: { src: "/images/logo.png", alt: "logo" },
            }),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "normal-page flex-grow-1 flex-shrink-1" }, [
      _c("div", { staticClass: "container" }, [_vm._t("default")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }