<template>
  <div class="container">
    <div class="content d-flex flex-column justify-content-center">
      <div class="d-flex justify-content-between align-items-center page-title">
        <button class="btn btn-secondary btn-back" @click="onBack">
          <arrow-left-icon class="custom-class"></arrow-left-icon>Back
        </button>
        <h1 class="form-title display-5 text-center">Edit Profile</h1>
      </div>
      <user-form
        viewer="coder"
        :user="profile"
        v-on:submit="onSubmit"
        v-on:back="onBack"
      ></user-form>
    </div>
  </div>
</template>

<script>
import UserForm from '../../../components/admin/UserForm';
import { ArrowLeftIcon } from 'vue-feather-icons';

  export default {
    name: 'CreateCoder',
    components: {
      UserForm,
      ArrowLeftIcon,
    },
    computed: {
      profile(){
        return {};
      }
    },
    methods: {
      onAccept (e) {
        const maskRef = e.detail;
        this.coder.birthday = maskRef.value;
      },
      onSubmit(coder) {
        this.$store.dispatch('coders/saveCoder', {
          coder,
          callback: () => {
            this.$router.push({
              path: '/coder'
            });
          }
        });
      },
      onBack() {
        this.$router.push({path: '/coder'});
      },
    }
  }
</script>
