<template>
    <admin-wrapper page-name="Payer">
        <dashboard-header name="Payer">
            <template slot="actions">
                <button v-if="isInvoice" class="btn btn-primary px-3" @click="exportInvoices()">
                    <file-text-icon></file-text-icon>
                    Export invoices
                </button>
                <button v-if="isInvoice" class="btn btn-primary px-3" @click="downloadInvoices()">
                    <arrow-down-icon></arrow-down-icon>
                    Download invoices
                </button>
            </template>
        </dashboard-header>
        <div class="row">
            <h3 class="col-6">
                <router-link :class="!isPayment ? 'active-color' : 'inactive-color'" :to="{name:'payment'}">
                    Payments
                </router-link>
            </h3>
            <h3 class="col-6">
                <router-link :class="!isInvoice ? 'active-color' : 'inactive-color'" :to="{name:'invoice'}">
                    Invoices
                </router-link>
            </h3>
        </div>
        <router-view/>
    </admin-wrapper>
</template>
<script>


  import {mapState} from "vuex";
  import moment from "moment";
  import {ArrowDownIcon, FileTextIcon} from "vue-feather-icons";

  export default {
    components: {
      ArrowDownIcon,
      FileTextIcon
    },
    name: "DashboardPayment",
    created() {
      this.initFilters();
    },
    computed: {
      ...mapState('invoice', [
        'idFilter',
        'coderFilter',
        'amountFilter',
        'dateFilter',
        'projectFilter'
      ]),
      isPayment() {
        return this.$route.name === 'payment';
      },
      isInvoice() {
        return this.$route.name === 'invoice';
      },
      startDate() {
        return this.dateFilter[0];
      },
      endDate() {
        return this.dateFilter[1];
      }
    },
    methods: {
      initFilters() {
        this.$store.commit('invoice/SET_ID_FILTER', '');
        this.$store.commit('invoice/SET_AMOUNT_FILTER', null);
        this.$store.commit('invoice/SET_DATE_FILTER', [null, null]);
      },
      downloadInvoices() {
        window.open(`/api/download-invoices?id=${this.idFilter}&coder=${this.coderFilter}&amount=${this.amountFilter}&startDate=${this.getStartDate()}&endDate=${this.getEndDate()}&project=${this.projectFilter}`);
      },
      getStartDate() {
        return this.dateFilter[0] !== null ? moment(this.dateFilter[0]).toISOString() : '';
      },
      getEndDate() {
        return this.dateFilter[1] !== null ? moment(this.dateFilter[1]).toISOString() : '';
      },
      exportInvoices() {
        window.open(`/api/export-invoices?id=${this.idFilter}&coder=${this.coderFilter}&amount=${this.amountFilter}&startDate=${this.getStartDate()}&endDate=${this.getEndDate()}&project=${this.projectFilter}`);
      }
    }
  }

</script>

