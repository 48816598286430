<template>
  <div class="my-5 d-flex metrics-board ">
    <div class="row" v-if="error">
      <b-alert show variant="danger">{{error}}</b-alert>
    </div>
    <metric-item v-for="metric in metrics" :key="metric.key" :metric="metric" :isLoading="isLoading"></metric-item>
  </div>
</template>

<script>
  import MetricItem from './MetricItem';
  import {
    BarChart2Icon,
    UsersIcon,
    LayersIcon,
    InboxIcon,
    TagIcon,
  } from 'vue-feather-icons';
  import {
    CREATE_COMPONENT,
    ASSIGN_COMPONENT,
    CODERS_DELEGATION_SKILLS,
    CODERS_FIXED_PRICE
  } from '../../constants';
  
  export default {
    components: {
      MetricItem,
      BarChart2Icon,
      UsersIcon,
      LayersIcon,
      InboxIcon,
      TagIcon,
    },
    name: 'DashboardMetrics',
    computed: {
      isLoading() {
        return this.$store.getters['metrics/isLoading'];
      },
      error() {
        return this.$store.getters['metrics/error'];
      },
      metrics() {
        const metrics = this.$store.getters['metrics/getMetrics'];
        return [{
            icon: BarChart2Icon,
            value: `$${metrics.expenses}`,
            description: 'Expenses',
            key: 1
          },
          {
            icon: UsersIcon,
            value: metrics.codersStaffed + "/" + metrics.totalCoders,
            description: 'Coders Staffed',
            key: 2
          },
          {
            icon: LayersIcon,
            value: metrics.projectsWithCoders,
            description: 'Projects with Coders',
            key: 3
          },
          {
            icon: InboxIcon,
            value: metrics.delegationOfSkills,
            description: CODERS_DELEGATION_SKILLS,
            key: 4
          },
          {
            icon: TagIcon,
            value: metrics.fixedPrice,
            description: CODERS_FIXED_PRICE,
            key: 5
          },
        ];
      }
    },
    methods: {},
    created() {},
    mounted() {
      this.$store.dispatch('metrics/getMetrics');
    },
  };
</script>

