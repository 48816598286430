var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table user-table-content" },
    [
      _vm.components.length === 0
        ? _c("tr", [
            _c("td", { attrs: { colspan: "9" } }, [
              _vm._v("No components to show"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.components, function (component, index) {
        return _vm.userRole !== "manager" ||
          (_vm.userRole === "manager" &&
            component.manager &&
            component.manager.uuid === _vm.currentUserId &&
            component.status !== "Archived")
          ? _c("tr", { key: index }, [
              _c("td", { staticClass: "compo-name" }, [
                _vm._v(_vm._s(component.projectName)),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "compo-name" }, [
                _vm._v(_vm._s(component.name)),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "compo-manager" }, [
                _vm._v(_vm._s(_vm.getDate(component))),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "compo-price" }, [
                _c("span", { staticClass: "mx-1" }, [
                  _vm._v(
                    _vm._s(_vm._f("symbol")(component.currency)) +
                      _vm._s(component.fixedPrice || component.dayRate) +
                      _vm._s(component.dayRate ? " / day" : null)
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "compo-price-icon" }, [
                _c(
                  "span",
                  [
                    component.type === _vm.STATUS_CODERS_FIXED_PRICE
                      ? _c("tag-icon", { staticClass: "p-1" })
                      : _c("inbox-icon", { staticClass: "p-1" }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "font-accent compo-status" },
                [
                  _c("check-icon"),
                  _vm._v(" "),
                  _c("span", { staticClass: "mx-1" }, [
                    _vm._v(_vm._s(component.status)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "compo-edit-icon" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: _vm.getEditComponentUrl(component) } },
                    [_c("edit-2-icon", { staticClass: "m-0 p-1" })],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }