<template>
  <div v-bind:class="'metric d-flex metric-' + metric.key">
    <component :is="metric.icon" class="icon"></component>
    <div class="metric-data heigh">
      <div class="my-0" v-if="isLoading">
        <b-spinner label="Spinning"></b-spinner>
      </div>
      <h1 class="my-0" v-if="!isLoading">{{metric.value}}</h1>
      <span class="font-semibold font-alt-light">{{metric.description}}</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['metric', 'isLoading'],
    name: 'MetricItem',
  };
</script>

