<template>
  <div class="container-fluid dash p-0">
    <div class="container-fluid px-0">
      <div class="d-flex flex-sm-column flex-lg-row pt-2">
        <div class="p-0 coder-cards">
         <profile-info :components="length" :coder="profile" :admins="admins"></profile-info>
          {{payment[0]}}
          <payment-info :payment="payment"></payment-info>
        </div>
        <div class="p-0 coder-table flex-grow-1">
          <notification
            v-for="notification in notifications"
            :key="notification.uuid"
            :notification="notification"
          ></notification>
          <component-list :user_uuid="profile.id" v-on:update-length="updateLength"></component-list>
          <div v-if="isLoading" class="text-center py-4">
            <b-spinner label="Spinning"></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProfileInfo from '../../../components/coder/CoderInfo';
  import ComponentList from '../../../components/coder/ComponentList';
  import Notification from '../../../components/coder/Notification';
  import PaymentInfo from '../../../components/coder/PaymentInfo';
  import {
    CREATE_COMPONENT,
    ASSIGN_COMPONENT
  } from '../../../constants';

  export default {
    name: "DashboardCoder",
    components: {
      ProfileInfo,
      ComponentList,
      Notification,
      PaymentInfo,
    },  
    created: function(){
      this.$store.dispatch('notifications/getNotifications');
      this.$store.dispatch('coders/getAdmins');
      this.$store.dispatch('payments/getCoderPayments');
    },  
    computed: {
      isLoading() {
        return this.$store.getters['coders/isLoading'];
      },
      hasError() {
        return this.$store.getters['coders/hasError'];
      },
      error() {
        return this.$store.getters['coders/error'];
      },     
      notifications() {
        return this.$store.getters['notifications/getNotifications'];
      },
      payment() {
        let payments = this.$store.getters['payments/getCoderPayments'];
        let total = 0; let periods = []; let amounts = [];
        payments.forEach(function(pay){
          total = parseFloat(total) + parseFloat(pay.amount);
          amounts.push(parseFloat(pay.amount));
          periods.push(pay.period);
        });

        return {
          total: total,
          data: {
            periods: periods,
            amounts: amounts,
            currentMonth: 345,
          }
        }
      },     
      admins() {
        return this.$store.getters['coders/getAdmins'];
      },
      coders() {
        return this.$store.getters['coders/getCoders'];
      },
      STATUS_CREATE_COMPONENT: function(){
        return CREATE_COMPONENT;
      }
    },
    data: function() {
      const { security } = this.$store.state;
      return {
        profile: security,
        length: 0
      }
    },
    methods: {
      updateLength(e){
        this.length = e;
      },
    }
  };
</script>

<style scoped>
.nopadding {
  padding: 0 !important;
}
</style>