var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "wrapper",
    [
      _c("section", { staticClass: "p-3 m-3" }, [
        _c("div", [
          _c("h1", { staticClass: "text-center" }, [
            _vm._v("Accept Component - Your Contract"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("b-form", { on: { submit: _vm.onAccept } }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12 col-sm-12" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "fieldset-component-name",
                      label: "Component Name",
                      "label-for": "input-component-name",
                    },
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "input-component-name",
                        name: "form.componentName",
                        placeholder: "Component Name",
                        readonly: "",
                      },
                      model: {
                        value: _vm.component.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.component, "name", $$v)
                        },
                        expression: "component.name",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-12 col-sm-12" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "fieldset-type",
                      label: "Type",
                      "label-for": "input-type",
                    },
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "input-type",
                        name: "form.type",
                        readonly: "",
                      },
                      model: {
                        value: _vm.component.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.component, "type", $$v)
                        },
                        expression: "component.type",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-12 col-sm-12" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "fieldset-description",
                      label: "Component Description",
                      "label-for": "input-description",
                    },
                  },
                  [
                    _c("b-form-textarea", {
                      attrs: {
                        id: "textarea",
                        rows: "3",
                        "max-rows": "6",
                        readonly: "",
                      },
                      model: {
                        value: _vm.component.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.component, "description", $$v)
                        },
                        expression: "component.description",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-12 col-sm-12" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "fieldset-price",
                      label: "Component Price",
                      "label-for": "input-description",
                    },
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "input-price",
                        name: "form.price",
                        readonly: "",
                      },
                      model: {
                        value:
                          _vm.component.type ===
                          _vm.STATUS_CODERS_DELEGATION_SKILLS
                            ? _vm.component.dayRate + " / day"
                            : _vm.component.fixedPrice,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.component.type ===
                              _vm.STATUS_CODERS_DELEGATION_SKILLS
                              ? _vm.component.dayRate + " / day"
                              : _vm.component,
                            "fixedPrice",
                            $$v
                          )
                        },
                        expression:
                          "component.type===STATUS_CODERS_DELEGATION_SKILLS ? component.dayRate+' / day' : component.fixedPrice",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12" }, [
              _c("h1", { staticClass: "form-section-title" }, [
                _vm._v("Your Contract"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "terms-area p-3" }, [
                  _c("p", [_vm._v("Preamble")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "The Detailed Conditions herein are bound to the General Terms and Conditions of Use. Those two documents are a single and indivisible whole. The developer agrees that he has read the last version of the General Terms and Conditions of Use on the website coders’ url."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "He recognizes that he is held to the aforementioned terms, conditions, restrictions, and notices. "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("Article 1")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "The Developer accepts the implementation of the Component part of the project. The Component should run as describe in the Requirements which have been published on coders url on date of component creation."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("Article 2 – Coming into effect.")]),
                  _vm._v(".\n              "),
                  _c("p", [
                    _vm._v(
                      "The Particular Conditions come into effect automatically by checking the box besides the statement “I acknowledge and agree to the terms and conditions of THE CODING MACHINE”, located on the page component url.The contract will end at the expiry of the Guarantee, as defined in article 22 of the General"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("Article 3 - Delivery date")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "The developer shall deliver the component on the date of delivery. If specifications had been sent by email or had been set up on Ryver’s tasks refer to it here."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("Article 4 – Warrantee")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "The warrantee phase ends two months after the component validation date."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("Article 5 - Price")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "The component price is price in $ written on the component (if it’s a fixed-price contract) or $ per hour + day rate if it’s delegation of skills component"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("Article 6 – Payment")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Before receiving any payment, if this component is the first component developed by the Developer for THE CODING MACHINE, the Developer must return The General Terms and Conditions of Use and The Particular Conditions signed to THE CODING MACHINE, as explained in the General Terms and Conditions of Use.The Developer can request his payment: 0% of $XXX are paid when the Component is delivered and TCM allows the request. 50% of the payment are paid when the bugs are dealt with and TCM allows the request."
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-12" },
              [
                _c(
                  "b-form-checkbox-group",
                  {
                    attrs: {
                      options: _vm.options,
                      state: _vm.state,
                      name: "checkbox-validation",
                    },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  },
                  [
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { state: _vm.state } },
                      [
                        _vm._v(
                          "You have to agree terms, conditions and contract"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-section button-container" }, [
              _c("div", { staticClass: "row justify-content-around" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-8 d-flex justify-content-around" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger m-2",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onDecline.apply(null, arguments)
                          },
                        },
                      },
                      [_c("x-icon"), _vm._v("Refuse")],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary m-2",
                        attrs: { disabled: !_vm.state },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onAccept.apply(null, arguments)
                          },
                        },
                      },
                      [_c("check-icon"), _vm._v("Accept")],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }