<template>
  <div class="my-5 dash-header container">
    <div class="navbar d-flex justify-content-space">
      <!-- <div class="navigation mr-2">
        <bar-chart-2-icon class="burger-icon"/>
      </div> -->
      <div class="navbar-brand">
        <a href="/" @click.prevent="goHome">
          <img class="brand-logo" src="/images/logo.png" alt="logo">
        </a>
      </div>
      <div class="ml-auto" title="Logout">
        <power-icon class="power-icon" @click="logout">
        </power-icon>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    PlusIcon,
    UserPlusIcon,
    PowerIcon,
    BarChart2Icon,
  } from 'vue-feather-icons'
  
  export default {
    components: {
      PlusIcon,
      UserPlusIcon,
      PowerIcon,
      BarChart2Icon,
    },
    name: 'Header',
    computed: {},
    methods: {
      logout() {
        this.$store.dispatch('security/logout');
        this.$router.push({path: '/connection'});
      },
      goHome() {
        this.$router.push('/');
      }
    },
    created() {},
    mounted() {},
  };
</script>
