var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "content d-flex flex-column justify-content-center" },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between align-items-center page-title",
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary btn-back",
                on: { click: _vm.onBack },
              },
              [
                _c("arrow-left-icon", { staticClass: "custom-class" }),
                _vm._v("Back\n      "),
              ],
              1
            ),
            _vm._v(" "),
            _c("h1", { staticClass: "form-title display-5 text-center" }, [
              _vm._v("Edit Profile"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("user-form", {
          attrs: { viewer: "coder", user: _vm.profile },
          on: { submit: _vm.onSubmit, back: _vm.onBack },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }