var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "component-form" },
    [
      _c("b-form", { on: { submit: _vm.onSubmit } }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column justify-content-center" },
          [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-name",
                          label: "Project Name *",
                          "label-for": "input-name",
                          state: _vm.validateState("form.projectName"),
                          "invalid-feedback": _vm.validationMsg.projectName,
                        },
                      },
                      [
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: true },
                              expression: "{ required: true }",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "input-name",
                            name: "form.projectName",
                            placeholder: "Enter project name",
                            disabled: _vm.mode === "coder" || _vm.checkEdit,
                            state: _vm.validateState("form.projectName"),
                          },
                          model: {
                            value: _vm.form.projectName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "projectName", $$v)
                            },
                            expression: "form.projectName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-city",
                          label: "Branch *",
                          "label-for": "input-city",
                          "invalid-feedback": _vm.validationMsg.cityId,
                          state: _vm.validateState("form.cityId"),
                        },
                      },
                      [
                        _c("v-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: true },
                              expression: "{ required: true }",
                            },
                          ],
                          staticClass: "form-control form-list-project",
                          attrs: {
                            options: _vm.cities,
                            label: "name",
                            placeholder: "Select a branch",
                            id: "input-city",
                            name: _vm.form.cityId,
                            required: !_vm.form.cityId,
                            disabled: _vm.mode === "coder" || _vm.checkEdit,
                            state: _vm.validateState("form.cityId"),
                          },
                          on: { input: _vm.handleSelectCity },
                          model: {
                            value: _vm.city,
                            callback: function ($$v) {
                              _vm.city = $$v
                            },
                            expression: "city",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-name",
                          label: "Component Name *",
                          "label-for": "input-name",
                          state: _vm.validateState("form.name"),
                          "invalid-feedback": _vm.validationMsg.name,
                        },
                      },
                      [
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: true },
                              expression: "{ required: true }",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "input-name",
                            name: "form.name",
                            placeholder: "Enter component name",
                            disabled: _vm.mode === "coder" || _vm.checkEdit,
                            state: _vm.validateState("form.name"),
                          },
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-coder",
                          label: "Coder name *",
                          "label-for": "input-coder",
                          state: _vm.validateState("form.coderId"),
                          "invalid-feedback": _vm.validationMsg.coderId,
                        },
                      },
                      [
                        _c("v-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: true },
                              expression: "{ required: true }",
                            },
                          ],
                          staticClass: "form-control form-list-project",
                          attrs: {
                            options: _vm.allCoders,
                            label: "firstName",
                            placeholder: "Select a coder",
                            id: "input-coder",
                            name: "form.coderId",
                            required: !_vm.coder,
                            disabled: _vm.mode === "coder" || _vm.checkEdit,
                            state: _vm.validateState("form.coderId"),
                          },
                          on: { input: _vm.handleSelectCoder },
                          scopedSlots: _vm._u([
                            {
                              key: "selected-option",
                              fn: function (option) {
                                return [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(option.firstName) +
                                      " " +
                                      _vm._s(option.lastName) +
                                      "\n                            "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(option.firstName) +
                                      " " +
                                      _vm._s(option.lastName) +
                                      "\n                            "
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.coder,
                            callback: function ($$v) {
                              _vm.coder = $$v
                            },
                            expression: "coder",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isManager,
                        expression: "!isManager",
                      },
                    ],
                    staticClass: "col-md-12",
                  },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-manager",
                          label: "Manager name *",
                          "label-for": "input-manager",
                          state: _vm.validateState("form.managerId"),
                          "invalid-feedback": _vm.validationMsg.managerId,
                        },
                      },
                      [
                        _c("v-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: !_vm.isManager },
                              expression: "{ required: !isManager }",
                            },
                          ],
                          staticClass: "form-control form-list-project",
                          attrs: {
                            options: _vm.managers,
                            label: "firstName",
                            placeholder: "Select a manager",
                            id: "input-manager",
                            name: "form.managerId",
                            required: !_vm.manager,
                            disabled:
                              _vm.mode === "manager" || _vm.mode === "coder",
                            state: _vm.validateState("form.managerId"),
                          },
                          on: { input: _vm.handleSelectManager },
                          scopedSlots: _vm._u([
                            {
                              key: "selected-option",
                              fn: function (option) {
                                return [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(option.firstName) +
                                      " " +
                                      _vm._s(option.lastName) +
                                      "\n                            "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(option.firstName) +
                                      " " +
                                      _vm._s(option.lastName) +
                                      "\n                            "
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.manager,
                            callback: function ($$v) {
                              _vm.manager = $$v
                            },
                            expression: "manager",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-description",
                          label: "Component Description",
                          "label-for": "input-description",
                        },
                      },
                      [
                        _c("b-form-textarea", {
                          attrs: {
                            id: "textarea",
                            placeholder: "Enter component description",
                            rows: "3",
                            "max-rows": "6",
                            disabled: _vm.mode === "coder" || _vm.checkEdit,
                          },
                          model: {
                            value: _vm.form.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "description", $$v)
                            },
                            expression: "form.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-start-date",
                          label: "Start Date *",
                          "label-for": "input-start-date",
                          state: _vm.validateState("form.startDate"),
                          "invalid-feedback": _vm.validationMsg.startDate,
                        },
                      },
                      [
                        _c("datepicker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: true },
                              expression: "{ required: true }",
                            },
                          ],
                          attrs: {
                            id: "input-start-date",
                            name: "form.startDate",
                            placeholder: "Start Date",
                            format: "MM/dd/yyyy",
                            "bootstrap-styling": "",
                            disabled: _vm.mode === "coder" || _vm.checkEdit,
                            state: _vm.validateState("form.startDate"),
                            "aria-describedby": "start-date-live-feedback",
                          },
                          model: {
                            value: _vm.form.startDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "startDate", $$v)
                            },
                            expression: "form.startDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-end-date",
                          label: "End Date *",
                          "label-for": "input-end-date",
                          state: _vm.validateState("form.endDate"),
                          "invalid-feedback": _vm.validationMsg.endDate,
                        },
                      },
                      [
                        _c("datepicker", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: true },
                              expression: "{ required: true }",
                            },
                          ],
                          attrs: {
                            id: "input-end-date",
                            name: "form.endDate",
                            placeholder: "End Date",
                            format: "MM/dd/yyyy",
                            "bootstrap-styling": "",
                            disabled: _vm.mode === "coder" || _vm.checkEdit,
                            state: _vm.validateState("form.endDate"),
                            "aria-describedby": "end-date-live-feedback",
                          },
                          model: {
                            value: _vm.form.endDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "endDate", $$v)
                            },
                            expression: "form.endDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    !_vm.validateDate() &&
                    _vm.form.startDate &&
                    _vm.form.endDate
                      ? _c("b-form-group", { attrs: { id: "dateValidate" } }, [
                          _vm._v(
                            _vm._s(this.validDatesMessage) +
                              "\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12 col-md-12" }, [
                  _c("div", { staticClass: "form-pills" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "d-block" }, [
                        _vm._v("Payment Type"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        _vm._l(_vm.paymentOptions, function (option, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "form-check-item mr-2" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.type,
                                    expression: "form.type",
                                  },
                                ],
                                staticClass: "check-input",
                                attrs: {
                                  type: "radio",
                                  name: "payment-type",
                                  id: "payment-option-" + i,
                                  disabled:
                                    _vm.mode === "coder" || _vm.mode === "edit",
                                },
                                domProps: {
                                  value: option.value,
                                  checked: _vm._q(_vm.form.type, option.value),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "type",
                                      option.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "d-fle form-check-label",
                                  attrs: { for: "payment-option-" + i },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "form-control" },
                                    [
                                      _c("inbox-icon", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              option.text ===
                                              _vm.STATUS_CODERS_DELEGATION_SKILLS,
                                            expression:
                                              "option.text === STATUS_CODERS_DELEGATION_SKILLS",
                                          },
                                        ],
                                        staticClass: "p-1",
                                      }),
                                      _vm._v(" "),
                                      _c("tag-icon", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              option.text ===
                                              _vm.STATUS_CODERS_FIXED_PRICE,
                                            expression:
                                              "option.text === STATUS_CODERS_FIXED_PRICE",
                                          },
                                        ],
                                        staticClass: "p-1",
                                      }),
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(option.text) +
                                          "\n                    "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.type === _vm.STATUS_CODERS_FIXED_PRICE,
                        expression: "form.type === STATUS_CODERS_FIXED_PRICE",
                      },
                    ],
                    staticClass: "col-sm-12 col-md-6",
                  },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-price",
                          label: "Component Price *",
                          "label-for": "input-price",
                          state: _vm.validateState("form.fixedPrice"),
                          "invalid-feedback": _vm.validationMsg.fixedPrice,
                        },
                      },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "currency-addon" },
                          [
                            _c("div", { staticClass: "input-group-prepend" }, [
                              _c("span", { staticClass: "input-group-text" }, [
                                _vm._v(_vm._s(_vm.currency.symbol)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("b-form-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    required:
                                      _vm.form.type ===
                                      _vm.STATUS_CODERS_FIXED_PRICE,
                                    decimal: true,
                                  },
                                  expression:
                                    "{ required: form.type === STATUS_CODERS_FIXED_PRICE ,decimal: true}",
                                },
                              ],
                              attrs: {
                                id: "input-price",
                                min: "0",
                                name: "form.fixedPrice",
                                disabled:
                                  _vm.mode === "coder" || _vm.mode === "edit",
                                state: _vm.validateState("form.fixedPrice"),
                                readonly:
                                  _vm.mode === "create" &&
                                  (_vm.firstFixedPaymentStatus !==
                                    _vm.STATUS_CODERS_WAITING_PAYMENT ||
                                    _vm.secondFixedPaymentStatus !==
                                      _vm.STATUS_CODERS_WAITING_PAYMENT),
                                placeholder: "Enter component price",
                              },
                              on: {
                                change: function ($event) {
                                  _vm.form.hourRate = 0
                                },
                              },
                              model: {
                                value: _vm.form.fixedPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "fixedPrice", $$v)
                                },
                                expression: "form.fixedPrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.form.type === _vm.STATUS_CODERS_DELEGATION_SKILLS,
                        expression:
                          "form.type === STATUS_CODERS_DELEGATION_SKILLS",
                      },
                    ],
                    staticClass: "col-sm-12 col-md-6",
                  },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-rate",
                          label: "Component Hour Rate",
                          "label-for": "input-rate",
                          state: _vm.validateState("form.hourRate"),
                          "invalid-feedback": _vm.validationMsg.hourRate,
                        },
                      },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "currency-addon" },
                          [
                            _c("div", { staticClass: "input-group-prepend" }, [
                              _c("span", { staticClass: "input-group-text" }, [
                                _vm._v(_vm._s(_vm.currency.symbol)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("b-form-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    required:
                                      _vm.form.type ===
                                      _vm.STATUS_CODERS_DELEGATION_SKILLS,
                                    decimal: true,
                                  },
                                  expression:
                                    "{ required: form.type === STATUS_CODERS_DELEGATION_SKILLS , decimal: true}",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "input-rate",
                                name: "form.hourRate",
                                disabled: _vm.mode === "coder" || _vm.checkEdit,
                                readonly:
                                  _vm.mode === "edit" || _vm.hourRateIsReadOnly,
                                state: _vm.validateState("form.hourRate"),
                                placeholder: "Enter component hour rate",
                              },
                              on: {
                                change: function ($event) {
                                  _vm.form.fixedPrice = 0
                                },
                              },
                              model: {
                                value: _vm.form.hourRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "hourRate", $$v)
                                },
                                expression: "form.hourRate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-non-billable-hours",
                          label: "Component Non Billable Hours *",
                          "label-for": "input-non-billable-hours",
                          state: _vm.validateState("form.nonBillableHours"),
                          "invalid-feedback":
                            _vm.validationMsg.nonBillableHours,
                        },
                      },
                      [
                        _c("b-form-input", {
                          class: {
                            "input-alert": _vm.form.nonBillableHours === 0,
                          },
                          attrs: {
                            id: "input-non-billable-hours",
                            min: "0",
                            name: "form.nonbillableHours",
                            state: _vm.validateState("form.nonBillableHours"),
                            disabled: true,
                            readonly: true,
                            placeholder: "Enter component non billable hours",
                          },
                          on: {
                            change: function ($event) {
                              _vm.form.nonBillableHours = 0
                            },
                          },
                          model: {
                            value: _vm.form.nonBillableHours,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "nonBillableHours", $$v)
                            },
                            expression: "form.nonBillableHours",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-billable-hours",
                          label: "Component Billable Hours *",
                          "label-for": "input-billable-hours",
                          state: _vm.validateState("form.billableHours"),
                          "invalid-feedback": _vm.validationMsg.billableHours,
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "input-non-billable-hours",
                            min: "0",
                            name: "form.billableHours",
                            disabled: true,
                            state: _vm.validateState("form.billableHours"),
                            readonly: true,
                            placeholder: "Enter component billable hours",
                          },
                          on: {
                            change: function ($event) {
                              _vm.form.billableHours = 0
                            },
                          },
                          model: {
                            value: _vm.form.billableHours,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billableHours", $$v)
                            },
                            expression: "form.billableHours",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-12 col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "fieldset-grand-total",
                          label: "Component grand total *",
                          "label-for": "input-grand-total",
                          state: _vm.validateState("form.grandTotal"),
                          "invalid-feedback": _vm.validationMsg.grandTotal,
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "input-grand-total",
                            min: "0",
                            name: "form.grandTotal",
                            disabled: true,
                            state: _vm.validateState("form.grandTotal"),
                            readonly: true,
                            placeholder: "Enter component grand total",
                          },
                          on: {
                            change: function ($event) {
                              _vm.form.grandTotal = 0
                            },
                          },
                          model: {
                            value: _vm.form.grandTotal,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "grandTotal", $$v)
                            },
                            expression: "form.grandTotal",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("component-payment", {
                  key: _vm.paymentsUpdtIdx,
                  attrs: {
                    symbol: _vm.currency.symbol,
                    payments: _vm.originalPayments,
                    loading: _vm.isLoadingPayments,
                    mode: _vm.mode,
                    formType: _vm.form.type,
                    checkEdit: _vm.checkEdit,
                    formFixedPrice:
                      _vm.form.type === _vm.STATUS_CODERS_FIXED_PRICE
                        ? _vm.form.fixedPrice
                        : 0,
                    formHourRate:
                      _vm.form.type === _vm.STATUS_CODERS_DELEGATION_SKILLS
                        ? _vm.form.hourRate
                        : 0,
                  },
                  on: {
                    add: _vm.onAddPayment,
                    delete: _vm.onDeletePayment,
                    remove: _vm.onRemovePayment,
                    "remove-fixed": _vm.onRemoveFixedPayment,
                    request: _vm.onRequestPayment,
                    allow: _vm.onAllowPayment,
                    setFixedPaymentStatus: _vm.setFixedPaymentStatus,
                    setDelegationSkillsPaymentStatus:
                      _vm.setDelegationSkillsPaymentStatus,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isLoading
              ? _c(
                  "b-alert",
                  {
                    attrs: {
                      show: _vm.dismissCountDown,
                      dismissible: "",
                      fade: "",
                      variant: "success",
                    },
                    on: { "dismiss-count-down": _vm.countDownChanged },
                  },
                  [_vm._v("\n              Component Saved\n            ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "text-center py-4" },
                  [_c("b-spinner", { attrs: { label: "Spinning" } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.mode !== "coder"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "form-section button-container d-flex justify-content-center",
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "px-5 py-2 m-2",
                        attrs: {
                          variant: "secondary",
                          disabled: _vm.isLoading || _vm.isLoadingPayments,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onCancel.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("x-icon"),
                        _vm._v(
                          "\n                    Cancel\n                "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.mode === "create"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "px-5 py-2 m-2",
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              disabled: _vm.isLoading || _vm.isLoadingPayments,
                            },
                          },
                          [
                            _c("plus-icon"),
                            _vm._v(
                              "\n                    Create Component\n                "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.checkEdit && _vm.mode === "edit"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "px-5 py-2 m-2",
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              disabled: _vm.isLoading || _vm.isLoadingPayments,
                            },
                          },
                          [
                            _c("plus-icon"),
                            _vm._v(
                              "\n                    Save Component\n                "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAdmin &&
                    _vm.component &&
                    _vm.component.status !== "Finished"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "px-5 py-2 m-2",
                            attrs: { variant: "primary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.syncWithZoho.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("plus-icon"),
                            _vm._v(
                              "\n                Sync With Zoho\n              "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAdmin &&
                    _vm.component &&
                    _vm.component.status === "Finished"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "px-5 py-2 m-2",
                            attrs: { variant: "success" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onArchive.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("archive-icon"),
                            _vm._v(
                              "\n                    Archive\n                "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAdmin &&
                    _vm.component &&
                    _vm.component.status === "Created"
                      ? _c(
                          "b-button",
                          {
                            staticClass: "px-5 py-2 m-2",
                            attrs: { variant: "danger" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onDelete.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("trash-2-icon"),
                            _vm._v("\n                Delete\n              "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modal-payment",
          attrs: {
            id: "modal-payment",
            centered: "",
            title: "Create Payment",
            "hide-footer": "",
          },
        },
        [
          _vm.isFixed(_vm.form)
            ? _c("payment-fixed-modal", {
                on: { save: _vm.onSavePayment, cancel: _vm.onCancelPayment },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isHourly(_vm.form)
            ? _c("payment-delegation-modal", {
                attrs: {
                  rate: _vm.form.hourRate,
                  "grand-total": _vm.form.grandTotal,
                  "original-payments": _vm.originalPayments,
                },
                on: { save: _vm.onSavePayment, cancel: _vm.onCancelPayment },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }