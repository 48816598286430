import _ from 'lodash';
import { apolloClient } from '../graphql';
import notificationsQuery from '../graphql/coder/query/notifications.gql';
import notificationMutation from '../graphql/coder/mutation/notification.gql';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    notifications: []
  },
  getters: {
    isLoading: state => {
      return state.isLoading;
    },
    hasError: state => {
      return state.error !== null;
    },
    error: state => {
      return state.error;
    },
    getNotifications: state => {
      return state.notifications;
    },
  },
  mutations: {
    ['GET_NOTIFICATIONS_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['GET_NOTIFICATIONS_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.notifications = payload;
    },
    ['GET_NOTIFICATIONS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['SAVE_NOTIFICATION_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['SAVE_NOTIFICATION_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
    },
    ['SAVE_NOTIFICATION_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['CLEAR_NOTIFIACTIONS'](state) {
      state.notifications = [];
    }
  },
  actions: {
    getNotifications({ commit }) {
      commit('GET_NOTIFICATIONS_REQUEST');
      return apolloClient
        .query({
          query: notificationsQuery,
        })
        .then(res => {commit('GET_NOTIFICATIONS_SUCCESS', res.data.notifications.items);apolloClient.cache.reset();})
        .catch(err => commit('GET_NOTIFICATIONS_ERROR', err));
    },
    updateNotification({ commit }, { notificationId, accepted, callback }) {
      commit('SAVE_NOTIFICATION_REQUEST');
      return apolloClient
        .mutate({
          mutation: notificationMutation,
          variables: {
            notificationId,
            accepted
          }
        })
        .then(res => {
          commit('SAVE_NOTIFICATION_SUCCESS', res.data.changeNotification);
          apolloClient.cache.reset();
          callback();
        })
        .catch(err => commit('SAVE_NOTIFICATION_ERROR', err));
    },
    clearNotifications({ commit }) {
      commit('CLEAR_PAYMENTS');
      apolloClient.cache.reset();
    },
  }
};
