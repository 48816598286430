var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-table admin-dash-table" },
    [
      _c("coder-info", {
        attrs: { coder: _vm.coder, isOpen: _vm.isOpen, userRole: _vm.userRole },
        on: { toggle: _vm.onToggle },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "coders-accordion-content",
          class: { active: _vm.isOpen },
        },
        [
          _c("coder-component", {
            attrs: {
              coder: _vm.coder,
              userRole: _vm.userRole,
              components: _vm.getFilteredComponent(_vm.coder.components.items),
            },
            on: { view: _vm.onViewComponent },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("component-modal", {
        attrs: { selectedComponent: _vm.selectedComponent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }