import _ from 'lodash';
import {apolloClient} from '../graphql';
import componentsQuery from '../graphql/coder/query/components.gql';
import componentListQuery from '../graphql/coder/query/fetchComponentsList.gql';
import componentsByNotificationQuery from '../graphql/coder/query/fetchComponentByNotification.gql';
import componentsMutation from '../graphql/coder/mutation/component.gql';
import componentAPI from '../api/component.js';
import fetchComponentByUuid from "../graphql/coder/query/fetchComponentByUuid.gql";
import getCities from "../graphql/coder/query/getCities.gql";
import syncComponentWithZohoMutation from '../graphql/coder/mutation/syncComponentWithZoho.gql';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    components: [],
    count: 0,
    componentsByNotification: [],
    fetchComponent: null,
    component: {},
    dashboardFilter: null,
    componentsFilter: null,
    cities: [],
    syncedComponent:null,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    getFetchComponent: state => {
      return state.fetchComponent;
    },
    getComponent: state => {
      return state.components;
    },
    component: state => {
      return state.component;
    },
    getCities: state => {
      return state.cities;
    },
    getCount: state => {
      return state.count;
    },
    getComponentsByNotification: state => {
      return state.componentsByNotification;
    },
    dashboardFilter: state => {
      return state.dashboardFilter;
    },
    componentsFilter: state => {
      return state.componentsFilter;
    }
  },
  mutations: {
    ['GET_COMPONENT_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
      state.component = {};
    },
    ['GET_COMPONENT_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.component = payload;
    },
    ['GET_COMPONENT_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['GET_COMPONENTS_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['GET_COMPONENTS_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.components = payload.items;
      state.count = payload.count;
    },
    ['SET_FETCH_COMPONENTS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.fetchComponent = payload.component;
    },
    ['GET_COMPONENTS_NOTIFICATION_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.componentsByNotification = payload;
    },
    ['GET_COMPONENTS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['CLEAR_COMPONENTS'](state) {
      state.components = [];
      state.dashboardFilter = null;
      state.componentsFilter = null;
    },
    ['SAVE_COMPONENT_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['SAVE_COMPONENT_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.components = [...state.components, payload];
    },
    ['SAVE_COMPONENT_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['UPDATE_COMPONENT_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['SYNC_COMPONENT_WITH_ZOHO_STATUS'](state, payload) {
      state.isLoading = true;
      state.error = null;
    },
    ['SYNC_COMPONENT_WITH_ZOHO_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.syncedComponent = payload;
    },
    ['SYNC_COMPONENT_WITH_ZOHO_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['UPDATE_COMPONENT_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      const index = state.components.findIndex(component => component.uuid === payload.uuid);
      state.components[index] = payload;
      // state.components = [ ...state.components, payload ];
    },
    ['UPDATE_COMPONENT_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['SET_DASHBOARD_FILTER'](state, payload) {
      state.dashboardFilter = payload;
    },
    ['SET_COMPONENTS_FILTER'](state, payload) {
      state.componentsFilter = payload;
    },
    ['GET_CITIES_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
      state.cities = [];
    },
    ['GET_CITIES_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.cities = payload;
    },
    ['GET_CITIES_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
  },
  actions: {
    fetchComponentById({commit}, uuid) {
      commit('GET_COMPONENT_REQUEST');
      return apolloClient
       .query({
         query: fetchComponentByUuid,
         variables: {
           uuid: uuid,
         }
       })
       .then(res => {
         commit('GET_COMPONENT_SUCCESS', res.data.fetchComponentByUuid);
       })
       .catch(err => commit('GET_COMPONENT_ERROR', err));
    },
    checkComponents({commit}, coderId) {
      return componentAPI.checkComponents(coderId);
    },
    createMonthlyComponent({commit}, zohoUserId) {
      return componentAPI.createMonthlyComponent(zohoUserId);
    },
    resetStore() {
      apolloClient.cache.reset();
      apolloClient.resetStore();
    },
    refetchQueries() {
      apolloClient.cache.reset();
      apolloClient.reFetchObservableQueries();
    },
    getComponents({commit}, payload) {
      commit('GET_COMPONENTS_REQUEST');
      return apolloClient
       .query({
         query: componentsQuery,
         variables: {
           coder: payload.coder,
           component: payload.component,
           projectName: payload.projectName,
           city: payload.city,
           limit: payload.limit,
           offset: payload.offset
         }
       })
       .then(res => {
         commit('GET_COMPONENTS_SUCCESS', res.data.components);
       })
       .catch(err => commit('GET_COMPONENTS_ERROR', err));
    },
    getComponentsByNotification({commit}, payload) {
      commit('GET_COMPONENTS_REQUEST');

      return new Promise(function (resolve, reject) {
        apolloClient
         .query({
           query: componentsByNotificationQuery,
           variables: {
             notificationUuid: payload.noticationUuid,
           }
         })
         .then(res => {
           commit('GET_COMPONENTS_NOTIFICATION_SUCCESS', res.data.fetchComponentsByNotification.items)
           apolloClient.cache.reset()
           resolve();
         })
         .catch(err => {
           commit('GET_COMPONENTS_ERROR', err);
           reject();
         });
      });
    },
    getComponentList({commit}, payload) {
      commit('GET_COMPONENTS_REQUEST');

      return new Promise(function (resolve, reject) {
        apolloClient
         .query({
           query: componentListQuery,
           variables: {
             coderUuid: payload.uuid
           }
         })
         .then(res => {
           apolloClient.cache.reset();
           resolve(res.data.fetchComponentsList.items);
         })
         .catch(err => {
           commit('GET_COMPONENTS_ERROR', err);
           reject();
         });
      });
    },
    clearComponents({commit}) {
      commit('CLEAR_COMPONENTS');
    },
    syncComponentWithZoho({ commit }, { componentId, callback }) {
      commit('SYNC_COMPONENT_WITH_ZOHO_STATUS');
      return apolloClient
          .mutate({
            mutation: syncComponentWithZohoMutation,
            variables: {
              componentId,
            }
          })
          .then(res => {
            commit('SYNC_COMPONENT_WITH_ZOHO_SUCCESS', res.data.syncComponentWithZoho);
            callback(res.data.syncComponentWithZoho);
          })
          .catch(err => {
            commit('SYNC_COMPONENT_WITH_ZOHO_ERROR', err);
          });
    },
    saveComponent({dispatch, commit}, {component, callback}) {
      commit('SAVE_COMPONENT_REQUEST');
      return apolloClient
       .mutate({
         mutation: componentsMutation,
         variables: {
           component
         }
       })
       .then(res => {
         commit('SAVE_COMPONENT_SUCCESS', res.data.component);
         if (typeof component.uuid === "undefined") {
           dispatch('coders/addCoderComponent', {
             userId: component.coderId,
             component: res.data.component
           }, {
             root: true,
           });
         }
         callback(res.data.component);
       })
       .catch(err => commit('SAVE_COMPONENT_ERROR', err));
    },
    setDashboardFilter({ commit }, payload) {
      commit('SET_DASHBOARD_FILTER', payload);
    },
    setComponentsFilter({ commit }, payload) {
      commit('SET_COMPONENTS_FILTER', payload);
    },
    getCities({ commit }) {
      commit('GET_COMPONENT_REQUEST');
      return apolloClient
          .query({
            query: getCities
          })
          .then(res => {
            commit('GET_CITIES_SUCCESS', res.data.cities.items);
          })
          .catch(err => commit('GET_CITIES_ERROR', err));
    }
  }
};
