import _ from 'lodash';
import { apolloClient } from '../graphql';
import managersQuery from '../graphql/coder/query/managers.gql';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    managers: [],
    components: [],
    admins: []
  },
  getters: {
    isLoading: state => {
      return state.isLoading;
    },
    hasError: state => {
      return state.error !== null;
    },
    error: state => {
      return state.error;
    },
    getManagers: state => {
      return state.managers;
    },
  },
  mutations: {
    ['GET_MANAGERS_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['GET_MANAGERS_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.managers = payload;
    },
    ['GET_MANAGERS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
  },
  actions: {
    getManagers({ commit }) {
      commit('GET_MANAGERS_REQUEST');
      return apolloClient
        .query({
          query: managersQuery
        })
        .then(res => commit('GET_MANAGERS_SUCCESS', res.data.managers.items))
        .catch(err => commit('GET_MANAGERS_ERROR', err));
    },
    clearManagers({ commit }) {
      commit('CLEAR_MANAGER');
    }
  }
};
