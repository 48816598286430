<template>
  <div class="d-flex h-100 admin-wrap">
    <coder-menu class="active"></coder-menu>
    <div class="admin-dash flex-grow-1 flex-shrink-1">
      <dash-header></dash-header> 
      <div class="admin-inner pb-5">
          <div class="container">
              <slot/>
          </div>
      </div>
    </div>

  </div>
  
</template>
<script>
import DashHeader from '../components/Header'
import DashFooter from '../components/Footer'
import CoderMenu from '../components/coder/CoderMenu'
export default {
    name:'CoderWrapper',
    components:{
      DashFooter,
      DashHeader,
      CoderMenu
    }
}
</script>
