var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-md-12" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c("h1", { staticClass: "form-section-title" }, [_vm._v("Payment")]),
          _vm._v(" "),
          _vm.mode !== "coder" && !_vm.checkEdit
            ? _c(
                "b-button",
                {
                  staticClass: "px-5 py-2 m-2",
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addPayment.apply(null, arguments)
                    },
                  },
                },
                [_c("plus-icon"), _vm._v("Create Payment")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm.payments.length === 0 &&
            !_vm.loading &&
            !(
              _vm.mode === "create" &&
              _vm.formType === _vm.STATUS_CODERS_FIXED_PRICE &&
              _vm.formFixedPrice > 0
            )
              ? _c("tr", [
                  _c("td", { attrs: { colspan: "6" } }, [
                    _vm._v("No Data to Show"),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.payments, function (payment, index) {
              return !_vm.loading
                ? _c("tr", [
                    payment.requestedDate
                      ? _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("moment")(
                                payment.requestedDate,
                                "MM/DD/YYYY"
                              )
                            )
                          ),
                        ])
                      : _c("td", [_vm._v("-")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.symbol) +
                          " " +
                          _vm._s(parseFloat(payment.amount).toFixed(2))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "span",
                        {
                          model: {
                            value: payment.status,
                            callback: function ($$v) {
                              _vm.$set(payment, "status", $$v)
                            },
                            expression: "payment.status",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.isPaidPayment(payment)
                                  ? _vm.STATUS_CODERS_PAID_PAYMENT
                                  : _vm.isRequestedPayment(payment)
                                  ? _vm.STATUS_CODERS_REQUESTED_PAYMENT
                                  : _vm.isAllowedPayment(payment)
                                  ? _vm.STATUS_CODERS_ALLOWED_PAYMENT
                                  : _vm.isWaitingPayment(payment) ||
                                    _vm.isNewPayment(payment)
                                  ? _vm.STATUS_CODERS_WAITING_PAYMENT
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    payment.feedbacks
                      ? _c("td", [
                          payment.feedbacks.items.length > 0 &&
                          _vm.hasFeedback(payment.feedbacks.items)
                            ? _c("div", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "cursor-pointer",
                                    attrs: { id: "feedback" + payment.uuid },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.showFeedbackView(
                                          payment.feedbacks.items
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/images/comment.png",
                                        width: "20",
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _c("td"),
                    _vm._v(" "),
                    _vm.mode === "create"
                      ? _c(
                          "td",
                          { attrs: { colspan: "2" } },
                          [
                            _vm.isNewPayment(payment) &&
                            _vm.formType ===
                              _vm.STATUS_CODERS_DELEGATION_SKILLS &&
                            _vm.formHourRate > 0
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "px-5 py-2 m-2",
                                    attrs: {
                                      variant: "primary",
                                      disabled: _vm.loading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.issueDelegationSkillsPayment(
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("\n            Allow\n          ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mode === "edit"
                      ? _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _vm.isWaitingPayment(payment)
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "px-5 py-2 m-2",
                                    attrs: {
                                      variant: "primary",
                                      disabled: _vm.loading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        !_vm.checkEdit &&
                                          _vm.issuePayment(payment)
                                      },
                                    },
                                  },
                                  [
                                    _c("check-circle-icon"),
                                    _vm._v("Allow\n          "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isAllowedPayment(payment)
                              ? _c(
                                  "p",
                                  { staticClass: "success-text" },
                                  [
                                    _c("check-circle-icon", {
                                      staticClass: "success-icon",
                                    }),
                                    _vm._v(" Allowed"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isNewPayment(payment)
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "px-5 py-2 m-2",
                                    attrs: {
                                      variant: "primary",
                                      disabled: _vm.loading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        !_vm.checkEdit &&
                                          _vm.issueDelegationSkillsPayment(
                                            index
                                          )
                                      },
                                    },
                                  },
                                  [
                                    _c("check-circle-icon"),
                                    _vm._v("Allow\n          "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isRequestedPayment(payment)
                              ? _c(
                                  "p",
                                  { staticClass: "accent-text" },
                                  [
                                    _c("check-circle-icon", {
                                      staticClass: "accent-icon",
                                    }),
                                    _vm._v(" Requested"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isPaidPayment(payment)
                              ? _c(
                                  "p",
                                  { staticClass: "success-text" },
                                  [
                                    _c("check-circle-icon", {
                                      staticClass: "success-icon",
                                    }),
                                    _vm._v(" Paid"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mode === "edit"
                      ? _c(
                          "td",
                          [
                            _vm.isWaitingPayment(payment)
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "px-5 py-2 m-2",
                                    attrs: { variant: "primary" },
                                    on: {
                                      click: function ($event) {
                                        !_vm.checkEdit &&
                                          _vm.deletePayment(payment)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash2-icon"),
                                    _vm._v("Delete\n          "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isNewPayment(payment)
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "px-5 py-2 m-2",
                                    attrs: { variant: "primary" },
                                    on: {
                                      click: function ($event) {
                                        !_vm.checkEdit &&
                                          _vm.deletePayment(payment)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash2-icon"),
                                    _vm._v("Delete\n          "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.mode === "coder"
                      ? _c(
                          "td",
                          {
                            staticClass: "text-center",
                            attrs: { colspan: "2" },
                          },
                          [
                            _vm.isAllowedPayment(payment)
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "px-5 py-2 m-2",
                                    attrs: {
                                      variant: "primary",
                                      disabled: _vm.loading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.issuePayment(payment)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.isAllowedPayment(payment)
                                            ? "Request"
                                            : ""
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isWaitingPayment(payment)
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "px-5 py-2 m-2",
                                    attrs: {
                                      variant: "primary",
                                      disabled: true,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.issuePayment(payment)
                                      },
                                    },
                                  },
                                  [_vm._v("\n            Request\n          ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isRequestedPayment(payment)
                              ? _c(
                                  "p",
                                  { staticClass: "accent-text" },
                                  [
                                    _c("check-circle-icon", {
                                      staticClass: "accent-icon",
                                    }),
                                    _vm._v(" Requested"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isPaidPayment(payment)
                              ? _c(
                                  "p",
                                  { staticClass: "success-text" },
                                  [
                                    _c("check-circle-icon", {
                                      staticClass: "success-icon",
                                    }),
                                    _vm._v(" Paid"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e()
            }),
            _vm._v(" "),
            _vm.mode === "create" &&
            _vm.formType === _vm.STATUS_CODERS_FIXED_PRICE &&
            _vm.formFixedPrice > 0
              ? _c("tr", [
                  _c("td", [_vm._v("-")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.symbol) + " " + _vm._s(_vm.formFixedPrice / 2)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _vm._v(_vm._s(_vm.firstFixedPaymentStatus)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _vm.firstFixedPaymentStatus ===
                      _vm.STATUS_CODERS_WAITING_PAYMENT
                        ? _c(
                            "b-button",
                            {
                              staticClass: "px-5 py-2 m-2",
                              attrs: { variant: "primary" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.issueFixedPayment(1)
                                },
                              },
                            },
                            [_vm._v("Allow")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.mode === "create" &&
            _vm.formType === _vm.STATUS_CODERS_FIXED_PRICE &&
            _vm.formFixedPrice > 0
              ? _c("tr", [
                  _c("td", [_vm._v("-")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.symbol) + " " + _vm._s(_vm.formFixedPrice / 2)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "2" } }, [
                    _vm._v(_vm._s(_vm.secondFixedPaymentStatus)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _vm.secondFixedPaymentStatus ===
                      _vm.STATUS_CODERS_WAITING_PAYMENT
                        ? _c(
                            "b-button",
                            {
                              staticClass: "px-5 py-2 m-2",
                              attrs: { variant: "primary" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.issueFixedPayment(2)
                                },
                              },
                            },
                            [_vm._v("Allow")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.loading
              ? _c("tr", [
                  _c("td", { attrs: { colspan: "6" } }, [
                    _vm.loading
                      ? _c(
                          "div",
                          { staticClass: "text-center py-4" },
                          [_c("b-spinner", { attrs: { label: "Spinning" } })],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modal-feedback-show",
          attrs: {
            id: "modal-feedback-show",
            centered: "",
            title: "Feedback",
            "hide-footer": "",
          },
        },
        [
          _c("div", { staticClass: "container justify-content-center" }, [
            _vm.feedback.target === "admin"
              ? _c("div", { staticClass: "row" }, [
                  _vm.feedback.commentForManager
                    ? _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Feedback for manager" } },
                            [
                              _c("b-form-textarea", {
                                attrs: { rows: "3", disabled: "" },
                                model: {
                                  value: _vm.feedback.commentForManager,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.feedback,
                                      "commentForManager",
                                      $$v
                                    )
                                  },
                                  expression: "feedback.commentForManager",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            { attrs: { label: "Feedback for admin" } },
                            [
                              _c("b-form-textarea", {
                                attrs: { rows: "3", disabled: "" },
                                model: {
                                  value: _vm.feedback.commentForManagerAdmin,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.feedback,
                                      "commentForManagerAdmin",
                                      $$v
                                    )
                                  },
                                  expression: "feedback.commentForManagerAdmin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.feedback.commentForCoder
                    ? _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Feedback for coder" } },
                            [
                              _c("b-form-textarea", {
                                attrs: { rows: "3", disabled: "" },
                                model: {
                                  value: _vm.feedback.commentForCoder,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.feedback,
                                      "commentForCoder",
                                      $$v
                                    )
                                  },
                                  expression: "feedback.commentForCoder",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            { attrs: { label: "Feedback for admin" } },
                            [
                              _c("b-form-textarea", {
                                attrs: { rows: "3", disabled: "" },
                                model: {
                                  value: _vm.feedback.commentForCoderAdmin,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.feedback,
                                      "commentForCoderAdmin",
                                      $$v
                                    )
                                  },
                                  expression: "feedback.commentForCoderAdmin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Feedback for " + _vm.feedback.target,
                          },
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: { rows: "3", disabled: "" },
                            model: {
                              value: _vm.feedback.comment,
                              callback: function ($$v) {
                                _vm.$set(_vm.feedback, "comment", $$v)
                              },
                              expression: "feedback.comment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.checkEdit && _vm.mode !== "coder"
        ? _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
            _vm._v("\n      You cannot edit this payment\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Date Requested")]),
        _vm._v(" "),
        _c("th", [_vm._v("Amount")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "8%" } }),
        _vm._v(" "),
        _c("th", { attrs: { width: "11%" } }),
        _vm._v(" "),
        _c("th", { attrs: { width: "11%" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }