var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "admin-menu d-flex flex-column" }, [
    _c("div", { staticClass: "nav mt-5" }, [
      _c("ul", {}, [
        _c(
          "li",
          { staticClass: "active" },
          [
            _c(
              "router-link",
              {
                staticClass: "d-block text-center",
                attrs: { to: { name: "coder" } },
              },
              [
                _c("activity-icon", {
                  staticClass: "d-inline-block menu-icon",
                }),
                _vm._v(" "),
                _c("span", { staticClass: "d-block" }, [_vm._v("Dashboard")]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "active" },
          [
            _c(
              "router-link",
              {
                staticClass: "d-block text-center",
                attrs: { to: { name: "InvoiceCoder" } },
              },
              [
                _c("dollar-sign-icon", {
                  staticClass: "d-inline-block menu-icon",
                }),
                _vm._v(" "),
                _c("span", { staticClass: "d-block" }, [_vm._v("Invoice")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "nav nav-auto" }, [
      _c("ul", { staticClass: "social-icons-side" }, [
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.facebook.com/the.coding.machine/",
                target: "_blank",
                title: "Facebook",
              },
            },
            [_c("facebook-icon")],
            1
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://github.com/thecodingmachine",
                target: "_blank",
                title: "Github",
              },
            },
            [_c("github-icon")],
            1
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.instagram.com/inside_thecodingmachine/",
                target: "_blank",
                title: "Instagram",
              },
            },
            [_c("instagram-icon")],
            1
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.linkedin.com/company/the-coding-machine/",
                target: "_blank",
                title: "LinkedIn",
              },
            },
            [_c("linkedin-icon")],
            1
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://twitter.com/coding_machine",
                target: "_blank",
                title: "Twitter",
              },
            },
            [_c("twitter-icon")],
            1
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.youtube.com/channel/UCw6p2FZ2q2Fj1iGFcIQ_73w",
                target: "_blank",
                title: "YouTube",
              },
            },
            [_c("youtube-icon")],
            1
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { staticClass: "font-bold" }, [_vm._v(_vm._s(_vm.version))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }