var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "row d-flex justify-content-around m-0",
        staticStyle: { width: "100%" },
      },
      [
        _c("id-filter", { on: { search: _vm.onSearchId } }),
        _vm._v(" "),
        _vm.coderFilter !== "own"
          ? _c("coders-filter", { on: { search: _vm.onSearchCoder } })
          : _vm._e(),
        _vm._v(" "),
        _c("amount-filter", { on: { search: _vm.onSearchAmount } }),
        _vm._v(" "),
        _c("project-filter", { on: { search: _vm.onSearchProject } }),
        _vm._v(" "),
        _c("date-range-filter", { on: { search: _vm.onSearchDate } }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "admin-table mt-4 mb-5" },
      [
        _c("ApolloQuery", {
          attrs: {
            query: require("../../../graphql/coder/query/invoiceWithCoders.gql"),
            variables: {
              id: _vm.idFilter,
              coder: _vm.coderFilter !== "own" ? _vm.coderFilter : "",
              amount: _vm.amountFilter,
              startDate: _vm.startDate,
              endDate: _vm.endDate,
              project: _vm.projectFilter,
              offset: _vm.filterOffset,
              limit: _vm.filterLimit,
            },
            options: { notifyOnNetworkStatusChange: true },
            fetchPolicy: "no-cache",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ result: { loading, error, data } }) {
                return [
                  loading
                    ? _c(
                        "div",
                        { staticClass: "text-center py-4" },
                        [_c("b-spinner", { attrs: { label: "Spinning" } })],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  error ? _c("div", [_vm._v("An error occured")]) : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3 list-user" }, [
                    !loading
                      ? _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("th", [_vm._v("#")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Coder name")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Amount dollar")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Amount euro")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Date")]),
                            _vm._v(" "),
                            _c("th"),
                          ]),
                          _vm._v(" "),
                          data.invoicesWithCoders.items
                            ? _c(
                                "tbody",
                                _vm._l(
                                  data.invoicesWithCoders.items,
                                  function (invoice, i) {
                                    return _c("tr", { key: i }, [
                                      _c("td", [_vm._v(_vm._s(invoice.id))]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(_vm._f("name")(invoice))),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("amountDollar")(
                                              invoice.amountDollar
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(invoice.amountEuro) + " €"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatDte")(
                                              invoice.creationDate
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: `/api/download-invoice/${invoice.id}/${invoice.pdfPath}`,
                                              target: "_blank",
                                            },
                                          },
                                          [_c("span", { staticClass: "pdf" })]
                                        ),
                                      ]),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  !loading
                    ? _c("div", { staticClass: "admin-accordions" }, [
                        !data.invoicesWithCoders.items.length
                          ? _c(
                              "div",
                              { staticClass: "user-table admin-dash-table" },
                              [
                                _c("h6", [
                                  _vm._v(
                                    "No\n                        Requested invoices"
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !loading && data.invoicesWithCoders.count > _vm.filterLimit
                    ? _c("b-pagination", {
                        attrs: {
                          value: _vm.currentPage,
                          "total-rows": data.invoicesWithCoders.count,
                          "per-page": _vm.filterLimit,
                          align: "center",
                        },
                        on: { change: _vm.onPaginationChange },
                      })
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }