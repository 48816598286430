<template>
  <div class="col-md-12">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="form-section-title">Payment</h1>
      <b-button variant="primary" class="px-5 py-2 m-2" @click.prevent="addPayment" v-if="(mode !== 'coder' && !checkEdit)"><plus-icon></plus-icon>Create Payment</b-button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Date Requested</th>
          <th>Amount</th>
          <th>Status</th>
          <th width="8%"></th>
          <th width="11%"></th>
          <th width="11%"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="payments.length === 0 && !loading && !(mode==='create' && formType===STATUS_CODERS_FIXED_PRICE && formFixedPrice>0)">
          <td colspan="6">No Data to Show</td>
        </tr>
        <tr v-for="(payment, index) in payments" v-if="!loading">
          <td v-if="payment.requestedDate ">{{payment.requestedDate | moment("MM/DD/YYYY")}}</td>
          <td v-else>-</td>
          <td>{{ symbol }} {{parseFloat(payment.amount).toFixed(2)}}</td>
          <td>
            <span v-model="payment.status">
              {{
                isPaidPayment(payment) ? STATUS_CODERS_PAID_PAYMENT :
                isRequestedPayment(payment) ? STATUS_CODERS_REQUESTED_PAYMENT :
                isAllowedPayment(payment) ? STATUS_CODERS_ALLOWED_PAYMENT :
                isWaitingPayment(payment) || isNewPayment(payment) ? STATUS_CODERS_WAITING_PAYMENT :
                ''
              }}
            </span>
          </td>
          <td v-if="payment.feedbacks">
            <div v-if="payment.feedbacks.items.length>0 && hasFeedback(payment.feedbacks.items)">
              <a :id="'feedback'+payment.uuid" @click.prevent="showFeedbackView(payment.feedbacks.items)" class="cursor-pointer">
                <img src="/images/comment.png" width="20">
              </a>
            </div>
          </td>
          <td v-else></td>
          <td colspan="2" v-if="mode === 'create'">
            <b-button variant="primary" class="px-5 py-2 m-2" @click.prevent="issueDelegationSkillsPayment(index)" v-if="isNewPayment(payment) && formType===STATUS_CODERS_DELEGATION_SKILLS && formHourRate>0" :disabled="loading">
              Allow
            </b-button>
          </td>
          <td v-if="mode === 'edit'" class="text-center">
            <b-button variant="primary" class="px-5 py-2 m-2" @click.prevent="!checkEdit && issuePayment(payment)" v-if="isWaitingPayment(payment)" :disabled="loading">
              <check-circle-icon></check-circle-icon>Allow
            </b-button>
            <p class="success-text" v-if="isAllowedPayment(payment)"><check-circle-icon class="success-icon"></check-circle-icon> Allowed</p>
            <b-button variant="primary" class="px-5 py-2 m-2" @click.prevent="!checkEdit && issueDelegationSkillsPayment(index)" v-if="isNewPayment(payment)" :disabled="loading">
              <check-circle-icon></check-circle-icon>Allow
            </b-button>
            <p class="accent-text" v-if="isRequestedPayment(payment)"><check-circle-icon class="accent-icon"></check-circle-icon> Requested</p>
            <p class="success-text" v-if="isPaidPayment(payment)"><check-circle-icon class="success-icon"></check-circle-icon> Paid</p>
          </td>
          <td v-if="mode === 'edit'">
            <b-button variant="primary" class="px-5 py-2 m-2" @click="!checkEdit && deletePayment(payment)" v-if="isWaitingPayment(payment)">
              <trash2-icon></trash2-icon>Delete
            </b-button>
            <b-button variant="primary" class="px-5 py-2 m-2" @click="!checkEdit && deletePayment(payment)" v-if="isNewPayment(payment)">
              <trash2-icon></trash2-icon>Delete
            </b-button>
          </td>
          <td colspan="2" v-if="mode === 'coder'" class="text-center">
            <b-button variant="primary" class="px-5 py-2 m-2" @click.prevent="issuePayment(payment)" v-if="isAllowedPayment(payment)" :disabled="loading">
              {{isAllowedPayment(payment) ? 'Request' : ''}}
            </b-button>
            <b-button variant="primary" class="px-5 py-2 m-2" @click.prevent="issuePayment(payment)" v-if="isWaitingPayment(payment)" :disabled="true">
              Request
            </b-button>
            <p class="accent-text" v-if="isRequestedPayment(payment)"><check-circle-icon class="accent-icon"></check-circle-icon> Requested</p>
            <p class="success-text" v-if="isPaidPayment(payment)"><check-circle-icon class="success-icon"></check-circle-icon> Paid</p>
          </td>
        </tr>

        <tr v-if="mode==='create' && formType===STATUS_CODERS_FIXED_PRICE && formFixedPrice>0">
          <td>-</td>
          <td>{{symbol}} {{formFixedPrice/2}}</td>
          <td colspan="2">{{firstFixedPaymentStatus}}</td>
          <td>
            <b-button v-if="firstFixedPaymentStatus===STATUS_CODERS_WAITING_PAYMENT" variant="primary" class="px-5 py-2 m-2" @click.prevent="issueFixedPayment(1)">Allow</b-button>
          </td>
        </tr>
        <tr v-if="mode==='create' && formType===STATUS_CODERS_FIXED_PRICE && formFixedPrice>0">
          <td>-</td>
          <td>{{symbol}} {{formFixedPrice/2}}</td>
          <td colspan="2">{{secondFixedPaymentStatus}}</td>
          <td>
            <b-button v-if="secondFixedPaymentStatus===STATUS_CODERS_WAITING_PAYMENT" variant="primary" class="px-5 py-2 m-2" @click.prevent="issueFixedPayment(2)">Allow</b-button>
          </td>
        </tr>

        <tr v-if="loading">
          <td colspan="6">
            <div v-if="loading" class="text-center py-4">
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <b-modal
      id="modal-feedback-show"
      ref="modal-feedback-show"
      centered
      title="Feedback"
      hide-footer
    >
      <div class="container justify-content-center">
        <div class="row" v-if="feedback.target==='admin'">
          <div class="col-md-12" v-if="feedback.commentForManager">
            <b-form-group label="Feedback for manager">
              <b-form-textarea v-model="feedback.commentForManager" rows="3" disabled></b-form-textarea>
            </b-form-group>
            <b-form-group label="Feedback for admin">
              <b-form-textarea v-model="feedback.commentForManagerAdmin" rows="3" disabled></b-form-textarea>
            </b-form-group>
          </div>
          <div class="col-md-12" v-if="feedback.commentForCoder">
            <b-form-group label="Feedback for coder">
              <b-form-textarea v-model="feedback.commentForCoder" rows="3" disabled></b-form-textarea>
            </b-form-group>
            <b-form-group label="Feedback for admin">
              <b-form-textarea v-model="feedback.commentForCoderAdmin" rows="3" disabled></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-12">
            <b-form-group :label="'Feedback for ' + feedback.target">
              <b-form-textarea v-model="feedback.comment" rows="3" disabled></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-modal>
    <b-alert show variant="danger" v-if="checkEdit && mode !== 'coder'">
        You cannot edit this payment
    </b-alert>
  </div>
</template>

<script>
import { PlusIcon, Trash2Icon, ClockIcon, CheckCircleIcon } from 'vue-feather-icons';
import {
  CODERS_WAITING_PAYMENT,
  CODERS_ALLOWED_PAYMENT,
  CODERS_REQUESTED_PAYMENT,
  CODERS_PAID_PAYMENT,
  CODERS_FIXED_PRICE,
  CODERS_DELEGATION_SKILLS
} from '../../constants';
import moment from 'moment';

export default {
  components: {
    PlusIcon,
    Trash2Icon,
    ClockIcon,
    CheckCircleIcon
  },
  props: ['payments', 'symbol', 'isOpen', 'loading', 'mode', 'formType', 'formFixedPrice', 'formHourRate','checkEdit'],
  name: 'ComponentPayment',
  computed: {
    userRole() {
      return this.$store.getters['security/getRole'];
    },
    STATUS_CODERS_WAITING_PAYMENT: function(){
      return CODERS_WAITING_PAYMENT;
    },
    STATUS_CODERS_ALLOWED_PAYMENT: function(){
      return CODERS_ALLOWED_PAYMENT;
    },
    STATUS_CODERS_PAID_PAYMENT: function(){
      return CODERS_PAID_PAYMENT;
    },
    STATUS_CODERS_REQUESTED_PAYMENT: function(){
      return CODERS_REQUESTED_PAYMENT;
    },
    STATUS_CODERS_FIXED_PRICE: function(){
      return CODERS_FIXED_PRICE;
    },
    STATUS_CODERS_DELEGATION_SKILLS: function(){
      return CODERS_DELEGATION_SKILLS;
    }
  },
  data: function () {
    return {
      loadingInProgress:false,
      loadingSuccess:false,
      isAllowedSuccessfuly:false,
      elementId: null,
      firstFixedPaymentStatus: CODERS_WAITING_PAYMENT,
      secondFixedPaymentStatus: CODERS_WAITING_PAYMENT,
      paymentsUpdtIdx:0,
      feedback: {
        target: '',
        comment: '',
        commentForManager: '',
        commentForManagerAdmin: '',
        commentForCoder: '',
        commentForCoderAdmin: ''
      },
      hours: 0
    }
  },
  methods: {
    hasFeedback(items) {
      if (this.userRole==='admin') {
        return true;
      }
      let hasFeedback = false;
      if (this.userRole==='coder') {
        for(let i=0; i<items.length; i++) {
          if (items[i].protectedCoderComment) {
            hasFeedback = true; break;
          }
        }
      } else {
        for(let i=0; i<items.length; i++) {
          if (items[i].protectedManagerComment) {
            hasFeedback = true; break;
          }
        }
      }
      return hasFeedback;
    },
    showFeedbackView(items) {
      this.feedback = {
        target: '',
        comment: '',
        commentForManager: '',
        commentForManagerAdmin: '',
        commentForCoder: '',
        commentForCoderAdmin: ''
      };
      if (this.userRole==='admin') {
        this.feedback.target = 'admin';
        for(let i=0; i<items.length; i++) {
          if (items[i].protectedManagerComment) {
            this.feedback.commentForManager = items[i].protectedManagerComment;
            this.feedback.commentForManagerAdmin = items[i].privateManagerComment;
          }
          if (items[i].protectedCoderComment) {
            this.feedback.commentForCoder = items[i].protectedCoderComment;
            this.feedback.commentForCoderAdmin = items[i].privateCoderComment;
          }
        }
      } else if (this.userRole==='coder') {
        this.feedback.target = 'coder';
        for(let i=0; i<items.length; i++) {
          if (items[i].protectedCoderComment) {
            this.feedback.comment = items[i].protectedCoderComment; break;
          }
        }
      } else {
        this.feedback.target = 'manager';
        for(let i=0; i<items.length; i++) {
          if (items[i].protectedManagerComment) {
            this.feedback.comment = items[i].protectedManagerComment; break;
          }
        }
      }
    },
    testAnimation(index){
      this.elementId = index;
      this.loadingInProgress = true;
      setTimeout(() => {
        this.loadingInProgress = false;
        this.isAllowedSuccessfuly = true;
      }, 1000);
    },
    deletePayment(payment){
        this.$emit('delete', payment.uuid);
      },
    addPayment() {
      this.$emit('add');
    },
    requestPayment(payment) {
      this.$emit('request', payment.uuid);
    },
    allowPayment(payment) {
      this.$emit('allow', payment.uuid)
    },
    issuePayment(payment) {
      if(this.mode==='edit' && this.isWaitingPayment(payment)) {
          this.$emit('allow', payment.uuid);
          //payment.status = CODERS_ALLOWED_PAYMENT;
      } else if(this.mode==='create' && this.isWaitingPayment(payment) && this.formHourRate>0) {
          //payment.status = CODERS_ALLOWED_PAYMENT;
        this.$emit('request', payment.uuid);
      } else if (this.mode==='coder' && this.isAllowedPayment(payment) && !this.isRequestedPayment(payment)) {
          //payment.status = CODERS_REQUESTED_PAYMENT;
          this.$emit('request', payment.uuid);
      }
    },
    issueDelegationSkillsPayment(idx) {
      this.$emit('setDelegationSkillsPaymentStatus', idx, CODERS_ALLOWED_PAYMENT);
    },
    issueFixedPayment(idx) {
      if(idx===1){
        this.firstFixedPaymentStatus = CODERS_ALLOWED_PAYMENT;
      }
      if(idx===2){
        this.secondFixedPaymentStatus = CODERS_ALLOWED_PAYMENT;
      }
      this.$emit('setFixedPaymentStatus', idx, CODERS_ALLOWED_PAYMENT);
    },
    onRemovePayment(payment, index) {
      if (payment.required) {
        this.$emit('remove-fixed', index);
      } else {
        this.$emit('remove', index);
      }
    },
    isAllowedPayment(payment) {
      return payment.status === CODERS_ALLOWED_PAYMENT;
    },
    isWaitingPayment(payment) {
      return payment.status === CODERS_WAITING_PAYMENT;
    },
    isRequestedPayment(payment) {
      return payment.status === CODERS_REQUESTED_PAYMENT;
    },
    isPaidPayment(payment) {
      return payment.status === CODERS_PAID_PAYMENT;
    },
    isNewPayment(payment) {
      return typeof payment.status === "undefined" || payment.status === '' || !payment.status;
    },
  },
  created() {},
  mounted() {},
};
</script>

