var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-fluid px-2 component-info-list" }, [
      _c("table", { staticClass: "table rounded-table dash-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.components, function (component, index) {
            return _c(
              "tr",
              {
                key: index,
                class: _vm.isPaymentAllowed(component)
                  ? "highlight-darkred"
                  : "",
                staticStyle: { cursor: "auto" },
              },
              [
                _c("td", [_vm._v(_vm._s(component.name))]),
                _vm._v(" "),
                _c("td"),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("moment")(component.endDate, "DD/MM/YYYY")
                      ) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm.isHourly(component)
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("symbol")(component.currency)) +
                            _vm._s(component.dayRate)
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFixed(component)
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("symbol")(component.currency)) +
                            _vm._s(component.fixedPrice)
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center component-view",
                    },
                    [
                      _c("inbox-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isHourly(component),
                            expression: "isHourly(component)",
                          },
                        ],
                      }),
                      _vm._v(" "),
                      _c("tag-icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isFixed(component),
                            expression: "isFixed(component)",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "component-view font-accent" }, [
                    _c("span", { staticClass: "d-block" }, [
                      _vm._v(_vm._s(component.status)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.getComponentUrl(component)
                        },
                      },
                    },
                    [
                      component.manager && component.manager.uuid === _vm.userId
                        ? _c(
                            "span",
                            [_c("edit2-icon", { staticClass: "p-1 ml-2" })],
                            1
                          )
                        : _c(
                            "span",
                            [_c("eye-icon", { staticClass: "p-1 ml-2" })],
                            1
                          ),
                    ]
                  ),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", {}, [
      _c("th", [_vm._v("Component Name")]),
      _vm._v(" "),
      _c("th", {}, [_c("span", { staticClass: "d-block" }, [_vm._v("Team")])]),
      _vm._v(" "),
      _c("th", [_vm._v("Deadline")]),
      _vm._v(" "),
      _c("th", [_vm._v("Price")]),
      _vm._v(" "),
      _c("th", [_vm._v("Type")]),
      _vm._v(" "),
      _c("th", { staticClass: "font-accent" }, [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Actions")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }