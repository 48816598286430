var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "coder-search d-flex align-items-center justify-content-between px-3",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "form-group d-flex align-items-center border-bottom mr-3",
        },
        [
          _c("search-icon", { staticClass: "search-icon" }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.coder,
                expression: "coder",
              },
            ],
            staticClass: "form-control form-control-sm",
            attrs: { placeholder: "Search a coder", type: "text" },
            domProps: { value: _vm.coder },
            on: {
              keyup: _vm.onChange,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.coder = $event.target.value
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "form-group d-flex align-items-center border-bottom ml-3",
        },
        [
          _c("search-icon", { staticClass: "search-icon" }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.component,
                expression: "component",
              },
            ],
            staticClass: "form-control form-control-sm",
            attrs: { placeholder: "Search a component", type: "text" },
            domProps: { value: _vm.component },
            on: {
              keyup: _vm.onChange,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.component = $event.target.value
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.page === "Components"
        ? _c(
            "div",
            {
              staticClass:
                "form-group d-flex align-items-center border-bottom ml-3",
            },
            [
              _c("search-icon", { staticClass: "search-icon" }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.projectName,
                    expression: "projectName",
                  },
                ],
                staticClass: "form-control form-control-sm",
                attrs: { placeholder: "Search a project", type: "text" },
                domProps: { value: _vm.projectName },
                on: {
                  keyup: _vm.onChange,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.projectName = $event.target.value
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.page === "Components"
        ? _c(
            "div",
            {
              staticClass:
                "form-group d-flex align-items-center border-bottom ml-3",
            },
            [
              _c("search-icon", { staticClass: "search-icon" }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.cityId,
                    expression: "cityId",
                  },
                ],
                staticClass: "form-control form-control-sm",
                attrs: { placeholder: "Search a city", type: "text" },
                domProps: { value: _vm.cityId },
                on: {
                  keyup: _vm.onChange,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.cityId = $event.target.value
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }