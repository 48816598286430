<template>
  <div class="card p-2">
    <div class="">
      <div class="d-flex justify-content-end" v-if="isAdmin">
          <router-link :to="{name:'editCoder',params:{editCoder: coder}}">
              <edit-2-icon class="p-1 ml-2"></edit-2-icon>
          </router-link>
      </div>
    </div>
    <div class="d-flex align-items-center flex-column user-info">
      <div class="avatar p-2">
        <div class="img-wrap">
          <img v-if="coder.avatar && coder.email" width="150px" height="150px" class="td-avatar" :src="'/images/users/'+coder.email+coder.avatar" alt=""/>
          <img v-else-if="coder.avatar && coder.accountAddress" width="150px" height="150px" class="td-avatar" :src="'/images/users/'+coder.accountAddress+coder.avatar" alt=""/>
          <img v-else :src="'https://ui-avatars.com/api/?name=' + coder.firstName + '+' + coder.lastName + '&size=150'"/>
        </div>
      </div>
      <div class="details p-2 mt-3">
        <h3 class="mb-3 font-medium">{{[coder.firstName,coder.lastName].join(' ')}}</h3>
        <div class="d-flex user-detail">
          <map-pin-icon></map-pin-icon>
          <div class="pl-2" v-if="coder.address">
            <h6>{{[coder.address.city,coder.address.country].join(', ')}}</h6>
          </div>
        </div>
        <div class="d-flex mt-2 user-detail">
          <globe-icon></globe-icon>
          <div class="pl-2" v-if="typeof coder.timezone === 'number'">
            <h6> GMT {{coder.timezone > 0 ? `+${coder.timezone}` : `+${coder.timezone}`}}</h6>
          </div>
        </div>
        <div class="d-flex mt-2 user-detail">
          <code-icon></code-icon>
          <div class="pl-2">
            <h6>{{coder.skills && coder.skills.substring(0,200)}}</h6>
          </div>
        </div>
        <div class="d-flex mt-2 user-detail">
          <dollar-sign-icon></dollar-sign-icon>
          <div class="pl-2" v-if="coder.hourlyRate">
            <h6><strong>${{coder.hourlyRate}}/H - ${{ dailyRate }}/DAY </strong></h6>
          </div>
        </div>
      </div>
    </div>


    <div class="my-2 d-flex text-center justify-content-center user-stats">
      <div class="mx-4">
        <div class="user-stat">
          <h1 class="font-semibold font-accent">{{components}}</h1>
          <h6 class="stat-label">COMPONENTS</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    MessageCircleIcon,
    CodeIcon,
    GlobeIcon,
    MapPinIcon,
    MoreHorizontalIcon,
    Edit2Icon,
    DollarSignIcon
  } from 'vue-feather-icons'
  import ViewComponentModal from './../admin/ViewComponentModal';
  export default {
    props: ['coder','components', 'admins'],
    components: {
      MessageCircleIcon,
      CodeIcon,
      GlobeIcon,
      MapPinIcon,
      MoreHorizontalIcon,
      Edit2Icon,
      DollarSignIcon,
      ViewComponentModal,
    },
    name: 'CoderInfo',
    computed: {
      isAdmin() {
        const role = this.$store.getters['security/getRole'];
        return role === 'admin';
      },

      dailyRate(){
        return this.coder.hourlyRate * 8;
      }
    },
    data: function() {
      return {
        admin: ''
      }
    }
  };
</script>
