<template>
    <div>
        <div class="row d-flex justify-content-around m-0" style="width: 100%;">
            <id-filter v-on:search="onSearchId"></id-filter>
            <coders-filter v-on:search="onSearchCoder" v-if="coderFilter!=='own'"></coders-filter>
            <amount-filter v-on:search="onSearchAmount"></amount-filter>
            <project-filter v-on:search="onSearchProject"></project-filter>
            <date-range-filter v-on:search="onSearchDate"></date-range-filter>
        </div>
        <div class="admin-table mt-4 mb-5">
            <ApolloQuery
                    :query="require('../../../graphql/coder/query/invoiceWithCoders.gql')"
                    :variables="{ id: idFilter, coder: coderFilter!=='own'? coderFilter : '', amount: amountFilter, startDate, endDate, project: projectFilter, offset: filterOffset, limit: filterLimit }"
                    :options="{notifyOnNetworkStatusChange: true}"
                    fetchPolicy='no-cache'
            >
                <template v-slot="{ result: { loading, error, data } }">
                    <div v-if="loading" class="text-center py-4">
                        <b-spinner label="Spinning"></b-spinner>
                    </div>
                    <div v-if="error">An error occured</div>
                    <div class="mt-3 list-user">
                        <table v-if="!loading" class="table">
                            <thead>
                            <th>#</th>
                            <th>Coder name</th>
                            <th>Amount dollar</th>
                            <th>Amount euro</th>
                            <th>Date</th>
                            <th></th>
                            </thead>
                            <tbody v-if="data.invoicesWithCoders.items">
                            <tr v-for="(invoice,i) in data.invoicesWithCoders.items" :key="i">
                                <td>{{invoice.id}}</td>
                                <td>{{invoice | name}}</td>
                                <td>{{invoice.amountDollar |amountDollar}}</td>
                                <td>{{invoice.amountEuro }} €</td>
                                <td>{{invoice.creationDate | formatDte}}</td>
                                <td><a :href="`/api/download-invoice/${invoice.id}/${invoice.pdfPath}`" target="_blank"><span
                                        class="pdf"></span></a></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="admin-accordions" v-if="!loading">
                        <div class="user-table admin-dash-table" v-if="!data.invoicesWithCoders.items.length"><h6>No
                            Requested invoices</h6></div>
                    </div>
                    <b-pagination v-if="!loading && data.invoicesWithCoders.count > filterLimit" :value="currentPage" :total-rows="data.invoicesWithCoders.count" :per-page="filterLimit" align="center" @change="onPaginationChange" />
                </template>
            </ApolloQuery>
        </div>
    </div>
</template>
<script>
  import PayerAccordion from "../../../components/admin/PayerAccordion";
  import IdFilter from "../../../components/admin/IdFilter";
  import CodersFilter from "../../../components/admin/CodersFilter";
  import DateRangeFilter from "../../../components/admin/DateRangeFilter";
  import AmountFilter from "../../../components/admin/AmountFilter";
  import ProjectFilter from "../../../components/admin/ProjectFilter";
  import moment from 'moment';
  import {DollarSignIcon} from 'vue-feather-icons';
  import {ArrowDownIcon} from "vue-feather-icons";
  import {mapState} from "vuex";

  export default {
    name: "invoice",
    components: {
      IdFilter,
      CodersFilter,
      AmountFilter,
      ProjectFilter,
      PayerAccordion,
      DollarSignIcon,
      DateRangeFilter,
      ArrowDownIcon
    },
    data: function () {
      return {
        filterLimit: 20
      };
    },
    created() {
      this.initFilters();
    },
    computed: {
      ...mapState('invoice', [
        'idFilter',
        'coderFilter',
        'amountFilter',
        'dateFilter',
        'projectFilter',
        'filterOffset',
        'currentPage'
      ]),
      startDate() {
        return this.convertDateFormat(this.dateFilter[0]);
      },
      endDate() {
        return this.convertDateFormat(this.dateFilter[1]);
      },
      isCoder() {
        return this.$store.getters['security/hasRole']('coder');
      }
    },
    methods: {
      initFilters() {
        this.$store.commit('invoice/SET_ID_FILTER', '');
        this.$store.commit('invoice/SET_CODER_FILTER', this.isCoder ? 'own' : '');
        this.$store.commit('invoice/SET_AMOUNT_FILTER', null);
        this.$store.commit('invoice/SET_DATE_FILTER', [null, null]);
        this.$store.commit('invoice/SET_PROJECT_FILTER', '');
      },
      onSearchId(filter) {
        this.$store.commit('invoice/SET_ID_FILTER', filter);
      },
      onSearchCoder(filter) {
        this.$store.commit('invoice/SET_CODER_FILTER', filter);
      },
      onSearchAmount(filter) {
        this.$store.commit('invoice/SET_AMOUNT_FILTER', filter);
      },
      onSearchDate(filter) {
        this.$store.commit('invoice/SET_DATE_FILTER', filter);
      },
      onSearchProject(filter) {
        this.$store.commit('invoice/SET_PROJECT_FILTER', filter);
      },
      convertDateFormat(date) {
        if (date === null) {
          return null;
        }
        return moment(String(date)).format('YYYY-MM-DD');
      },
      onPaginationChange(page) {
        let offset = (page - 1) * this.filterLimit;
        this.$store.commit('invoice/SET_FILTER_OFFSET', offset);
        this.$store.commit('invoice/SET_CURRENT_PAGE', page ? page : 1);
      }
    },
    filters: {
      name(invoice) {
        let coder = invoice.firstPayment.component.coder;
        return coder.firstName + ' ' + coder.lastName;
      },
      formatDte(date) {
        return moment(String(date)).format('MM-DD-YYYY');
      },
      amountDollar(amount) {
        return amount ? `$ ${amount}` : `-`;
      }
    }
  }

</script>