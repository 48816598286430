<template>
  <div class="container-fluid dash p-0">
    <div>
      <button class="btn btn-secondary btn-back" @click.prevent="onBack">
        <arrow-left-icon class="custom-class"></arrow-left-icon>Back
      </button>
    </div>
    <div class="border-bottom my-3"></div>
    <div class="container-fluid px-0">
      <div class="d-flex flex-sm-column flex-lg-row pt-2">
        <div class="p-0 coder-cards">
         <profile-info :components="length" :coder="coder" :admins="admins"></profile-info>
          {{payment[0]}}
          <payment-info :payment="payment"></payment-info>
        </div>
        <div class="p-0 coder-table flex-grow-1">
          <component-list v-if="coder.uuid" :user_uuid="coder.uuid" v-on:update-length="updateLength"></component-list>
          <div v-if="isLoading" class="text-center py-4">
            <b-spinner label="Spinning"></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProfileInfo from '../../../components/coder/CoderInfo';
  import ComponentList from '../../../components/coder/ComponentList';
  import PaymentInfo from '../../../components/coder/PaymentInfo';
  import {
    CREATE_COMPONENT,
    ASSIGN_COMPONENT
  } from '../../../constants';
  import {
    ArrowLeftIcon
  } from 'vue-feather-icons';

  export default {
    name: "CoderDashboard",
    components: {
      ProfileInfo,
      ComponentList,
      PaymentInfo,
      ArrowLeftIcon,
    },  
    created: function(){
      this.$store.dispatch('coders/getCoderById', {uuid: this.$route.params.uuid});
      this.$store.dispatch('coders/getAdmins');
      this.$store.dispatch('payments/getCoderPaymentsById', {uuid: this.$route.params.uuid});
    },  
    computed: {
      isLoading() {
        return this.$store.getters['coders/isLoading'];
      },
      hasError() {
        return this.$store.getters['coders/hasError'];
      },
      error() {
        return this.$store.getters['coders/error'];
      },     
      payment() {
        let payments = this.$store.getters['payments/getCoderPayments'];
        let total = 0; let periods = []; let amounts = [];
        payments.forEach(function(pay){
          total = parseFloat(total) + parseFloat(pay.amount);
          amounts.push(parseFloat(pay.amount));
          periods.push(pay.period);
        });

        return {
          total: total,
          data: {
            periods: periods,
            amounts: amounts,
            currentMonth: 345,
          }
        }
      },     
      admins() {
        return this.$store.getters['coders/getAdmins'];
      },
      coders() {
        return this.$store.getters['coders/getCoders'];
      },
      coder() {
        return this.$store.getters['coders/getCoder'];
      },
      STATUS_CREATE_COMPONENT: function(){
        return CREATE_COMPONENT;
      }
    },
    data: function() {
      return {
        length: 0
      }
    },
    methods: {
      updateLength(e){
        this.length = e;
      },
      onBack() {
        this.$router.go(-1);
      },
    }
  };
</script>

<style scoped>
.nopadding {
  padding: 0 !important;
}
</style>