<template>
  <div class="">
    <div class="d-flex  align-items-center page-title mb-3">
      <div>
        <button class="btn btn-secondary btn-back" @click.prevent="back">
          <arrow-left-icon class="custom-class"></arrow-left-icon>Back
        </button>
      </div>
      <div class="ml-4">
        <h2 class="dash-header-title m-0">Create Component</h2>
      </div>
    </div>
    <div class="border-bottom my-3"></div>
    <component-form class="mt-5" v-on:save="onSave" mode="create" :isLoading="isLoading" :error="error"></component-form>
  </div>
</template>

<script>
  import {
    ArrowLeftIcon
  } from 'vue-feather-icons';
  import ComponentForm from '../../../components/admin/ComponentForm';
  import DashHeader from '../../../components/Header';
  
  export default {
    name: "CreateComponent",
    components: {
      ArrowLeftIcon,
      ComponentForm,
      DashHeader,
    },
    computed: {
      isLoading() {
        return this.$store.getters['components/isLoading'] || this.$store.getters['payments/isLoading'];
      },
      hasError() {
        return this.$store.getters['components/hasError'];
      },
      error() {
        return this.$store.getters['components/error'];
      },
    },
    methods: {
      onSave: function({
        component,
        payments
      }) {
        this.$store.dispatch('components/saveComponent', {
          component,
          callback: (component) => {
            const _payments = payments.map(payment => {
              return {
                componentId: component.uuid,
                amount: payment.amount,
                status: payment.status,
                uuid: typeof payment.uuid !== "undefined" ? payment.uuid : null
              }
            });
            this.$store.dispatch('payments/saveBulkPayment', {
              userId: component.coder.uuid,
              componentId: component.uuid,
              payments: _payments,
              callback: () => {
                this.$root.$bvToast.toast('Component Created', {
                  toaster: 'b-toaster-top-center',
                  variant: 'success',
                  solid: true,
                  noCloseButton: true,
                  autoHideDelay: 2000
                })
                this.$router.push({
                  path: '/dashboard'
                });
              }
            })
          }
        });
      },
      back() {
        this.$router.go(-1);
      }
    }
  };
</script>