var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card py-4 pl-2 user-data" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex justify-content-center p-4" }, [
      _c("h1", { staticClass: "font-semibold font-accent" }, [
        _c("span", { staticClass: "px-2" }, [_vm._v("$")]),
        _vm._v(_vm._s(_vm.payment.total.toLocaleString())),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "p-2" }, [
      _c(
        "div",
        { staticClass: "mx-auto d-flex" },
        [
          _c("highcharts", {
            staticClass: "payment-chart",
            attrs: { options: _vm.chartOptions },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex" }, [
      _c("h4", { staticClass: "mx-1 font-accent" }, [
        _vm._v("Total Earnings "),
        _c("span", { staticStyle: { "font-size": "14px" } }, [
          _vm._v("(Last 12 Month)"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }