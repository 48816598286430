var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-components" },
    [
      _c(
        "dashboard-header",
        { attrs: { name: "Components" } },
        [
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "router-link",
                { attrs: { to: _vm.getCreateComponentUrl() } },
                [
                  _c(
                    "button",
                    { staticClass: "btn btn-primary px-3" },
                    [_c("plus-icon"), _vm._v(" Create component")],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("component-filter", {
        attrs: { page: "Components" },
        on: { search: _vm.onSearch },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3 list-component" },
        [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "text-center py-4" },
                [_c("b-spinner", { attrs: { label: "Spinning" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("table", { staticClass: "table dash-table" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.components
              ? _c(
                  "tbody",
                  [
                    _vm._l(_vm.components, function (c, i) {
                      return _c(
                        "tr",
                        { key: i, staticStyle: { cursor: "auto" } },
                        [
                          _c("td", { staticClass: "compo-name" }, [
                            _vm._v(_vm._s(c.name)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "compo-name" }, [
                            _vm._v(_vm._s(c.projectName)),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            c.coder
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(c.coder.firstName) +
                                      " " +
                                      _vm._s(c.coder.lastName)
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            c.manager
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(c.manager.firstName) +
                                      " " +
                                      _vm._s(c.manager.lastName)
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "compo-price-icon" }, [
                            _c(
                              "span",
                              [
                                c.type === _vm.STATUS_CODERS_FIXED_PRICE
                                  ? _c("tag-icon", { staticClass: "p-1" })
                                  : _c("inbox-icon", { staticClass: "p-1" }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "compo-price" }, [
                            _c("span", { staticClass: "mx-1" }, [
                              _vm._v(
                                _vm._s(c.currency === "EUR" ? "€" : "$") +
                                  _vm._s(c.fixedPrice || c.dayRate) +
                                  _vm._s(c.dayRate ? " / day" : null)
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "compo-manager" }, [
                            _vm._v(_vm._s(_vm.getDate(c.startDate))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "font-accent compo-status" },
                            [
                              _c("span", { staticClass: "mx-1" }, [
                                _vm._v(_vm._s(c.status)),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: _vm.getEditComponentUrl(c) } },
                                [
                                  _vm.isAdmin ||
                                  (c.manager && c.manager.uuid === _vm.userId)
                                    ? _c(
                                        "span",
                                        [
                                          _c("edit2-icon", {
                                            staticClass: "p-1 ml-2",
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        [
                                          _c("eye-icon", {
                                            staticClass: "p-1 ml-2",
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    !_vm.components.length
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "8" } }, [
                            _vm._v("No component to show"),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.count,
              "per-page": _vm.filter.limit,
              align: "center",
            },
            on: { change: _vm.onPaginationChange },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Project")]),
      _vm._v(" "),
      _c("th", [_vm._v("Coder")]),
      _vm._v(" "),
      _c("th", [_vm._v("Manager")]),
      _vm._v(" "),
      _c("th", [_vm._v("Type")]),
      _vm._v(" "),
      _c("th", [_vm._v("Price")]),
      _vm._v(" "),
      _c("th", [_vm._v("Date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Status")]),
      _vm._v(" "),
      _c("th", [_vm._v("Actions")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }