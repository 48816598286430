<template>
	<div class="coder-search d-flex align-items-center justify-content-between">
		<div class="form-group d-flex align-items-center border-bottom">
			<search-icon class="search-icon"></search-icon>
			<input class="form-control form-control-sm" placeholder="Search a coder" type="text" v-model="text" @keyup="onChange"/>
		</div>
		<!-- <div class="sort-wrapper d-flex align-items-center">
			<filter-icon class="filter-icon"></filter-icon>
			<span>Sort By</span>
		</div> -->
	</div>
</template>

<script>
import {
	FilterIcon,
	SearchIcon
} from 'vue-feather-icons';

export default {
	name: 'CodersFilter',
	components: {
		FilterIcon,
		SearchIcon
	},
	props: ['keyword', 'sort_by', 'page'],
	data: function() {
		return {
			text: '',
		}
	},
	computed: {},
	methods: {
		onChange: function() {
			this.$emit('search', this.text);
		}
	},
	created() {
		if (this.page === 'Coders') {
			this.text = this.$store.getters['coders/codersFilter'];
		}
	},
	mounted() {},
};
</script>
