<template>
  <div class="auth-wrap">
    <div class="auth-content center">
      <div class="auth-container reset">
        <div class="auth-logo-wrap">
          <img src="/images/logo.png" />
        </div>
        <div class="auth-form">
          <b-form @submit="onSubmit">
            <h2 class="mb-3 text-center">Create/Reset Password</h2>
            <b-alert show variant="danger" v-if="hasError">{{error}}</b-alert>
            <b-form-group id="fieldset-password" label="Password" label-for="input-password" :invalid-feedback="validationMsg.password" :state="validateState('password')">
              <b-form-input id="input-password" type="password" v-model="password" placeholder="Enter password" name="password" v-validate="{ required: true }" ref="password" :state="validateState('password')" :disabled="isLoading"></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-password-repeat" label="Repeat Password" label-for="input-password-repeat" :invalid-feedback="validationMsg.confirmPassword" :state="validateState('password-repeat')">
              <b-form-input id="input-password-repeat" type="password" v-model="confirmPassword" placeholder="Enter password again" name="password-repeat" v-validate="{ required: true, confirmed: password }" :state="validateState('password-repeat')" :disabled="isLoading"></b-form-input>
            </b-form-group>
            <div class="form-group auth-item my-4">
              <div v-if="isLoading" class="text-center py-4">
                <b-spinner label="Spinning"></b-spinner>
              </div>
              <b-button type="submit" variant="primary" class="btn-block py-2" :disabled="isLoading">Validate</b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'resetPassword',
    data() {
      return {
        accountAddress: '',
        password: '',
        confirmPassword: '',
        validationMsg: {
          password: 'Please input password',
          confirmPassword: 'Password does not match'
        }
      };
    },
    computed: {
      isLoading() {
        return this.$store.getters['security/isLoading'];
      },
      hasError() {
        return this.$store.getters['security/hasError'];
      },
      error() {
        return this.$store.getters['security/error'];
      },
      token() {
        return this.$state.getters['security/token'];
      }
    },
    mounted() {
      if (!this.$store.getters['security/tokenVerified']) {
        this.$router.push({path: '/connection'});
      }
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault();
        const password = this.password;
        this.$store.dispatch('security/getEmailFromToken');
        this.$validator.validateAll()
          .then(() => {
            if (!this.errors.any()) {
              this.$store.dispatch('security/resetPassword', {
                password,
                callback: () => {
                  let login = this.$store.getters['security/accountAddress'];
                  let payload = {
                    login: login,
                     password: password
                  }
                  this.$store.dispatch('security/login', payload)
                  .then(() => {
                    if (!this.$store.getters['security/hasError']) {
                      this.$router.push({
                        path: '/coder'
                      })
                    }
                  });
                }
              });
            }
          })
      },
      validateState(ref) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.errors.has(ref);
        }
        return null;
      }
    }
  }
</script>
