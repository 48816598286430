var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table user-table-content mr-2" },
    [
      _vm.payments.length === 0
        ? _c("tr", [
            _c("td", { attrs: { colspan: "10" } }, [
              _vm._v("No components to show"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.payments, function (payment, index) {
        return _c(
          "tr",
          {
            key: index,
            class: _vm.selectedPaymts.includes(payment.uuid) ? "selected" : "",
          },
          [
            _c(
              "td",
              { staticStyle: { width: "100px", "text-align": "center" } },
              [
                _c("div", { staticClass: "payer-component-checkmark" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedPaymts,
                        expression: "selectedPaymts",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "payer-component-check-" + payment.uuid,
                      disabled: !_vm.isPayer,
                    },
                    domProps: {
                      value: payment.uuid,
                      checked: Array.isArray(_vm.selectedPaymts)
                        ? _vm._i(_vm.selectedPaymts, payment.uuid) > -1
                        : _vm.selectedPaymts,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.selectedPaymts,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = payment.uuid,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.selectedPaymts = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.selectedPaymts = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.selectedPaymts = $$c
                          }
                        },
                        function ($event) {
                          _vm.updateTotalPayment(
                            _vm.coder,
                            payment.amount,
                            _vm.selectedPaymts.includes(payment.uuid),
                            payment,
                            payment.component.currency,
                            payment.component.cityId
                          )
                        },
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { attrs: { for: "payer-component-check-" + payment.uuid } },
                    [_c("span")]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(payment.component.name))]),
            _vm._v(" "),
            _c("td", [
              _c("span", [_vm._v(_vm._s(_vm.managerName(payment.component)))]),
            ]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(_vm.coder.email))]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                _vm._s(_vm._f("symbol")(payment.component.currency)) +
                  " " +
                  _vm._s(payment.amount)
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.requestedDateFormat(payment.requestedDate)
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c("td", {}, [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.pmIcon(_vm.coder.paymentMethod)),
                },
              }),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "font-accent" }, [
              _vm.payments.length > 0
                ? _c("span", [_vm._v(_vm._s(payment.lockedId ? "Locked" : ""))])
                : _vm._e(),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }