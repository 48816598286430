var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dashboard-header",
        { attrs: { name: "Users" } },
        [
          _vm.isAdmin
            ? _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: _vm.getCreateCoderUrl() } },
                    [
                      _c(
                        "button",
                        { staticClass: "btn btn-primary px-3" },
                        [_c("user-plus-icon"), _vm._v("Create user")],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("coders-filter", {
        attrs: { page: "Coders" },
        on: { search: _vm.onSearch },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "mt-3 list-user" }, [
        _c("table", { staticClass: "table" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm.codersAdmin
            ? _c(
                "tbody",
                [
                  _vm._l(_vm.codersAdmin.items, function (u, i) {
                    return _c("tr", { key: i }, [
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "td-avatar-wrap d-flex align-items-center justify-content-center",
                          },
                          [
                            u.avatar
                              ? _c("img", {
                                  staticClass: "td-avatar",
                                  attrs: {
                                    width: "35px",
                                    height: "35px",
                                    src: "/images/users/" + u.email + u.avatar,
                                    alt: "",
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src:
                                      "https://ui-avatars.com/api/?name=" +
                                      u.firstName +
                                      "+" +
                                      u.lastName +
                                      "&size=35",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(u.firstName))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(u.lastName))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(u.email))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.beautifyLabelRole(u)))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            u.hourlyRate
                              ? `${u.currency === "USD" ? "$" : "€"} ${
                                  u.hourlyRate * 8
                                } / ${u.hourlyRate}`
                              : ""
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _vm.isAdmin
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "preview-btn btn p-0 mr-1",
                                    attrs: {
                                      to: {
                                        name: "editCoder",
                                        params: { uuid: u.uuid },
                                      },
                                    },
                                  },
                                  [
                                    _c("edit-2-icon", {
                                      staticClass: "p-1 ml-2",
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isAdmin
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn p-0",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onConfirmDeleteCoder(u)
                                      },
                                    },
                                  },
                                  [
                                    _c("trash-icon", {
                                      staticClass: "p-1 ml-2",
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.beautifyLabelRole(u) === "Coder"
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "preview-btn btn p-0 mr-1",
                                    attrs: {
                                      to: {
                                        name: "coderDashboard",
                                        params: { uuid: u.uuid },
                                      },
                                    },
                                  },
                                  [_c("eye-icon", { staticClass: "p-1 ml-2" })],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  !_vm.codersAdmin.items.length
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "7" } }, [
                          _vm._v("No user to show"),
                        ]),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "delete-user",
          attrs: {
            id: "delete-user",
            centered: "",
            title: "Delete User",
            "hide-footer": "",
          },
        },
        [
          _c("span", [_vm._v("Are you sure to delete this user?")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer w-100 border-0" },
            [
              _c(
                "b-button",
                {
                  staticClass: "float-right m-2",
                  attrs: { type: "submit", variant: "primary", size: "sm" },
                  on: { click: _vm.onDeleteUser },
                },
                [_vm._v("\n                OK\n            ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "float-right m-2",
                  attrs: { size: "sm" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onCancelDeleteUser.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n                Cancel\n            ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th"),
      _vm._v(" "),
      _c("th", [_vm._v("First Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Last Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Email")]),
      _vm._v(" "),
      _c("th", [_vm._v("Role")]),
      _vm._v(" "),
      _c("th", [_vm._v("Rate")]),
      _vm._v(" "),
      _c("th", [_vm._v("Action")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }