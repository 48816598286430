var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-5 d-flex metrics-board" },
    [
      _vm.error
        ? _c(
            "div",
            { staticClass: "row" },
            [
              _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
                _vm._v(_vm._s(_vm.error)),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.metrics, function (metric) {
        return _c("metric-item", {
          key: metric.key,
          attrs: { metric: metric, isLoading: _vm.isLoading },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }