<template>
    <table class="table user-table-content mr-2">
        <tr v-if="payments.length === 0">
            <td colspan="10">No components to show</td>
        </tr>
        <tr v-for="(payment, index) in payments" :key="index" :class="selectedPaymts.includes(payment.uuid) ? 'selected' : ''">
            <td style="width:100px;text-align:center">
                <div class="payer-component-checkmark">
                  <input type="checkbox" v-model="selectedPaymts" :value="payment.uuid" :id="'payer-component-check-'+payment.uuid"
                           :disabled="!isPayer" v-on:change="updateTotalPayment(coder, payment.amount, selectedPaymts.includes(payment.uuid), payment ,payment.component.currency, payment.component.cityId)"/>
                  <label :for="'payer-component-check-'+payment.uuid">
                        <span></span>
                    </label>
                </div>
            </td>
            <td>{{payment.component.name}}</td>
            <td><span>{{managerName(payment.component)}}</span></td>
            <td>{{coder.email}}</td>
            <td>{{payment.component.currency | symbol}} {{payment.amount}}</td>
            <td>
                <span v-html="requestedDateFormat(payment.requestedDate)"></span>
            </td>
            <td class="">
                <span v-html="pmIcon(coder.paymentMethod)"></span>
            </td>
            <td class="font-accent">
                <span v-if="payments.length>0">{{payment.lockedId ? 'Locked' :''}}</span>
            </td>
        </tr>
    </table>
</template>

<script>
    import moment from "moment";
    import {CheckIcon, DollarSignIcon, DownloadIcon, InboxIcon} from "vue-feather-icons";
    export default {
        name: "PayerPayment",
        props:['coder', 'payments', 'coderToPay', 'selectedPayments', 'isPayer'],
        components:{
            InboxIcon,
            CheckIcon,
            DollarSignIcon,
            DownloadIcon
        },
        data(){
            return {
                selectedPaymts: [],
                selectedLockedPaymentBefore: false,
                lastCurrency:null,
                lastCity: null,
            }
        },
        watch: {
            setSelectedPaymts(){
                this.selectedPaymts = this.selectedPayments;
            }
        },
        computed: {
            setSelectedPaymts(){
                this.selectedPaymts = this.selectedPayments;
            }
        },
        methods:{
            pmIcon(method){
                var m = method.toLowerCase();
                return this.imageWrap('/svg/payment-services/'+m.split(' ').join('-')+'-logo.svg');
            },
            managerName(component){
                return component.manager ? component.manager.firstName + ' ' + component.manager.lastName : {}
            },
            imageWrap(url,method = null){
                return `<img class="payment-mode-img" src="${url}" border="0" alt="${method} Icon"/>`
            },
            requestedDateFormat(date) {
                return date ? moment(date).format('DD/MM/YYYY') : `&ndash;`;
            },
            uploadInvoice(e,componentName){
                var f = e.target.files[0]
                //upload f
                console.log(f,componentName);
            },
            updateTotalPayment(coder2Pay, amount, isSum, payment, currency, cityId){
                let lockedId = payment.lockedId;
                if (this.selectedLockedPaymentBefore && !lockedId) {
                    this.selectedLockedPaymentBefore = false;
                    if (isSum) {
                        this.selectedPaymts = [payment.uuid];
                    } else {
                        this.selectedPaymts = [];
                    }
                    this.$emit('updateTotalPayment', { coder2Pay: coder2Pay, amount: 0, isSum: isSum, isLocked: true, selectedPayments: [], currency: currency, cityId: cityId });
                }
                if (lockedId) {
                    this.selectedPaymts = [];
                    amount = 0;
                    this.payments.map((item) => {
                        if (lockedId===item.lockedId) {
                            if (isSum) {
                                this.selectedPaymts.push(item.uuid);
                                amount += item.amount;
                            } else {
                                this.selectedPaymts.splice(
                                    this.selectedPaymts.findIndex(c => c===item.uuid),
                                    1
                                );
                            }
                        }
                    });
                } else {
                    if((this.coderToPay.uuid && coder2Pay.uuid !== this.coderToPay.uuid) || ( this.lastCurrency && this.lastCurrency!==currency ) || ( this.lastCity && this.lastCity !== cityId)){
                        this.selectedPaymts = [payment.uuid];
                    }

                }
                this.$emit('updateTotalPayment', { coder2Pay: coder2Pay, amount: amount, isSum: isSum, isLocked: !!lockedId, selectedPayments: this.selectedPaymts,currency: currency, cityId: cityId });
                this.selectedLockedPaymentBefore = !!lockedId;
                this.lastCurrency = currency;
                this.lastCity = cityId;
            }
        }
    }
</script>

<style scoped>

</style>