<template>
	<div class="project-search d-flex align-items-center justify-content-between">
		<div class="form-group d-flex align-items-center border-bottom">
			<search-icon class="search-icon"></search-icon>
			<input class="form-control form-control-sm" placeholder="Search a project" type="text" v-model="text" @keyup="onChange"/>
		</div>
	</div>
</template>

<script>
import {
	FilterIcon,
	SearchIcon
} from 'vue-feather-icons';

export default {
	name: 'ProjectFilter',
	components: {
		FilterIcon,
		SearchIcon
	},
	props: ['keyword', 'sort_by'],
	data: function() {
		return {
			text: '',
		}
	},
	computed: {},
	methods: {
		onChange: function() {
			this.$emit('search', this.text);
		}
	},
	created() {},
	mounted() {},
};
</script>
