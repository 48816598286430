var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "admin-wrapper",
    { attrs: { "page-name": "Payer" } },
    [
      _c(
        "dashboard-header",
        { attrs: { name: "Payer" } },
        [
          _c("template", { slot: "actions" }, [
            _vm.isInvoice
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary px-3",
                    on: {
                      click: function ($event) {
                        return _vm.exportInvoices()
                      },
                    },
                  },
                  [
                    _c("file-text-icon"),
                    _vm._v("\n                Export invoices\n            "),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isInvoice
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary px-3",
                    on: {
                      click: function ($event) {
                        return _vm.downloadInvoices()
                      },
                    },
                  },
                  [
                    _c("arrow-down-icon"),
                    _vm._v("\n                Download invoices\n            "),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "h3",
          { staticClass: "col-6" },
          [
            _c(
              "router-link",
              {
                class: !_vm.isPayment ? "active-color" : "inactive-color",
                attrs: { to: { name: "payment" } },
              },
              [_vm._v("\n                Payments\n            ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "h3",
          { staticClass: "col-6" },
          [
            _c(
              "router-link",
              {
                class: !_vm.isInvoice ? "active-color" : "inactive-color",
                attrs: { to: { name: "invoice" } },
              },
              [_vm._v("\n                Invoices\n            ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }