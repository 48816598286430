<template>
  <div class="user-form">
    <b-form @submit="onSubmit">
      <div class="form-section row">
        <div class="col-4">
          <h1 class="form-section-title"></h1>
          <div class="row profile-picture">
            <div class="col-sm">
              <div class="form-group">
                <label>Profile Picture</label>
                <div>
                  <picture-input
                          class="row profile-picture"
                          ref="pictureInput"
                          v-if="!editMode"
                          width="200"
                          height="200"
                          margin="16"
                          accept="image/jpeg,image/png"
                          size="10"
                          button-class="btn"
                          :custom-strings="{
                          upload: '<h1>Bummer!</h1>',
                          drag: 'Drag a profile picture!'
                          }"
                          @change="onChange">
                  </picture-input>
                    <picture-input
                            class="row profile-picture"
                            ref="pictureInput"
                            v-if="user.avatar && editMode"
                            width="200"
                            height="200"
                            margin="16"
                            accept="image/jpeg,image/png"
                            size="10"
                            :prefill="'/images/users/'+ user.email+user.avatar"
                            button-class="btn"
                            :custom-strings="{
                          upload: '<h1>Bummer!</h1>',
                          drag: 'Drag a profile picture!'
                          }"
                            @change="onChange">
                    </picture-input>
                    <picture-input
                            class="row profile-picture"
                            ref="pictureInput"
                            v-if="!user.avatar && editMode"
                            width="200"
                            height="200"
                            margin="16"
                            accept="image/jpeg,image/png"
                            size="10"
                            :prefill="'https://ui-avatars.com/api/?name=' + user.firstName + '+' + user.lastName + '&size=200.png'"
                            button-class="btn"
                            :custom-strings="{
                          upload: '<h1>Bummer!</h1>',
                          drag: 'Drag a profile picture!'
                          }"
                            @change="onChange">
                    </picture-input>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8">
          <h1 class="form-section-title">Personal Information</h1>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <b-form-group
                id="fieldset-first-name"
                label="First Name *"
                label-for="input-first-name"
                :state="validateState('user.firstName')"
                :invalid-feedback="validationMsg.firstName"
              >
                <b-form-input
                  id="input-first-name"
                  v-model="user.firstName"
                  class="form-control"
                  name="user.firstName"
                  placeholder="Enter first name"
                  v-validate="{ required: true }"
                  :state="validateState('user.firstName')"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-sm-12">
              <b-form-group
                id="fieldset-last-name"
                label="Last Name *"
                label-for="input-last-name"
                :state="validateState('user.lastName')"
                :invalid-feedback="validationMsg.lastName"
              >
                <b-form-input
                  id="input-last-name"
                  v-model="user.lastName"
                  class="form-control"
                  name="user.lastName"
                  placeholder="Enter last name"
                  v-validate="{ required: true }"
                  :state="validateState('user.lastName')"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <b-form-group
                id="fieldset-country"
                label="Country *"
                label-for="input-country"
                :invalid-feedback="validationMsg.country"
                :state="validateState('user.address.country')"
              >
                <b-form-select
                  v-model="user.address.country"
                  id="input-country"
                  class="form-control"
                  name="user.address.country"
                  v-validate="{ required: true }"
                  :options="countries"
                  :state="validateState('user.address.country')"
                >
                  <option :value="null">Select a country</option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-6 col-sm-12">
              <b-form-group
                id="fieldset-city"
                label="City *"
                label-for="input-city"
                :state="validateState('user.address.city')"
                :invalid-feedback="validationMsg.city"
              >
                <b-form-input
                  id="input-city"
                  v-model="user.address.city"
                  class="form-control"
                  name="user.city"
                  placeholder="Enter City"
                  v-validate="{ required: true }"
                  :state="validateState('user.address.city')"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <b-form-group
                id="fieldset-birthday"
                label="Birthday *"
                label-for="input-birthday"
                :state="validateState('user.birthday')"
                :invalid-feedback="validationMsg.birthday"
              >
                <datepicker
                  id="input-birthday"
                  v-model="user.birthday"
                  name="user.birthday"
                  placeholder="Birthday"
                  format="MM/dd/yyyy"
                  bootstrap-styling
                  :state="validateState('user.birthday')"
                  aria-describedby="birthday-live-feedback"
                ></datepicker>
              </b-form-group>
            </div>
            <div class="col-md-6 col-sm-12">
              <b-form-group
                id="fieldset-email"
                label="Email *"
                label-for="input-email"
                :state="validateState('user.email')"
                :invalid-feedback="validationMsg.email"
              >
                <b-form-input
                  id="input-email"
                  v-model="user.email"
                  type="email"
                  class="form-control"
                  name="user.email"
                  placeholder="Enter Email"
                  v-validate="{ required: true, email: true }"
                  :state="validateState('user.email')"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <b-form-group
                id="fieldset-github"
                label="Github Account"
                label-for="input-github"
                :state="validateState('user.github')"
                :invalid-feedback="validationMsg.github"
              >
                <b-form-input
                  id="input-github"
                  v-model="user.github"
                  class="form-control"
                  name="user.github"
                  placeholder="Enter Github"
                  :state="validateState('user.github')"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-sm-12">
              <b-form-group
                id="fieldset-skypeId"
                label="Skype ID"
                label-for="input-skypeId"
                :state="validateState('user.skypeId')"
                :invalid-feedback="validationMsg.skypeId"
              >
                <b-form-input
                  id="input-skypeId"
                  v-model="user.skypeId"
                  class="form-control"
                  name="user.skypeId"
                  placeholder="Enter SkypeID"
                  :state="validateState('user.skypeId')"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <b-form-group
                id="fieldset-timezone"
                label="GMT *"
                label-for="input-timezone"
                :state="validateState('user.timezone')"
                :invalid-feedback="validationMsg.timezone"
              >
                <b-form-input
                  id="input-timezone"
                  v-model.number="user.timezone"
                  class="form-control"
                  name="user.timezone"
                  placeholder="Enter Timezone"
                  v-validate="{ required: true, regex: /^-?[0-9]$|^-?1[0-2]$/ }"
                  :state="validateState('user.timezone')"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-6 col-sm-12">
              <b-form-group
                id="fieldset-zohoUserId"
                label="Zoho User ID"
                label-for="input-zohoUserId"
                :state="validateState('user.zohoUserId')"
                :invalid-feedback="validationMsg.zohoUserId"
              >
                <b-form-input
                  id="input-zohoUserId"
                  v-model="user.zohoUserId"
                  class="form-control"
                  name="user.zohoUserId"
                  placeholder="Enter Zoho User ID"
                  v-validate="{ required: true, regex: /^[0-9]{1,11}$/}"
                  :state="validateState('user.zohoUserId')"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>          
          <div class="row">
              <div class="col-md-6 col-sm-12">
                  <b-form-group
                          id="fieldset-role"
                          label="Role *"
                          label-for="input-role"
                          :invalid-feedback="validationMsg.country"
                          :state="validateState('user.address.country')"
                  >
                      <b-form-select
                              v-model="selected"
                              id="input-role"
                              class="form-control"
                              name="user.role"
                              v-validate="{ required: true }"
                              :options="options"
                              :state="validateState('')"
                      >
                      </b-form-select>
                  </b-form-group>
              </div>
            <div class="col-md-6 col-sm-12">
              <b-form-group
                      v-if="isCoder"
                      id="fieldset-currency"
                      label="Currency"
                      label-for="check-currency"
              >
                <div class="form-check form-check-inline">
                  <input
                          class="form-check-input"
                          id="check-currency-eur"
                          type="radio"
                          name="currency"
                          value="EUR"
                          v-model="user.currency"
                  >
                  <label class="form-check-label" for="check-currency-eur">
                    <span class="euro"></span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                          class="form-check-input"
                          id="check-currency-usd"
                          type="radio"
                          name="currency"
                          value="USD"
                          v-model="user.currency"
                  >
                  <label class="form-check-label" for="check-currency-usd">
                    <span class="dollar"></span>
                  </label>
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <b-form-group
                id="fieldset-intra-communty-vat"
                :label="isRequired?'Intra-community vat *':'Intra-community vat'"
                label-for="input-intra-community-vat"
                :invalid-feedback="validationMsg.intraCommunityVat"
                :state="validateState('user.intraCommunityVat')"
              >
                <b-form-input
                  v-model="user.intraCommunityVat"
                  id="input-intra-community-vat"
                  v-validate="{ required: isRequired }"
                  class="form-control"
                  name="user.intraCommunityVat"
                  placeholder="Enter intra-community vat"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-sm-12">
              <b-form-group
                v-if="isCoder"
                id="fieldset-hourlyRate"
                label="Hourly Rate *"
                label-for="input-hourlyRate"
                :state="validateState('user.hourlyRate')"
                :invalid-feedback="validationMsg.hourlyRate"
              >
                <b-form-input
                  id="input-hourlyRate"
                  v-model.number="user.hourlyRate"
                  class="form-control"
                  name="user.hourlyRate"
                  v-validate="{ required: isCoder, decimal: true }"
                  placeholder="Enter Hourly Rate"
                  :state="validateState('user.hourlyRate')"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <div class="form-section row">
        <div class="col-md-4 col-sm-12"></div>
        <div class="col-md-8 col-sm-12">
          <h1 class="form-section-title">Skills *</h1>
          <b-form-group
            id="fieldset-skills"
            label-for="input-textarea"
            :state="validateState('user.skills')"
            :invalid-feedback="validationMsg.skills"
          >
            <b-form-textarea
                    placeholder="Enter skills"
                    class="form-control"
                    v-model="user.skills"
                    id="input-textarea"
                    name="user.skills"
                    v-validate="{ required: true }"
                    :state="validateState('user.skills')"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
      <div v-if="isCoder" class="form-section row">
        <div class="col-md-4 col-sm-12"></div>
        <div class="col-md-8 col-sm-12">
          <h1 class="form-section-title">Payment Method *</h1>
          <div class="row logos-payment">
            <div class="col-sm">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  id="pm-paypal"
                  type="radio"
                  name="user.paymentMethod"
                  v-model="user.paymentMethod"
                  checked
                  value="paypal"
                >
                <label class="form-check-label" for="pm-paypal">
                  <div class="label-logo">
                    <img alt="paypal" title="PayPal" height="60" src="/images/logos/paypal.png">
                  </div>
                </label>
              </div>
              <!--<b-form-group
                id="fieldset-account"
                label="Paypal Address *"
                label-for="input-account"
                :state="validateState('user.accountAddress')"
                :invalid-feedback="validationMsg.accountAddress"
                v-if="user.paymentMethod==='paypal'"
              >
                <b-form-input
                  id="input-rate"
                  v-model="user.accountAddress"
                  name="user.accountAddress"
                  class="form-control"
                  v-validate="{ required: true, email: true }"
                  :state="validateState('user.accountAddress')"
                  placeholder="PayPal ID"
                ></b-form-input>
              </b-form-group>-->
            </div>
            <div class="col-sm">
              <!-- <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  id="pm-payoneer"
                  type="radio"
                  name="user.paymentMethod"
                  v-model="user.paymentMethod"
                  value="payoneer"
                >
                <label class="form-check-label" for="pm-payoneer">
                  <div class="label-logo">
                    <img alt="payoneer" height="50" src="/images/logos/payoneer.png">
                  </div>
                </label>
              </div> -->
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  id="pm-transferwise"
                  type="radio"
                  name="user.paymentMethod"
                  v-model="user.paymentMethod"
                  value="transferwise"
                >
                <label class="form-check-label" for="pm-transferwise">
                  <div class="label-logo">
                    <img alt="transferwise" title="TransferWise" height="22" src="/images/logos/transferwise.png">
                  </div>
                </label>
              </div>
              <!--<div class="form-group" v-if="user.paymentMethod==='payoneer'">
                <input
                  class="form-control"
                  name="account"
                  v-model="user.accountAddress"
                  v-validate="'required|email'"
                  :class="{'input': true, 'is-danger': errors.has('user.accountAddress') }"
                  type="text"
                  placeholder="Payoneer ID"
                >
                <i v-show="errors.has('account')" class="fa fa-warning"></i>
                <span
                  v-show="errors.has('account')"
                  class="help is-danger"
                >{{ errors.first('account') }}</span>
              </div>-->
            </div>
            <div class="col-sm">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  id="pm-wire-transfer"
                  type="radio"
                  name="user.paymentMethod"
                  v-model="user.paymentMethod"
                  value="wire-transfer"
                >
                <label class="form-check-label" for="pm-wire-transfer">
                  <div class="label-logo">
                    <img alt="wire-transfer" title="Wire Transfer" height="70" src="/images/logos/wire-transfer.png">
                  </div>
                </label>
              </div>
            </div>
            <div class="col-sm">
              <div class="form-check form-check-inline">
                <input
                    class="form-check-input"
                    id="pm-western-union"
                    type="radio"
                    name="user.paymentMethod"
                    v-model="user.paymentMethod"
                    value="western-union"
                >
                <label class="form-check-label" for="pm-western-union">
                  <div class="label-logo">
                    <img alt="western-union" title="Western Union" height="25" src="/images/logos/western-union.png">
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--
      <div v-if="!editMode" class="form-section row">
        <div class="col-md-4 col-sm-12"></div>
        <div class="col-md-8 col-sm-12">
          <h1 class="form-section-title">Send email</h1>
          <input type="checkbox" v-model="sendEmail" :value="sendEmail" :id="'create-user-sendEmail'"/>
          <label :for="'create-user-sendEmail'">
            <span>I agree to send an email to the new user.</span>
          </label>
        </div>
      <div class="row" v-if="error">
        <b-alert show variant="danger">{{error}}</b-alert>
      </div>
      -->
      <div class="form-section row">
        <div class="col-md-4 col-sm-12"></div>
        <div class="col-md-8 col-sm-12">
          <h1 class="form-section-title">Sync with zoho</h1>
          <input type="checkbox" v-model="user.syncWithZoho" :value="user.syncWithZoho" :id="'sync-with-zoho'"/>
          <label :for="'sync-with-zoho'">
            <span>Sync with Zoho.</span>
          </label>
        </div>
      </div>
      <div v-if="isLoading" class="text-center offset-4 py-4">
        <b-spinner label="Spinning"></b-spinner>
      </div>
      <div class="form-section button-container">
        <div class="row justify-content-around">
          <div class="col-4"></div>
          <div class="col-sm-8 d-flex justify-content-around">
            <!--button
              class="icon ion-md-trash btn btn-secondary"
              v-if="user.uuid && viewer === 'admin'"
            >Delete Profile</button-->
            <button v-if="!isLoading" id="component" class="icon ion-md-create btn btn-primary">
              <span>Create Component</span>
            </button>
            <button v-if="!isLoading" id="save" class="icon ion-md-create btn btn-primary">
              <span v-if="!user || !user.uuid">Save Profile</span>
              <span v-if="user.uuid">Update Profile</span>
            </button>
          </div>
        </div>
      </div>
    </b-form>
    <b-modal ref="confirmation-modal" title="Confirm the modification" hide-footer>
      <div class="d-block text-center">
        This user has currently components and payments on hold. Are you sure you want to update the email ?
      </div>
      <b-button @click="closeModal()">Cancel</b-button>
      <b-button class="btn-danger" @click="save()">Save</b-button>
    </b-modal>
  </div>
</template>

<script>
  import moment from 'moment';
  import Datepicker from 'vuejs-datepicker';
  import {CODERS_COUNTRY_LIST} from '../../constants';
  import PictureInput from 'vue-picture-input';
  export default {
  name: "CreateCoder",
  props: {
    viewer: String,
    error: [String, Object],
    editMode: Boolean,
    user: {
      type: Object,
      default: function(){
        return {
          'lastName': '',
          'firstName': '',
          'avatar': '',
          'timezone': '',
          'email': '',
          'github': '',
          'skypeId': '',
          'birthday': '',
          'zohoUserId': '',
          'skills': '',
          'paymentMethod': 'paypal',
          'accountAddress': '',
          'currency':'USD',
          'intraCommunityVat':null,
          'hourlyRate': null,
          'syncWithZoho': false,
          'address': {
            'city': '',
            'country': '',
          },
          'roles': [{'id': 3,'label': 'coder'}],
        }
      }
    },
  },
  components: {
    Datepicker,
    PictureInput
  },
  data() {
    return {
       selected: this.user && this.user.roles && this.user.roles[0].label? this.user.roles[0].label:'coder',
       sendEmail: true,
        options: [
            { value: 'admin', text: 'Admin' },
            { value: 'payer', text: 'Payer' },
            { value: 'coder', text: 'Coder' },
            { value: 'project_manager', text: 'Project Manager' }],
      countries: CODERS_COUNTRY_LIST.map(country => country.name),
      image: null,
      lastMail: '',
      form: {
        firstName: this.validateField('firstName'),
        lastName: this.validateField('lastName'),
        avatar: this.validateField('avatar'),
        email: this.validateField('email'),
        github: this.validateField('github'),
        skypeId: this.validateField('skypeId'),
        timezone: this.validateField('timezone'),
        zohoUserId: this.validateField('zohoUserId'),
        birthday: this.user && this.user.birthday ? moment(this.user.birthday).format('MM/DD/YYYY') : '',
        skills: this.validateField('skills'),
        paymentMethod: this.user.paymentMethod,
        accountAddress: this.validateField('accountAddress'),
        hourlyRate: this.validateField('hourlyRate'),
        syncWithZoho: this.user.syncWithZoho,
        address: {
          country: this.user && this.user.address ? this.user.address.country : '',
          city: this.user && this.user.address ? this.user.address.city : ''
        }
      },
      validationMsg: {
        firstName: "Please input first name.",
        lastName: "Please input last name.",
        email: "Please input email.",
        github: "Please input github.",
        skypeId: "Please input skypeId.",
        timezone: "Timezone can be between -12 to 12.",
        zohoUserId: "Please input zoho user Id.",
        birthday: "Please select birthday",
        skills: "Please add skills.",
        country: "Please select your country.",
        city: "Please input city.",
        accountAddress: "Please input valid PayPal address.",
        paymentMethod: "Please select a payment method",
        intraCommunityVat: "Please input Intra-community vat.",
        hourlyRate: "Please only input numbers."
      },
    };
  },
    created() {
        this.lastMail = this.user.email;
    },
    computed:{
      isRequired(){
        if(this.user.currency==='EUR'){
          return true
        }
        return false
      },
      isCoder(){
        return this.selected === 'coder'
      },
      isLoading() {
        return this.$store.getters['coders/isLoading'];
      }
    },
  methods: {
    validateField(field) {
      return this.user && this.user['field'] ? this.user['field'] : '';
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.errors.has(ref);
      }
      return null;
    },
    onChange (image) {
      if (image) {
        this.image=this.$refs.pictureInput
      } else {
      }
    },
    attemptUpload(email) {
      if (this.image) {
          this.user.avatar = this.image.file.name;
          const file=this.image.image;
          const imageName=email+this.image.file.name;
          const imageType=this.image.file.type;
          const imageSize=this.image.file.size;
          this.$store.dispatch('coders/uploadFile', {
              file,
              imageName,
              imageSize,
              imageType,
              callback: () => {
              }
          });
    }
      },
    save(){
      this.$validator.validateAll().then(() => {
                if (!this.errors.any()) {

                  this.attemptUpload(this.user.email);

                  let coder  = {
                    'uuid': this.user.uuid,
                    'lastName': this.user.lastName,
                    'firstName': this.user.firstName,
                    'avatar': this.user.avatar,
                    'timezone': this.user.timezone,
                    'email': this.user.email,
                    'github': this.user.github,
                    'skypeId': this.user.skypeId,
                    'birthday': this.user.birthday,
                    'zohoUserId': this.user.zohoUserId,
                    'skills': this.user.skills,
                    'paymentMethod': this.user.paymentMethod,
                    'accountAddress': this.user.accountAddress,
                    'currency': this.user.currency,
                    'intraCommunityVat':this.user.intraCommunityVat?this.user.intraCommunityVat:null,
                    'hourlyRate': this.user.hourlyRate,
                    'syncWithZoho': this.user.syncWithZoho,
                    'address': {
                      'city': this.user.address.city,
                      'country': this.user.address.country,
                    },
                  };


                  const { selected: role, sendEmail, editMode } = this;
                  this.$store.dispatch('coders/saveCoder', {
                    coder,
                    role,
                    createAccount: !editMode,
                    sendEmail,
                    callback: (serverData) => {
                      if (!serverData) {
                        alert('Email address already used.')
                        return;
                      }
                      this.$router.push({
                        name: 'dashboardUsers'
                      });
                    }
                  });
                }
              });
    },
    displayConfirmationModal(){
      this.$refs['confirmation-modal'].show();
    },
    closeModal(){
      this.$refs['confirmation-modal'].hide();
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (evt.submitter.id === "save") {
        if (this.user.uuid && this.lastMail !== this.user.email) {
          this.$store.dispatch('components/checkComponents', this.user.uuid).then(
           res => {
             if(!res.data){
               this.save();
             }else{
               this.displayConfirmationModal();
             }
           });
        }else {
          this.save();
        }
      } else if (evt.submitter.id === "component") {
        this.$store.dispatch('components/createMonthlyComponent', this.user.zohoUserId).then(
            () => {
              this.$router.push({
                name: 'dashboardUsers'
              })
            }
        );
      }
    }
  }
};
</script>
<style>
.user-form {
  width: 100%;
}
</style>
