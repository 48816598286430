<template>
    <div class="coder-search d-flex align-items-center justify-content-between date-range-filter">
        <div class="form-group d-flex align-items-center border-bottom">
            <search-icon class="search-icon"></search-icon>
            <date-picker v-model="date" lang="en" range :format="'MM-DD-YYYY'">
            </date-picker>
        </div>
    </div>
</template>

<script>
  import {
    FilterIcon,
    SearchIcon
  } from 'vue-feather-icons';
  import DatePicker from 'vue2-datepicker';

  export default {
    name: 'DateFilter',
    components: {
      FilterIcon,
      SearchIcon,
      DatePicker
    },
    data() {
      return {
        date: [null, null],
      }
    },
    watch: {
      date(value) {
        this.$emit('search', value);
      }
    },
    methods: {},
  };
</script>
