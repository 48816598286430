<template>
  <div>
    <b-form @submit="onSavePayment">
      <div class="container justify-content-center">
        <div class="row" v-for="(payment, index) in payments" :key="index">
          <div class="col-md-10">
            <b-form-group id="fieldset-amount" :state="validateState(`payment_amount_${index}`)" :invalid-feedback="validationMsg.fixedPrice">
              <b-form-input :id="`payment_amount_${index}`" v-model.number="payments[index].amount" :name="`payment_amount_${index}`" class="form-control" v-validate="{ required: true, decimal: true }" :state="validateState(`payment_amount_${index}`)" placeholder="Enter Fixed Price"></b-form-input>
            </b-form-group>
          </div>
          <div class="mt-1 col-md-2">
            <trash-2-icon class="trash-icon" @click="onRemovePayment(index)"></trash-2-icon>
          </div>
        </div>
        <b-button variant="primary" class="float-right m-2 add-payment-button" @click.prevent="onAddPayment">
          <plus-icon></plus-icon>
        </b-button>
      </div>
    </b-form>
    <div slot="modal-footer" class="w-100">
      <b-button type="submit" variant="primary" size="sm" class="float-right m-2" @click="onSavePayment">
        Save
      </b-button>
      <b-button size="sm" class="float-right m-2" @click.prevent="onCancelPayment">
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
  import {
    Trash2Icon,
    PlusIcon,
  } from 'vue-feather-icons'
  
  export default {
    props: ['coder', 'isOpen'],
    components: {
      Trash2Icon,
      PlusIcon,
    },
    name: 'PaymentFixedModal',
    data: function() {
      return {
        payments: [{
          amount: 0, required:1
        }],
        validationMsg: {
          fixedPrice: 'Please only input numbers.',
        }
      }
    },
    methods: {
      onAddPayment() {
        this.payments = [...this.payments, {
          amount: 0, required:1
        }];
      },
      onSavePayment(evt) {
        evt.preventDefault();
        console.log(this.payments);
        this.$validator.validateAll()
          .then(() => {
            if (!this.errors.any()) {
              this.$emit('save', this.payments);
              this.payments = [{
                amount: 0,required: 1
              }];
            }
          });
      },
      onCancelPayment() {
        this.$emit('cancel');
      },
      onRemovePayment(index) {
        const _clone = [...this.payments];
        _clone.splice(index, 1);
        this.payments = _clone;
      },
      validateState(ref) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.errors.has(ref)
        }
        return null
      }
    }
  };
</script>