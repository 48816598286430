<template>
   <div class="user-table admin-dash-table">
       <coder-info :coder="coder" :count="count" :isOpen="isOpen" :userRole="currentRole" v-on:toggle="onToggle"></coder-info>
       <div class="coders-accordion-content" :class="{ active: isOpen }">
           <payer-payment :coder="coder" :payments="payerPayments" :coderToPay="coderToPay" :selectedPayments="selectedPayments" :isPayer="isPayer" v-on:updateTotalPayment="updateTotalPayment"></payer-payment>
       </div>
   </div>
</template>
<script>
import CoderInfo from '../../components/admin/CoderInfo';
import PayerPayment from '../../components/admin/PayerPayment';
export default {
    name:'PayerAccordion',
    props: ['coder', 'coderToPay', 'selectedPayments', 'isPayer'],
    components:{
        CoderInfo,
        PayerPayment
    },
    computed: {
        payerPayments() {
            return this.coder.payments.items;
        },
        count() {
            return this.coder.payments.items.length;
        }
    },
    data(){
        return{
            isOpen: false,
            currentRole: 'payer'
        }
    },
    methods: {
        onToggle(){
            this.isOpen = !this.isOpen
        },
        updateTotalPayment({coder2Pay, amount, isSum, isLocked, selectedPayments, currency, cityId }){
            this.$emit('updateTotalPayment', { coder2Pay: coder2Pay, amount: amount, isSum: isSum, isLocked: isLocked, selectedPayments: selectedPayments, currency: currency, cityId: cityId});
        }
    }
}
</script>