var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fluid-container footer dash-footer" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "footer-inside" }, [
        _c("div", { staticClass: "navbar-text" }, [
          _vm._v("\n                    Let's code together\n            "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "social-icons navbar-brand" }, [
          _c("ul", [
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.facebook.com/the.coding.machine/",
                    target: "_blank",
                    title: "Facebook",
                  },
                },
                [_c("facebook-icon")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://github.com/thecodingmachine",
                    target: "_blank",
                    title: "Github",
                  },
                },
                [_c("github-icon")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.instagram.com/inside_thecodingmachine/",
                    target: "_blank",
                    title: "Instagram",
                  },
                },
                [_c("instagram-icon")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.linkedin.com/company/the-coding-machine/",
                    target: "_blank",
                    title: "LinkedIn",
                  },
                },
                [_c("linkedin-icon")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://twitter.com/coding_machine",
                    target: "_blank",
                    title: "Twitter",
                  },
                },
                [_c("twitter-icon")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.youtube.com/channel/UCw6p2FZ2q2Fj1iGFcIQ_73w",
                    target: "_blank",
                    title: "YouTube",
                  },
                },
                [_c("youtube-icon")],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }