<template>
  <div class="auth-wrap">
    <div class="auth-content center">
      <div class="auth-container login">
        <div class="auth-logo-wrap">
          <img src="/images/logo.png">
        </div>
        <div class="auth-form">
          <template v-if="!status.confirmed">
          
          <b-form @submit.prevent="performConfirmation" @input="resetStatus">
            <!-- error -->
            <b-alert :show="status.error" :variant="status.error ? 'danger' : 'success' ">{{status.message ? status.message : 'error'}}</b-alert>

            <!-- email input -->
            <b-form-group
              id="fieldset-email"
              label="Enter your email address"
              label-for="input-email"
              :invalid-feedback="validationMsg.email"
              :state="validateState('email')"
            >
              <b-form-input
                id="input-email"
                v-model="email"
                class="form-control"
                name="email"
                placeholder="Email"
                type="email"
                :disabled="status.loading"
              ></b-form-input>
            </b-form-group>

            <!-- loading spinner -->
            <div class="form-group auth-item my-4">
              <div v-if="status.loading" class="text-center py-4">
                <b-spinner label="Spinning"></b-spinner>
              </div>
              <b-button
                type="submit"
                variant="primary"
                class="btn-block py-2"
                :disabled="status.loading"
              >Confirm Email</b-button>
            </div>
          </b-form>
          </template>
          <template v-else>
            <div class="py-3 text-center">
              <h4 class="font-accent">Email sent</h4>
              <p>Check your email for further instructions on resetting your password.</p>
            </div>
          </template>
        </div>
      </div>
    </div>
    <dash-footer></dash-footer>
  </div>
</template>
<script>
import SecurityAPI from '../../../api/security';
import DashFooter from "../../../components/Footer";
export default {
  name:"ConfirmEmail",
  components: {DashFooter},
  data(){
    return{
      email:null,
      validationMsg:{
        email:"You need to input email"
      },
      status:{
        success:false,
        error:false,
        message:null,
        loading:false,
        confirmed:false,
      }
      
    }
  },
  created(){
    if(this.$store.getters['security/isAuthenticated']){
      this.$router.push({path: '/dashboard'});
    }
  },
  methods:{
    validateState(param){
      return true
    },
    resetStatus(){
      this.status.error = this.status.success = false
    },
    performConfirmation(){

      if(this.email == null || this.email == ''){
        return false
      }
      

      this.status.loading = true

      SecurityAPI.confirmEmail(this.email)
      .then(function(response){
        this.status.loading = false
        var success = !!response.data.status
        this.status.confirmed = this.status.success = success
        
      }.bind(this))
      .catch(function(err){
        console.error(err)
        this.status.loading = false
        this.status.error = err
      }.bind(this))
    }
  }
}
</script>

