var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-5 d-flex py-3" }, [
    _c("div", [
      _c("h2", { staticClass: "dash-header-title" }, [
        _vm._v(_vm._s(_vm.title ? _vm.title : _vm.defaultTile) + " "),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ml-auto d-flex justify-content-between btn-list" },
      [_vm._t("actions")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }