import _ from 'lodash';
import SecurityAPI from '../api/security';
import { apolloClient } from '../graphql';
import confirmTokenMutation from '../graphql/coder/mutation/confirm_token.gql';
import confirmTermsMutation from '../graphql/coder/mutation/confirm_terms.gql';
import getMailFromToken from "../graphql/coder/query/email_from_token.gql";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    isAuthenticated: false,
    isAgreed: null,
    id: null,
    firstName: null,
    lastName: null,
    avatar: null,
    address: null,
    roles: [],
    rights: [],
    token: null,
    skills: null,
    timezone: 0,
    accountAddress:null,
    tokenVerified: false,
    loginTerm: false,
    hourlyRate: 0,
    version: "",
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    isAgreed(state) {
      return state.isAgreed;
    },
    id(state) {
      return state.id;
    },
    lastName(state) {
      return state.lastName;
    },
    firstName(state) {
      return state.firstName;
    },
    avatar(state) {
      return state.avatar;
    },
    address(state) {
      return state.address;
    },
    skills(state) {
      return state.skills;
    },
    timezone(state) {
      return state.timezone;
    },
    accountAddress(state) {
      return state.accountAddress;
    },
    getRole(state) {
      return state.roles[0];
    },
    hasRole(state) {
      return role => {
        return state.roles.indexOf(role) !== -1;
      };
    },
    getRights(state) {
      return Object.values(state.rights || {});
    },
    hasRights(state) {
      return (right, index) => {
        if (_.isNil(state.rights[index])) {
          return false;
        }
        return state.rights[index].indexOf(right) !== -1;
      };
    },
    token(state) {
      return state.token;
    },
    tokenVerified(state) {
      return state.tokenVerified;
    },
    loginTerm(state) {
      return state.loginTerm;
    },
    hourlyRate(state) {
      return state.hourlyRate;
    },
    version(state) {
      return state.version;
    }
  },
  mutations: {
    ['AUTHENTICATING'](state) {
      state.isLoading = true;
      state.error = null;
      state.isAuthenticated = false;
      state.isAgreed = false;
      state.id = null;
      state.lastName = null;
      state.firstName = null;
      state.avatar = null;
      state.skills = null;
      state.timezone = 0;
      state.roles = [];
      state.rights = [];
      state.hourlyRate = 0;
    },
    ['AUTHENTICATING_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.isAuthenticated = true;
      state.isAgreed = payload.agreed ? true : false;
      state.id = payload.id;
      state.lastName = payload.lastName;
      state.firstName = payload.firstName;
      state.avatar = payload.avatar;
      state.roles = payload.roles;
      state.rights = payload.rights;
      state.token = payload.token;
      state.address = payload.address;
      state.skills = payload.skills;
      state.timezone = payload.timezone;
      state.accountAddress = payload.accountAddress;
      state.tokenVerified = true;
      state.loginTerm = true;
      state.hourlyRate = payload.hourlyRate;
      state.version = payload.version;
    },
    ['AUTHENTICATING_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
      state.isAuthenticated = false;
      state.isAgreed = false;
      state.id = null;
      state.lastName = null;
      state.firstName = null;
      state.avatar = null;
      state.skills = null;
      state.timezone = 0;
      state.roles = [];
      state.rights = [];
      state.hourlyRate = 0;
    },
    ['LOGOUT'](state) {
      state.error = null;
      state.isAuthenticated = false;
      state.isAgreed = false;
      state.id = null;
      state.lastName = null;
      state.firstName = null;
      state.avatar = null;
      state.address = null;
      state.skills = null;
      state.timezone = 0;
      state.loginTerm = false;
      state.tokenVerified = false;
      state.token = null;
      state.roles = [];
      state.rights = [];
      state.hourlyRate = 0;
    },
    ['CONFIRM_TOKEN_REQUEST'](state, token) {
      state.token = token;
      state.tokenVerified = false;
      state.isLoading = true;
      state.error = null;
      state.loginTerm = false;
    },
    ['CONFIRM_TERMS_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['CONFIRM_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.isAuthenticated = true;
      state.isAgreed = payload.agreed;
      state.id = payload.uuid;
      state.lastName = payload.lastName;
      state.firstName = payload.firstName;
      state.address = payload.address;
      state.skills = payload.skills;
      state.timezone = payload.timezone;
      state.accountAddress = payload.accountAddress;
      state.tokenVerified = true;
    },
    ['CONFIRM_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
      state.isAuthenticated = false;
      state.isAgreed = false;
      state.id = null;
      state.lastName = null;
      state.firstName = null;
      state.skills = null;
      state.timezone = 0;
      state.token = null;
      state.tokenVerified = false;
    },
    ['DELETE_TOKEN'](state) {
      state.token = null;
    },
    ['PROVIDING_DATA_ON_REFRESH_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.isAuthenticated = payload.isAuthenticated;
      state.id = payload.id;
      state.lastName = payload.lastName;
      state.firstName = payload.firstName;
      state.avatar = payload.avatar;
      state.skills = payload.skills;
      state.timezone = payload.timezone;
      state.accountAddress = payload.accountAddress;
      state.roles = payload.roles;
      state.rights = payload.rights;
    },
    ['GET_CODER_EMAIL_SUCCESS'](state, payload) {
      state.accountAddress = payload.accountAddress;
    },
    ['GET_CODER_EMAIL_REQUEST'](state) {
      state.accountAddress = null;
    },
    ['GET_CODER_EMAIL_ERROR'](state) {
      state.accountAddress = null;
    }
  },
  actions: {
    login({ commit }, payload) {
      commit('AUTHENTICATING');
      return SecurityAPI.login(payload.login, payload.password)
        .then(res => commit('AUTHENTICATING_SUCCESS', res.data))
        .catch(err => {
          commit('AUTHENTICATING_ERROR', err.response ? err.response.data.error : 'Unknown Error')
        });
    },
    logout({ dispatch, commit }) {
      commit('LOGOUT');
      document.cookie =
        'PHPSESSID=;Path=/cv;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      dispatch('coders/clearCoders', {}, {
        root: true,
      });
      dispatch('components/clearComponents', {}, {
        root: true,
      });
      dispatch('payments/clearPayments', {}, {
        root: true,
      });
      dispatch('metrics/clearMetrics', {}, {
        root: true,
      });
      return SecurityAPI.logout();
    },
    resetPassword({ commit, state }, payload) {
      commit('AUTHENTICATING');
      const { password, callback } = payload;
      return SecurityAPI.reset(state.token, password)
        .then(res => {
          commit('AUTHENTICATING_SUCCESS', res.data);
          commit('DELETE_TOKEN');
          callback();
        })
        .catch(err => commit('AUTHENTICATING_ERROR', err.response ? err.response.data.error : 'Unknown Error'));
    },
    confirmCoderToken({ commit }, { token, callback }) {
      commit('CONFIRM_TOKEN_REQUEST', token);
      return apolloClient
        .mutate({
          mutation: confirmTokenMutation,
          variables: {
            token
          }
        })
        .then(res => {
          if (res.data.confirmCoderToken) {
            const payload = res.data.confirmCoderToken;
            payload.agreed = false;
            commit('CONFIRM_SUCCESS', res.data.confirmCoderToken);
            callback(res);
          } else {
            commit('CONFIRM_ERROR', 'Token not valid');
          }
        })
        .catch(err => commit('CONFIRM_ERROR', err.response ? err.response.data.error : 'Unknown Error'));
    },
    confirmTermsAndConditions({ commit }, {coder, callback}) {
      commit('CONFIRM_TERMS_REQUEST');
      return apolloClient
        .mutate({
          mutation: confirmTermsMutation,
          variables: coder
        })
        .then(res => {
          const payload = res.data.confirmTermsAndConditions;
          payload.agreed = true;
          commit('CONFIRM_SUCCESS', payload);
          callback();
        })
        .catch(err => commit('CONFIRM_ERROR', err.response ? err.response.data.error : 'Unknown Error'));
    },
    onRefresh({ commit }, payload) {
      commit('PROVIDING_DATA_ON_REFRESH_SUCCESS', payload);
    },
    getEmailFromToken({ commit, state }) {
      commit('GET_CODER_EMAIL_REQUEST');
      return apolloClient
          .query({
            query: getMailFromToken,
            variables:{
              token: state.token
            }
          })
          .then(res => commit('GET_CODER_EMAIL_SUCCESS', res.data.items))
          .catch(err => commit('GET_CODER_EMAIL_ERROR', err));
    },
  }
};
