var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auth-wrap" }, [
    _c("div", { staticClass: "auth-content center" }, [
      _c("div", { staticClass: "auth-container reset" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "auth-form" },
          [
            _c(
              "b-form",
              { on: { submit: _vm.onSubmit } },
              [
                _c("h2", { staticClass: "mb-3 text-center" }, [
                  _vm._v("Create/Reset Password"),
                ]),
                _vm._v(" "),
                _vm.hasError
                  ? _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
                      _vm._v(_vm._s(_vm.error)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "fieldset-password",
                      label: "Password",
                      "label-for": "input-password",
                      "invalid-feedback": _vm.validationMsg.password,
                      state: _vm.validateState("password"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: { required: true },
                          expression: "{ required: true }",
                        },
                      ],
                      ref: "password",
                      attrs: {
                        id: "input-password",
                        type: "password",
                        placeholder: "Enter password",
                        name: "password",
                        state: _vm.validateState("password"),
                        disabled: _vm.isLoading,
                      },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "fieldset-password-repeat",
                      label: "Repeat Password",
                      "label-for": "input-password-repeat",
                      "invalid-feedback": _vm.validationMsg.confirmPassword,
                      state: _vm.validateState("password-repeat"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: { required: true, confirmed: _vm.password },
                          expression: "{ required: true, confirmed: password }",
                        },
                      ],
                      attrs: {
                        id: "input-password-repeat",
                        type: "password",
                        placeholder: "Enter password again",
                        name: "password-repeat",
                        state: _vm.validateState("password-repeat"),
                        disabled: _vm.isLoading,
                      },
                      model: {
                        value: _vm.confirmPassword,
                        callback: function ($$v) {
                          _vm.confirmPassword = $$v
                        },
                        expression: "confirmPassword",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group auth-item my-4" },
                  [
                    _vm.isLoading
                      ? _c(
                          "div",
                          { staticClass: "text-center py-4" },
                          [_c("b-spinner", { attrs: { label: "Spinning" } })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-block py-2",
                        attrs: {
                          type: "submit",
                          variant: "primary",
                          disabled: _vm.isLoading,
                        },
                      },
                      [_vm._v("Validate")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "auth-logo-wrap" }, [
      _c("img", { attrs: { src: "/images/logo.png" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }