var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "admin-menu d-flex flex-column" }, [
    _c("div", { staticClass: "nav mt-5" }, [
      _c("ul", {}, [
        _c(
          "li",
          { staticClass: "active" },
          [
            _c(
              "router-link",
              {
                staticClass: "d-block text-center",
                attrs: { to: { name: "admin" } },
              },
              [
                _c("activity-icon", {
                  staticClass: "d-inline-block menu-icon",
                }),
                _vm._v(" "),
                _c("span", { staticClass: "d-block" }, [_vm._v("Dashboard")]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isAdmin || _vm.isManager
          ? _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "d-block text-center",
                    attrs: { to: { name: "dashboardUsers" } },
                  },
                  [
                    _c("users-icon", {
                      staticClass: "d-inline-block menu-icon",
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-block" }, [_vm._v("Users")]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isAdmin
          ? _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "d-block text-center",
                    attrs: { to: { name: "payment" } },
                  },
                  [
                    _c("payer-icon", {
                      staticClass: "d-inline-block menu-icon",
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-block" }, [
                      _vm._v("Payer Mode"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isAdmin || _vm.isManager
          ? _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "d-block text-center",
                    attrs: { to: { name: "dashboardComponents" } },
                  },
                  [
                    _c("box-icon", { staticClass: "d-inline-block menu-icon" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-block" }, [
                      _vm._v("Components"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "nav nav-auto" }, [
      !_vm.isManager
        ? _c("ul", { staticClass: "social-icons-side" }, [
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.facebook.com/the.coding.machine/",
                    target: "_blank",
                    title: "Facebook",
                  },
                },
                [_c("facebook-icon")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://github.com/thecodingmachine",
                    target: "_blank",
                    title: "Github",
                  },
                },
                [_c("github-icon")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.instagram.com/inside_thecodingmachine/",
                    target: "_blank",
                    title: "Instagram",
                  },
                },
                [_c("instagram-icon")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.linkedin.com/company/the-coding-machine/",
                    target: "_blank",
                    title: "LinkedIn",
                  },
                },
                [_c("linkedin-icon")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://twitter.com/coding_machine",
                    target: "_blank",
                    title: "Twitter",
                  },
                },
                [_c("twitter-icon")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.youtube.com/channel/UCw6p2FZ2q2Fj1iGFcIQ_73w",
                    target: "_blank",
                    title: "YouTube",
                  },
                },
                [_c("youtube-icon")],
                1
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.version)),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }