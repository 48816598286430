var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "coder-wrapper",
    { attrs: { name: "Coder" } },
    [
      _c(
        "dashboard-header",
        { attrs: { title: "Invoice" } },
        [
          _c("template", { slot: "actions" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary px-3",
                on: {
                  click: function ($event) {
                    return _vm.downloadInvoices()
                  },
                },
              },
              [
                _c("arrow-down-icon"),
                _vm._v("\n                Download invoices\n            "),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("invoice"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }