var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "row d-flex justify-content-around m-0",
        staticStyle: { width: "100%" },
      },
      [
        _c("coders-filter", {
          staticClass: "col-6",
          on: { search: _vm.onSearchCoder },
        }),
        _vm._v(" "),
        _c("city-filter", {
          staticClass: "col-6",
          on: { search: _vm.onSearchCity },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "admin-table mt-4 mb-5" },
      [
        _c("ApolloQuery", {
          attrs: {
            query: require("../../../graphql/coder/query/payerCoders.gql"),
            variables: { coder: _vm.coderFilter, city: _vm.cityFilter },
            options: { notifyOnNetworkStatusChange: true },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ result: { loading, error, data } }) {
                return [
                  loading
                    ? _c(
                        "div",
                        { staticClass: "text-center py-4" },
                        [_c("b-spinner", { attrs: { label: "Spinning" } })],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  error ? _c("div", [_vm._v("An error occured")]) : _vm._e(),
                  _vm._v(" "),
                  !loading
                    ? _c(
                        "div",
                        { staticClass: "admin-accordions" },
                        [
                          _vm._l(
                            data.payerCoders.items,
                            function (coder, index) {
                              return coder.payments.items.length
                                ? _c("payer-accordion", {
                                    key: _vm.componentKey + "-" + index,
                                    attrs: {
                                      coder: coder,
                                      coderToPay: _vm.coderToPay,
                                      selectedPayments: _vm.selectedPayments,
                                      isPayer: _vm.isPayer,
                                    },
                                    on: {
                                      updateTotalPayment:
                                        _vm.updateTotalPayment,
                                    },
                                  })
                                : _vm._e()
                            }
                          ),
                          _vm._v(" "),
                          !data.payerCoders.items.length
                            ? _c(
                                "div",
                                { staticClass: "user-table admin-dash-table" },
                                [_c("h6", [_vm._v("No Requested Payments")])]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !loading && _vm.isPayer
                    ? _c(
                        "nav",
                        {
                          key: _vm.componentKey,
                          staticClass:
                            "navbar fixed-bottom-custom navbar-expand-sm payer-footer-bg p-5 d-flex row",
                        },
                        [
                          _c("div", { staticClass: "col-md-2" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6 row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-12 justify-content-center",
                              },
                              [
                                _c("h5", { staticClass: "font-accent" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.coderToPay.uuid &&
                                        _vm.coderToPay.uuid !== ""
                                        ? "Paying out : " +
                                            _vm.coderToPay.firstName +
                                            " " +
                                            _vm.coderToPay.lastName
                                        : "Check components for payment"
                                    )
                                  ),
                                ]),
                                _c("hr"),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-6 justify-content-end align-self-end",
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    "TOTAL AMOUNT (" +
                                      _vm._s(
                                        _vm._f("symbol")(_vm.currentCurrency)
                                      ) +
                                      ") "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.coderToPay.uuid,
                                      expression: "coderToPay.uuid",
                                    },
                                  ],
                                  attrs: { type: "hidden", useriD_total: "" },
                                  domProps: { value: _vm.coderToPay.uuid },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.coderToPay,
                                        "uuid",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.totalPayment,
                                      expression: "totalPayment",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    name: "usd_total",
                                    id: "usd_total",
                                    readonly: "",
                                  },
                                  domProps: { value: _vm.totalPayment },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.totalPayment = $event.target.value
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.currentCurrency === "USD"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-6 justify-content-end align-self-end",
                                  },
                                  [
                                    _c("label", [_vm._v("TOTAL AMOUNT (€)")]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.totalPaymentInEuro,
                                          expression: "totalPaymentInEuro",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        name: "euro_total",
                                        id: "euro_total",
                                        value: "0",
                                      },
                                      domProps: {
                                        value: _vm.totalPaymentInEuro,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.totalPaymentInEuro =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-2 justify-content-end align-self-end",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { disabled: _vm.isActive || loading },
                                  on: { click: _vm.payAll },
                                },
                                [_vm._v("$ Pay")]
                              ),
                              _vm._v(" "),
                              _vm.isLocked
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-warning",
                                      attrs: {
                                        disabled:
                                          _vm.totalPayment === 0 || loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.lock(false)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-unlock" }),
                                      _vm._v(" Unlock"),
                                    ]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-warning",
                                      attrs: {
                                        disabled:
                                          _vm.totalPayment === 0 || loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.lock(true)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-lock" }),
                                      _vm._v(" Lock"),
                                    ]
                                  ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-2" }),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }