<template>
    <div class="">
      <div class="d-flex  align-items-center page-title mb-3">
        <div>
          <button class="btn btn-secondary btn-back" @click.prevent="back">
            <arrow-left-icon class="custom-class"></arrow-left-icon>Back
          </button>
        </div>
        <div class="ml-4">
          <h2 v-if="canEdit()" class="dash-header-title m-0">Edit Component</h2>
          <h2 v-else class="dash-header-title m-0">View Component</h2>
        </div>
      </div>
      <div class="border-bottom my-3"></div>
      <component-form class="mt-5" mode="edit" v-on:save="onSave" :isLoading="isLoading" :component="component" v-if="component"
                      :error="error"></component-form>
    </div>
</template>

<script>
  import {
    ArrowLeftIcon
  } from 'vue-feather-icons';
  import ComponentForm from '../../../components/admin/ComponentForm';
  import DashHeader from '../../../components/Header';

  export default {
    name: "EditComponent",
    components: {
      ArrowLeftIcon,
      ComponentForm,
      DashHeader,
    },
    data() {
      return {
        component: null,
      }
    },
    async mounted () {
      if (!this.$route.params.uuid) {
        await this.$router.push({
          path: '/dashboard'
        });
      } else {
        await this.$store.dispatch('components/fetchComponentById', this.$route.params.uuid);
        this.component = this.$store.getters['components/component'];
      }
    },
    computed: {
      isLoading() {
        return this.$store.getters['components/isLoading'];
      },
      hasError() {
        return this.$store.getters['components/hasError'];
      },
      error() {
        return this.$store.getters['components/error'];
      },
    },
    methods: {
      onSave: function ({
                          component,
                          payments
                        }) {
        const uuid = this.component['uuid'];
        const _component = Object.assign({}, component, {uuid: uuid});

        this.$store.dispatch('components/saveComponent', {
          component: _component,
          callback: (component) => {
            const _payments = payments.map(payment => {
              return {
                componentId: component.uuid,
                amount: payment.amount,
                status: payment.status,
                uuid: typeof payment.uuid !== "undefined" ? payment.uuid : null
              }
            });
            this.$store.dispatch('payments/saveBulkPayment', {
              userId: component.coder.uuid,
              componentId: component.uuid,
              payments: _payments,
              callback: () => {
                this.$router.push({
                  path: '/dashboard'
                });
              }
            })
          }
        });
      },
      back() {
        this.$store.dispatch('components/resetStore');
        setTimeout(()=>{this.$router.go(-1);}, 1000);        
      },
      canEdit: function() {
        const isAdmin = this.$store.getters['security/hasRole']('admin');
        if (isAdmin === true) {
          return true
        }
        const isManager = this.$store.getters['security/hasRole']('project_manager');
        const userId = this.$store.getters['security/id'];

        const managerId = this.component && this.component.manager && this.component.manager.uuid;

        if (isManager && managerId && managerId === userId) {
          return true;
        }
        return false;
      },
      getMode() {
        if (this.canEdit()) {
          return "edit"
        }
        return "coder"
      },
    }
  };
</script>