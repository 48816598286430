<template>
	<div class="user-table admin-dash-table">
		<coder-info :coder="coder" :isOpen="isOpen" :userRole="userRole" v-on:toggle="onToggle"></coder-info>
		<div class="coders-accordion-content" :class="{ active: isOpen }" >
			<coder-component :coder="coder" :userRole="userRole" :components="getFilteredComponent(coder.components.items)" v-on:view="onViewComponent"></coder-component>
		</div>
		<component-modal :selectedComponent="selectedComponent" ></component-modal>
	</div>
</template>

<script>
	import CoderInfo from '../../components/admin/CoderInfo';
	import CoderComponent from '../../components/admin/CoderComponent';
	import ViewComponentModal from './ViewComponentModal';
	import ComponentModal from '../../components/admin/ComponentModal'

	import { Edit2Icon, XIcon, LayersIcon, TagIcon, InboxIcon } from 'vue-feather-icons'
	
	export default {
		name: 'CodersAccordion',
		components: {
			CoderInfo,
			CoderComponent,
			ViewComponentModal,
			ComponentModal,
			Edit2Icon,
			XIcon,
			LayersIcon,
			TagIcon,
			InboxIcon,
		},
		props: ['coder'],
		data: function() {
			return {
				isOpen: false,
				selectedComponent:{},
			}
		},

		methods: {
			onToggle: function() {
				this.isOpen = !this.isOpen
			},

			onViewComponent(component) {
				this.selectedComponent = this.coder.components.items[component];
			},
      getFilteredComponent(components) {
        return components.filter(c => {
            return c.status !== "Archived" && c.status !== "Declined" && c.status !== "Deleted" && c.status !== "Finished";
        })
      }
		},
		created() { },
		computed: {
			userRole: function(){
				let role = '';
				if(this.$store.getters['security/hasRole']('admin')){role = 'admin';}
				else if(this.$store.getters['security/hasRole']('project_manager')){role='manager';}
				return role;
			}
		},
		mounted() {},
	};
</script>
