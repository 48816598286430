<template>
  <div class="container">
    <div class="md-title">
      <div class="d-flex align-items-center">
        <div class="ml-auto d-flex align-items-center justify-content-center">
          <div class="p-2">
            <edit-2-icon></edit-2-icon>
          </div>
        </div>
      </div>
      <div>
        <span class="d-flex my-2">{{dateParse(component.endDate)}}</span>
        <span class="d-flex my-2 font-italic">{{component.project ? component.project.name: 'Project Name'}}</span>
      </div>
  
      <div class="d-flex component-badges my-2">
        <!-- project languages -->
        <!-- <span class="badge badge-pill badge-primary">PHP</span> -->
      </div>
      <div class="my-2">
        <p>{{component.project ? component.project.description : 'Description'}}</p>
      </div>
    </div>
    <div class="d-flex">
      <div>
        <span>
          <tag-icon v-if="component.type && component.type.toLowerCase() === STATUS_CODERS_FIXED_PRICE" class="p-1"></tag-icon>
					<inbox-icon v-else class="p-1"></inbox-icon>
					{{component.type}}
				</span>
      </div>
      <div class="ml-auto">
        <h1 class="font-accent">${{component.dayRate || component.fixedPrice}}</h1>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    TagIcon, InboxIcon, Edit2Icon, XIcon
  } from 'vue-feather-icons';
  import {
    CREATE_COMPONENT,
    ASSIGN_COMPONENT,
    CODERS_DELEGATION_SKILLS,
    CODERS_FIXED_PRICE
  } from '../../constants';
  
  export default {
    props: ['component'],
    components: {
      TagIcon,
      InboxIcon,
      Edit2Icon,
      XIcon
    },
    computed: {
      STATUS_CODERS_DELEGATION_SKILLS(){
        return CODERS_DELEGATION_SKILLS;
      },
      STATUS_CODERS_FIXED_PRICE(){
        return CODERS_FIXED_PRICE;
      },
    },
    name: 'ViewComponentModal',
    data: function() {
      return {}
    },
    methods: {
      dateParse(dateStr){
				var dateObj = new Date(dateStr)
				return [dateObj.getDate(), (dateObj.getMonth()+1), dateObj.getFullYear()].join('/')

			},
    }
  };
</script>