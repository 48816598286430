var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-5 dash-header container" }, [
    _c("div", { staticClass: "navbar d-flex justify-content-space" }, [
      _c("div", { staticClass: "navbar-brand" }, [
        _c(
          "a",
          {
            attrs: { href: "/" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goHome.apply(null, arguments)
              },
            },
          },
          [
            _c("img", {
              staticClass: "brand-logo",
              attrs: { src: "/images/logo.png", alt: "logo" },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ml-auto", attrs: { title: "Logout" } },
        [
          _c("power-icon", {
            staticClass: "power-icon",
            on: { click: _vm.logout },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }