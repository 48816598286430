<template>
  <div class="auth-wrap">
    <div class="auth-content center">
      <div class="auth-container login">
        <div class="auth-logo-wrap">
          <img src="/images/logo.png" />
        </div>
        <div class="auth-form">
          <b-form @submit="performLogin">
            <b-alert show variant="danger" v-if="hasError">{{error}}</b-alert>
            <b-form-group id="fieldset-username" label="E-mail" label-for="input-username" :invalid-feedback="validationMsg.username" :state="validateState('username')">
              <b-form-input id="input-username" v-model="credentials.login" name="username" placeholder="Enter e-mail" v-validate="{ required: true }" :state="validateState('username')" :disabled="isLoading"></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-password" label="Password" label-for="input-password" :invalid-feedback="validationMsg.password" :state="validateState('password')">
              <b-form-input id="input-password" type="password" v-model="credentials.password" placeholder="Enter password" name="password" v-validate="{ required: true }" :state="validateState('password')" :disabled="isLoading"></b-form-input>
            </b-form-group>
            <div class="form-group auth-item my-4">
              <div v-if="isLoading" class="text-center py-4">
                <b-spinner label="Spinning"></b-spinner>
              </div>
              <b-button v-if="!isLoading" type="submit" variant="primary" class="btn-block btn-connect py-2" :disabled="isLoading">Connect</b-button>
              <!-- <div class="text-center or">
                  <small>or</small>
              </div>
              <b-button @click="performOauth()" type="submit" variant="primary" class="btn-block btn-social py-2" :disabled="isLoading">
                <img src="/svg/general/google-24.svg">
                Sign In with Google</b-button> -->
            </div>
            <div class="auth-item">
              <router-link class="help-link font-bold" :to="{name:'confirmEmail'}">
                <u>Reset Password</u>
              </router-link>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DashFooter from "../../../components/Footer";
  export default {
    name: 'connection',
    components: {DashFooter},
    data() {
      return {
        credentials: {
          login: '',
          password: ''
        },
        validationMsg: {
          username: 'Please input username',
          password: 'Please input password'
        }
      };
    },
    created() {
      let redirect = this.$route.query.redirect;
      if (this.$store.getters['security/isAuthenticated']) {
        if (typeof redirect !== 'undefined') {
          this.$router.push({
            path: redirect
          });
        } else {
          this.$router.push({
            path: '/dashboard'
          });
        }
      }
    },
    computed: {
      isLoading() {
        return this.$store.getters['security/isLoading'];
      },
      hasError() {
        return this.$store.getters['security/hasError'];
      },
      error() {
        return this.$store.getters['security/error'];
      },
    },
    methods: {
      performLogin(evt) {
        evt.preventDefault();
        this.$validator.validateAll()
          .then(() => {
            if (!this.errors.any()) {
              let payload = {
                  login: this.$data.credentials.login,
                  password: this.$data.credentials.password
                },
                redirect = this.$route.query.redirect;
        
              this.$store.dispatch('security/login', payload)
                .then(() => {
                  if (!this.$store.getters['security/hasError']) {
                    if (typeof redirect !== 'undefined') {
                      this.$router.push({
                        path: redirect
                      });
                    } else {
                      if (this.$store.getters['security/hasRole']('admin') ||
                        this.$store.getters['security/hasRole']('project_manager')) {
                        this.$router.push({
                          path: '/dashboard'
                        });
                      } else if (this.$store.getters['security/hasRole']('payer')) {
                        this.$router.push({
                          path: '/payer'
                        });
                      } else if (this.$store.getters['security/hasRole']('coder')) {
                        this.$router.push({
                          path: '/coder'
                        });
                      } else {
                        this.$router.push({
                          path: '/connection'
                        });
                      }
                    }
                  }
                });
            }
          })
      },
      // performOauth() {
      //   this.$login();
      // },
      validateState(ref) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.errors.has(ref);
        }
        return null;
      }
    }
  }
</script>
