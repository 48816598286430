import axios from 'axios';

export default {
  checkComponents (coderId) {
    return axios.get(
     `/api/had-components/${coderId}`
    );
  },
  createMonthlyComponent (zohoUserId) {
    return axios.get(
        `/createMonthlyComponent/${zohoUserId}`
    );
  }
}