var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "d-flex align-items-center page-title mb-3" }, [
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary btn-back",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.back.apply(null, arguments)
                },
              },
            },
            [
              _c("arrow-left-icon", { staticClass: "custom-class" }),
              _vm._v("Back\n      "),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _vm.canEdit()
            ? _c("h2", { staticClass: "dash-header-title m-0" }, [
                _vm._v("Edit Component"),
              ])
            : _c("h2", { staticClass: "dash-header-title m-0" }, [
                _vm._v("View Component"),
              ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "border-bottom my-3" }),
      _vm._v(" "),
      _vm.component
        ? _c("component-form", {
            staticClass: "mt-5",
            attrs: {
              mode: "edit",
              isLoading: _vm.isLoading,
              component: _vm.component,
              error: _vm.error,
            },
            on: { save: _vm.onSave },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }