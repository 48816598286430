<template>
    <div>
        <dashboard-header name="Users">
            <template v-if="isAdmin" slot="actions">
              <router-link :to="getCreateCoderUrl()">
                <button class="btn btn-primary px-3"> <user-plus-icon></user-plus-icon>Create user</button>
              </router-link>               
            </template>
        </dashboard-header>
        <coders-filter page="Coders" v-on:search="onSearch"></coders-filter>
        <div class="mt-3 list-user">
            <table class="table">
                <thead>
                    <th></th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Rate</th>
                    <th>Action</th>
                </thead>
                <tbody v-if="codersAdmin">
                    <tr v-for="(u,i) in codersAdmin.items" :key="i">
                        <td>
                            <div class="td-avatar-wrap d-flex align-items-center justify-content-center">
                                <img v-if="u.avatar" width="35px" height="35px" class="td-avatar" :src="'/images/users/'+u.email+u.avatar" alt=""/>
                                <img v-else :src="'https://ui-avatars.com/api/?name=' + u.firstName + '+' + u.lastName + '&size=35'"/>
                            </div>
                        </td>
                        <td>{{u.firstName}}</td>
                        <td>{{u.lastName}}</td>
                        <td>{{u.email}}</td>
                        <td>{{beautifyLabelRole(u)}}</td>
                        <td>{{u.hourlyRate ? `${u.currency === 'USD' ? '$' : '€'} ${u.hourlyRate * 8} / ${u.hourlyRate}` : ''}}</td>
                        <td>
                            <div class="d-flex">
                              <router-link v-if="isAdmin" class="preview-btn btn p-0 mr-1" :to="{ name: 'editCoder', params: { uuid: u.uuid } }">
                                <edit-2-icon class="p-1 ml-2"></edit-2-icon>
                              </router-link>
                              <button v-if="isAdmin" class="btn p-0" @click="onConfirmDeleteCoder(u)">
                                  <trash-icon class="p-1 ml-2"></trash-icon>
                              </button>
                              <router-link v-if="beautifyLabelRole(u) === 'Coder'" class="preview-btn btn p-0 mr-1" :to="{ name: 'coderDashboard', params: { uuid: u.uuid } }">
                                <eye-icon class="p-1 ml-2"></eye-icon>
                              </router-link>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="!codersAdmin.items.length">
                        <td colspan="7">No user to show</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <b-modal
            id="delete-user"
            ref="delete-user"
            centered
            title="Delete User"
            hide-footer
        >
            <span>Are you sure to delete this user?</span>
            <div class="modal-footer w-100 border-0">
                <b-button type="submit" variant="primary" size="sm" class="float-right m-2" @click="onDeleteUser">
                    OK
                </b-button>
                <b-button size="sm" class="float-right m-2" @click.prevent="onCancelDeleteUser">
                    Cancel
                </b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { UserPlusIcon, Edit2Icon, TrashIcon, EyeIcon } from "vue-feather-icons";
import CodersFilter from "../../../components/admin/CodersFilter";
import gql from 'graphql-tag'
export default {
    name:'DashboardUsers',
    apollo: {
        codersAdmin: {
            query: gql`query codersAdmin($search: String) {
              codersAdmin(search: $search) {
                items {
                  uuid,
                  lastName,
                  firstName,
                  avatar,
                  timezone,
                  email,
                  github,
                  skypeId,
                  birthday,
                  zohoUserId,
                  skills,
                  hourlyRate,
                  paymentMethod,
                  accountAddress,
                  createdAt,
                  currency,
                  intraCommunityVat,
                  tdbmRoles{id,label},
                  address {
                    country,
                    city,
                  }
                }
              }
            }`,

            variables () {
                return {
                    search: this.keyword,
                }
            },
        }
    },
    components:{
        CodersFilter,
        UserPlusIcon,
        Edit2Icon,
        TrashIcon,
        EyeIcon
    },
    data: function() {
        return {
            user: null
        };
    },
    computed: {
        keyword() {
            return this.$store.getters['coders/codersFilter'];
        },
        isAdmin() {
          return this.$store.getters['security/hasRole']('admin');
        },
    },
    methods: {
        beautifyLabelRole(u){
            if (u.roles){
                if (u.roles[0]) {
                    switch(u.roles[0].label){
                        case 'admin': return "Admin"; break;
                        case 'project_manager': return "Project Manager"; break;
                        case 'payer': return "Payer"; break;
                        case 'coder': return "Coder"; break;
                        default: return '';
                    }
                }
            }
        },
        onConfirmDeleteCoder(coder) {
            this.$refs['delete-user'].show();
            this.user = coder;
        },
        onDeleteUser() {
            const coderId = this.user.uuid;
            const vm = this;
            this.$refs['delete-user'].hide();
            this.$store.dispatch('coders/deleteCoder', {
                coderId,
                callback: function() {
                    vm.$apollo.queries.codersAdmin.refetch()
                }
            });
        },
        onCancelDeleteUser() {
            this.$refs['delete-user'].hide();
        },
        onSearch(keyword) {
            this.$store.dispatch('coders/setCodersFilter', keyword);
        },
        getCreateCoderUrl() {
          let routeData = this.$router.resolve(
              {
                name: 'createCoder',
              });
          return routeData.href;
        },
    },
}
</script>