<template>
  <router-view></router-view>
</template>

<script>
import axios from 'axios'

export default {
  name: 'app',
  created () {
    axios.interceptors.response.use(undefined, (err) => {
      return new Promise(() => {
        if (err.response.status === 403) {
          this.$router.push({path: '/connection'})
        }
        throw err
      })
    })
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters['security/isAuthenticated']
    },
  },
}
</script>
