<template>
  <table class="table user-table-content">
    <tr v-if="components.length === 0">
      <td colspan="9">No components to show</td>
    </tr>
    <tr v-for="(component, index) in components"  v-if="((userRole!=='manager' || (userRole==='manager' && component.manager && component.manager.uuid===currentUserId && component.status !== 'Archived' )))"  :key="index">
      <td class="compo-name">{{ component.projectName }}</td>
      <td class="compo-name">{{component.name}}</td>
      <td class="compo-manager">{{getDate(component)}}</td>
      <td class="compo-price">
        <span class="mx-1">{{component.currency | symbol}}{{ component.fixedPrice || component.dayRate }}{{component.dayRate ? ' / day' : null}}</span>
      </td>
      <td class="compo-price-icon">
        <span>
            <tag-icon v-if="component.type === STATUS_CODERS_FIXED_PRICE" class="p-1"></tag-icon>
          	<inbox-icon v-else class="p-1"></inbox-icon>
        </span>
      </td>
      <td class="font-accent compo-status">
        <check-icon></check-icon>
        <span class="mx-1">{{component.status}}</span>
      </td>
      <td class="compo-edit-icon">
        <router-link :to="getEditComponentUrl(component)"><edit-2-icon class="m-0 p-1"></edit-2-icon></router-link>
      </td>
    </tr>
  </table>
</template>

<script>
  import {
    InboxIcon,
    CheckIcon,
    DollarSignIcon,
    EyeIcon,
    TagIcon,
    Edit2Icon
  } from 'vue-feather-icons'
  import {
    CODERS_WAITING_PAYMENT,
    CODERS_ALLOWED_PAYMENT,
    CODERS_REQUESTED_PAYMENT,
    CODERS_PAID_PAYMENT,
    CODERS_FIXED_PRICE,
    CODERS_DELEGATION_SKILLS,
    dateFormat
  } from '../../constants';
  
  export default {
    components: {
      InboxIcon,
      CheckIcon,
      DollarSignIcon,
      EyeIcon,
      TagIcon,
      Edit2Icon
    },
    props: ['components','coder', 'userRole'],
    name: 'CoderComponent',
    computed: {
      currentUserId: function(){
        return this.$store.getters['security/id'];
      },
      STATUS_CODERS_FIXED_PRICE: function(){
        return CODERS_FIXED_PRICE;
      }
    },   
    methods: {
      managerName(component){
        return component.manager ? component.manager.firstName + ' ' + component.manager.lastName : ''
      },      
     
      selectComponent(component){
        this.$emit('view', component);
      },

      editComponent(component) {
        let routeData = this.$router.resolve(
            {
              path: `/dashboard/edit-component/${component.uuid}`
            });
        window.open(routeData.href, '_blank');
      },

      getDate(component) {
        return dateFormat(component.startDate);
      },
      getEditComponentUrl(component) {
        let routeData = this.$router.resolve(
            {
              path: `/dashboard/edit-component/${component.uuid}`
            });
        return routeData.href;
      }
    },
    mounted() {},
  };
</script>

