var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "coder-search d-flex align-items-center justify-content-between date-range-filter",
    },
    [
      _c(
        "div",
        { staticClass: "form-group d-flex align-items-center border-bottom" },
        [
          _c("search-icon", { staticClass: "search-icon" }),
          _vm._v(" "),
          _c("date-picker", {
            attrs: { lang: "en", range: "", format: "MM-DD-YYYY" },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }