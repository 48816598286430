import _ from 'lodash';
import { apolloClient } from '../graphql';
import metricsQuery from '../graphql/coder/query/metrics.gql';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    metrics: {}
  },
  getters: {
    isLoading: state => {
      return state.isLoading;
    },
    hasError: state => {
      return state.error !== null;
    },
    error: state => {
      return state.error;
    },
    getMetrics: state => {
      return state.metrics;
    },
  },
  mutations: {
    ['GET_METRICS_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['GET_METRICS_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.metrics = JSON.parse(payload);
    },
    ['GET_METRICS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['CLEAR_METRICS'](state) {
      state.metrics = {};
    }
  },
  actions: {
    resetStore() {
      apolloClient.resetStore();
    },
    getMetrics({ commit }) {
      commit('GET_METRICS_REQUEST');
      return apolloClient
        .query({
          query: metricsQuery,
        })
        .then(res => commit('GET_METRICS_SUCCESS', res.data.metrics))
        .catch(err => commit('GET_METRICS_ERROR', err));
    },
    clearMetrics({ commit }) {
      commit('CLEAR_METRICS');
    },
  }
};
