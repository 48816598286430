import _ from 'lodash';
import { apolloClient } from '../graphql';
import paymentsQuery from '../graphql/coder/query/payments.gql';
import deletePaymentMutation from '../graphql/coder/mutation/deletePayment.gql';
import paymentMutation from '../graphql/coder/mutation/payment.gql';
import paymentBulkMutation from '../graphql/coder/mutation/payment_bulk.gql';
import paymentStatusMutation from '../graphql/coder/mutation/payment_status.gql';
import paymentFeedbackMutation from '../graphql/coder/mutation/payment_feedback.gql';
import payRequestedPaymentMutation from '../graphql/coder/mutation/pay_payment.gql';
import lockRequestedPaymentMutation from '../graphql/coder/mutation/lock_payment.gql';
import coderPaymentsQuery from '../graphql/coder/query/coderPayments.gql';
import coderPaymentsByIdQuery from '../graphql/coder/query/coderPaymentsById.gql';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    componentId: null,
    payments: [],
    coderPayments:[]
  },
  getters: {
    isLoading: state => {
      return state.isLoading;
    },
    hasError: state => {
      return state.error !== null;
    },
    error: state => {
      return state.error;
    },
    getPayments: state => {
      return state.payments;
    },
    getCoderPayments: state => {
      return state.coderPayments;
    },
    getComponentPayments: state => uuid => {
      return state.payments.find(payment => payment.uuid === uuid);
    },
  },
  mutations: {
    ['GET_PAYMENTS_REQUEST'](state, componentId) {
      state.isLoading = true;
      state.error = null;
      state.componentId = componentId;
      state.payments = [];
    },
    ['GET_PAYMENTS_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.payments = payload;
    },
    ['GET_PAYMENTS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['GET_CODER_PAYMENTS_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
      state.coderPayments = [];
    },
    ['GET_CODER_PAYMENTS_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.coderPayments = payload;
    },
    ['GET_CODER_PAYMENTS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['GET_CODERS_PAYMENTS_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['GET_CODERS_PAYMENTS_SUCCESS'](state) {
      state.isLoading = false;
      state.error = null;
    },
    ['GET_CODERS_PAYMENTS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['SAVE_PAYMENT_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['SAVE_PAYMENT_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.payments = [ ...state.payments, payload ];
    },
    ['SAVE_PAYMENT_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['DELETE_PAYMENT_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['DELETE_PAYMENT_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.payments=payload;
      state.error = null;
    },
    ['DELETE_PAYMENT_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['SAVE_BULK_PAYMENT_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['SAVE_BULK_PAYMENT_SUCCESS'](state) {
      state.isLoading = false;
      state.error = null;
    },
    ['SAVE_BULK_PAYMENT_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['SAVE_PAYMENT_STATUS_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['SAVE_PAYMENT_STATUS_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      const index = state.payments.findIndex(payment => payment.uuid === payload.uuid);
      state.payments[index] = payload;
    },
    ['SAVE_PAYMENT_STATUS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['FEEDBACK_PAYMENT_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['FEEDBACK_PAYMENT_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
    },
    ['FEEDBACK_PAYMENT_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['CLEAR_PAYMENTS'](state) {
      state.payments = [];
    }
  },
  actions: {
    getCoderPayments({ commit }) {
      commit('GET_CODER_PAYMENTS_REQUEST');
      return apolloClient
        .query({
          query: coderPaymentsQuery
        })
        .then(res => commit('GET_CODER_PAYMENTS_SUCCESS', res.data.coderPaymentsSums))
        .catch(err => commit('GET_CODER_PAYMENTS_ERROR', err));
    },
    getCoderPaymentsById({ commit }, payload) {
      commit('GET_CODER_PAYMENTS_REQUEST');
      return apolloClient
        .query({
          query: coderPaymentsByIdQuery,
          variables: {
            coderId: payload.uuid
          }
        })
        .then(res => commit('GET_CODER_PAYMENTS_SUCCESS', res.data.coderPaymentsSumsById))
        .catch(err => commit('GET_CODER_PAYMENTS_ERROR', err));
    },
    resetStore() {
      apolloClient.resetStore();
    },
    getPayments({ commit}, componentId) {
      commit('GET_PAYMENTS_REQUEST', componentId);
      return apolloClient
        .query({
          query: paymentsQuery,
          variables: {
            componentId,
          }
        })
        .then(res => {
          commit('GET_PAYMENTS_SUCCESS', res.data.componentPayment.items)
          apolloClient.resetStore()

        })
        .catch(err => {
          commit('GET_PAYMENTS_ERROR', err)
        });
    },

    getComponentPayments({ commit}, componentId) {
      commit('GET_PAYMENTS_REQUEST', componentId);
      return new Promise(function(resolve, reject) {
          apolloClient
          .query({
            query: paymentsQuery,
            variables: {
              componentId,
            }
          })
          .then(res => {
            resolve(res.data.componentPayment.items);
            apolloClient.resetStore();

          })
          .catch(err => {
            commit('GET_PAYMENTS_ERROR', err);
            reject(err);
          });
      });
    },
    savePayment({ commit }, { componentId, payment, callback }) {
      commit('SAVE_PAYMENT_REQUEST');
      return apolloClient
        .mutate({
          mutation: paymentMutation,
          variables: {
            componentId,
            payment
          }
        })
        .then(res => {
          commit('SAVE_PAYMENT_SUCCESS', res.data.payment);
          callback();
        })
        .catch(err => commit('SAVE_PAYMENT_ERROR', err));
    },
    deletePayment({ commit, dispatch}, {paymentId, email, componentId, callback }) {
      commit('DELETE_PAYMENT_REQUEST');
      apolloClient.resetStore()
      return apolloClient
          .mutate({
            mutation: deletePaymentMutation,
            variables: {
              paymentId,
              email,
              componentId
            }
          })
          .then(res => {
            dispatch('getPayments',componentId);
            commit('DELETE_PAYMENT_SUCCESS', res.data.deletePayment);
          })
          .catch(err => {
            commit('DELETE_PAYMENT_ERROR', err)
          }
      );
    },
    saveBulkPayment({ commit, dispatch }, { userId, componentId, payments, callback }) {
      commit('SAVE_BULK_PAYMENT_REQUEST');
      return apolloClient
        .mutate({
          mutation: paymentBulkMutation,
          variables: {
            payments
          }
        })
        .then(res => {
          commit('SAVE_BULK_PAYMENT_SUCCESS', res.data.bulkPayment);
          dispatch('coders/addComponentPayments', {
            userId,
            componentId,
            payments: res.data.bulkPayment
          }, {
            root: true,
          });
          callback();
        })
        .catch(err => commit('SAVE_BULK_PAYMENT_ERROR', err));
    },
    updatePaymentStatus({ commit }, { paymentId, status, callback }) {
      commit('SAVE_PAYMENT_STATUS_REQUEST');
      return apolloClient
        .mutate({
          mutation: paymentStatusMutation,
          variables: {
            paymentId,
            status,
          }
        })
        .then(res => {
          commit('SAVE_PAYMENT_STATUS_SUCCESS', res.data.paymentStatus);
          callback();
        })
        .catch(err => commit('SAVE_PAYMENT_STATUS_ERROR', err));
    },
    paymentFeedback({ commit }, { paymentId, protectedManagerComment, privateManagerComment, protectedCoderComment, privateCoderComment, callback }) {
      commit('FEEDBACK_PAYMENT_REQUEST');
      return apolloClient
        .mutate({
          mutation: paymentFeedbackMutation,
          variables: {
            paymentId,
            protectedManagerComment,
            privateManagerComment,
            protectedCoderComment,
            privateCoderComment,
          }
        })
        .then(res => {
          commit('FEEDBACK_PAYMENT_SUCCESS', res.data.paymentFeedback);
          callback();
        })
        .catch(err => commit('FEEDBACK_PAYMENT_ERROR', err));
    },
    payRequestedPayments({ commit, dispatch }, { paymentIds, totalDollar, totalEuro, callback }) {
      commit('GET_CODERS_PAYMENTS_REQUEST');
      return apolloClient
          .mutate({
            mutation: payRequestedPaymentMutation,
            variables: {
              paymentIds,
              totalDollar,
              totalEuro
            }
          })
          .then(res => {
            commit('GET_CODERS_PAYMENTS_SUCCESS', res.data.payRequestedPayment.items);
            callback();
          })
          .catch(err => {commit('GET_CODERS_PAYMENTS_ERROR');});
    },
    lockRequestedPayments({ commit, dispatch }, { paymentIds, lock, callback }) {
      commit('GET_CODERS_PAYMENTS_REQUEST');
      return apolloClient
          .mutate({
            mutation: lockRequestedPaymentMutation,
            variables: {
              paymentIds,
              lock
            }
          })
          .then(res => {
            commit('GET_CODERS_PAYMENTS_SUCCESS', res.data.lockRequestedPayment.items);
            callback();
          })
          .catch(err => {commit('GET_CODERS_PAYMENTS_ERROR');});
    },
    clearPayments({ commit }) {
      commit('CLEAR_PAYMENTS');
    },
  }
};
