var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "componentModal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "componentModalLabel",
        "aria-hidden": "true",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm.selectedComponent.name
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "p-3" }, [
                    _c("div", { staticClass: "md-title" }, [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c(
                          "h3",
                          { staticClass: "font-accent font-pseudo-underline" },
                          [_vm._v(_vm._s(_vm.selectedComponent.name))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ml-auto d-flex align-items-center justify-content-center",
                          },
                          [
                            _c("div", { staticClass: "p-2" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  attrs: { "data-dismiss": "modal" },
                                },
                                [_c("x-icon", { staticClass: "m-0" })],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "d-flex component-badges my-2",
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "my-2" }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.selectedComponent.description
                                ? _vm.selectedComponent.description
                                : "Description"
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", [
                        _c(
                          "span",
                          [
                            _vm.selectedComponent.type &&
                            _vm.selectedComponent.type ===
                              _vm.STATUS_CODERS_FIXED_PRICE
                              ? _c("tag-icon", { staticClass: "p-1" })
                              : _c("inbox-icon", { staticClass: "p-1" }),
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.selectedComponent.type) +
                                "\n                            "
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-auto" }, [
                        _c("h1", { staticClass: "font-accent" }, [
                          _vm._v(
                            "$" +
                              _vm._s(
                                _vm.selectedComponent.dayRate ||
                                  _vm.selectedComponent.fixedPrice
                              )
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _c("div", [
                  _c("div", { staticClass: "mb-5 mt-2 mx-5" }, [
                    _c("div", { staticClass: "d-flex mb-4" }, [
                      _c("div", { staticClass: "p-2 ml-auto" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: { "data-dismiss": "modal" },
                          },
                          [_c("x-icon", { staticClass: "m-0" })],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("h3", { staticClass: "m-4 text-center" }, [
                      _vm._v(
                        _vm._s(
                          _vm.selectedComponent
                            ? _vm.selectedComponent
                            : "No component selected"
                        )
                      ),
                    ]),
                  ]),
                ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }