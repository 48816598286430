var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center notification px-1 mb-5" },
    [
      _c("info-icon"),
      _vm._v(" "),
      _c("div", { staticClass: "nf-content" }, [
        _c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.message))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "nf-controls d-flex ml-auto" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-feather",
            on: {
              click: function ($event) {
                return _vm.viewContract(_vm.notification)
              },
            },
          },
          [_c("eye-icon")],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }