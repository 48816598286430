<template>
  <div class="container">
    <div class="navbar d-flex justify-content-center mt-3">
      <div class="navbar-brand mx-auto">
        <a href="/" @click.prevent="goHome">
          <img class="brand-logo" src="/images/logo.png" alt="logo">
        </a>
      </div>
    </div>
    <div class="normal-page flex-grow-1 flex-shrink-1">
      <div class="container">
        <slot/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name:'NormalWrapper',
    methods: {
      goHome() {
        this.$router.push('/connection');
      }
    }
}
</script>
