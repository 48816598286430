<template>
    <div>
        <dashboard-header class="border-bottom ">
            <template slot="actions">
                <router-link :to="getCreateComponentUrl()">
                  <button class="btn btn-primary px-3"><plus-icon></plus-icon> Create component</button>
                </router-link>
                <router-link :to="getCreateCoderUrl()">
                  <button v-if="isAdmin" class="btn btn-primary px-3"><user-plus-icon></user-plus-icon>Create user</button>  
                </router-link>
            </template>
        </dashboard-header>
<!--        <metrics></metrics>-->
        <component-filter page="Dashboard" v-on:search="onSearch"></component-filter>
        <div class="admin-table mt-4">
            <ApolloQuery
                    :query="require('../../../graphql/coder/query/coders.gql')"
                    :variables="{ coder: filter.coder, component: filter.component }"
                    :options="{notifyOnNetworkStatusChange: true}"
            >
                <template v-slot="{ result: { loading, error, data } }">
                    <div v-if="loading" class="text-center py-4">
                        <b-spinner label="Spinning"></b-spinner>
                    </div>
                    <b-alert show variant="danger" v-if="error">{{error}}</b-alert>
                    <div class="admin-accordions" v-if="!loading">
                        <coders-accordion
                                v-if="hasComponents(coder)"
                                v-for="(coder, index) in data.coders.items"
                                :coder="coder"
                                :key="index"
                        ></coders-accordion>
                        <div class="user-table admin-dash-table" v-if="data.coders.length <= 0"><h6>No Coder To Show</h6></div>
                    </div>
                </template>
            </ApolloQuery>
        </div>
    </div>
</template>

<script>
    import Metrics from "../../../components/admin/Metrics";
    import CodersAccordion from "../../../components/admin/CodersAccordion";
    import ComponentFilter from "../../../components/admin/ComponentFilter";

    import {
        PowerIcon,
        PlusIcon,
        UserPlusIcon,
    } from "vue-feather-icons";
    export default {
        name: "DashboardAdmin",
        components: {
            CodersAccordion,
            ComponentFilter,
            Metrics,
            PlusIcon,
            PowerIcon,
            UserPlusIcon,
        },
        created: function(){
            if (this.isAdmin) {
                this.$store.dispatch('coders/getCoders');
            } else {
                this.$store.dispatch('coders/getManagerCoders');
            }
        },
        watch: {
            '$route': function() {
                this.$store.dispatch('coders/getManagerCoders');
                this.$store.dispatch('coders/getCoders');
            }
        },
        computed: {
            isAdmin: function(){
                return this.$store.getters['security/hasRole']('admin');
            },
            filter() {
                let storedFilter = this.$store.getters['components/dashboardFilter'];
                if (storedFilter) {
                    return storedFilter;
                }

                return { coder: "", component: "" };
            }
        },
        methods: {
            onSearch(filter) {
                this.$store.dispatch('components/setDashboardFilter', filter);
            },
          getCreateComponentUrl() {
            let routeData = this.$router.resolve(
                {
                  path: `/dashboard/create-component/`
                });
            return routeData.href;
          },
          getCreateCoderUrl(component) {
            let routeData = this.$router.resolve(
                {
                  path: `/dashboard/create-coder`
                });
            return routeData.href;
          },
          hasComponents(coder) {
            let goodComponent = coder.components.items.filter(c => c.status !== "Archived" && c.status !== "Declined" && c.status !== "Deleted" && c.status !== "Finished");
            return goodComponent.length > 0;
          }
        },
    };
</script>
