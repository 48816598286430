var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex h-100 admin-wrap" },
    [
      _c("coder-menu", { staticClass: "active" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "admin-dash flex-grow-1 flex-shrink-1" },
        [
          _c("dash-header"),
          _vm._v(" "),
          _c("div", { staticClass: "admin-inner pb-5" }, [
            _c("div", { staticClass: "container" }, [_vm._t("default")], 2),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }