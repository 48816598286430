var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid dash p-0" }, [
    _c("div", { staticClass: "container-fluid px-0" }, [
      _c("div", { staticClass: "d-flex flex-sm-column flex-lg-row pt-2" }, [
        _c(
          "div",
          { staticClass: "p-0 coder-cards" },
          [
            _c("profile-info", {
              attrs: {
                components: _vm.length,
                coder: _vm.profile,
                admins: _vm.admins,
              },
            }),
            _vm._v("\n        " + _vm._s(_vm.payment[0]) + "\n        "),
            _c("payment-info", { attrs: { payment: _vm.payment } }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "p-0 coder-table flex-grow-1" },
          [
            _vm._l(_vm.notifications, function (notification) {
              return _c("notification", {
                key: notification.uuid,
                attrs: { notification: notification },
              })
            }),
            _vm._v(" "),
            _c("component-list", {
              attrs: { user_uuid: _vm.profile.id },
              on: { "update-length": _vm.updateLength },
            }),
            _vm._v(" "),
            _vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "text-center py-4" },
                  [_c("b-spinner", { attrs: { label: "Spinning" } })],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }