<template>
    <div class="modal fade" id="componentModal" tabindex="-1" role="dialog" aria-labelledby="componentModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body" v-if="selectedComponent.name">
                    <div class="p-3">
                        <div class="md-title">
                            <div class="d-flex align-items-center">
                                <h3 class="font-accent font-pseudo-underline">{{selectedComponent.name}}</h3>
                                <div class="ml-auto d-flex align-items-center justify-content-center">
                                    <!-- <div class="p-2">
                                        <edit-2-icon></edit-2-icon>
                                    </div> -->
                                    <div class="p-2">
                                        <button class="btn" data-dismiss="modal">
                                            <x-icon class="m-0"></x-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex component-badges my-2">
                                <!-- project languages -->
                                <!-- <span class="badge badge-pill badge-primary">PHP</span> -->
                            </div>
                            <div class="my-2">
                                <p>{{selectedComponent.description ? selectedComponent.description : 'Description'}}</p>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div>
                                <span>
                                    <tag-icon v-if="selectedComponent.type && selectedComponent.type === STATUS_CODERS_FIXED_PRICE" class="p-1"></tag-icon>
                                    <inbox-icon v-else class="p-1"></inbox-icon>
                                    {{selectedComponent.type}}
                                </span>
                            </div>
                            <div class="ml-auto">
                                <h1 class="font-accent">${{selectedComponent.dayRate || selectedComponent.fixedPrice}}</h1>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div v-else>
                    
                    <div class="mb-5 mt-2 mx-5">
                        <div class="d-flex mb-4">
                            <div class="p-2 ml-auto">
                                <button class="btn" data-dismiss="modal">
                                    <x-icon class="m-0"></x-icon>
                                </button>
                            </div>
                        </div>
                        <h3 class="m-4 text-center">{{selectedComponent ? selectedComponent : "No component selected"}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { LayersIcon , TagIcon, InboxIcon, XIcon, Edit2Icon} from 'vue-feather-icons'
import {
    CREATE_COMPONENT,
    ASSIGN_COMPONENT,
    CODERS_DELEGATION_SKILLS,
    CODERS_FIXED_PRICE
} from '../../constants';
export default {
    name:'ComponentModal',
    props:['selectedComponent'],
    components:{
        LayersIcon,
        TagIcon,
        InboxIcon, 
        XIcon,
        Edit2Icon
    },
    computed: {
        STATUS_CODERS_DELEGATION_SKILLS(){
            return CODERS_DELEGATION_SKILLS;
        },
        STATUS_CODERS_FIXED_PRICE(){
            return CODERS_FIXED_PRICE;
        },
    },
    data(){
        return {
            
        }
    },
    methods: {
        dateParse(dateStr){
            var dateObj = new Date(dateStr)
            return [dateObj.getDate(), (dateObj.getMonth()+1), dateObj.getFullYear()].join('/')
        }
    },
}
</script>