<template>
    <div class="dashboard-components">
        <dashboard-header name="Components">
            <template slot="actions">
                <router-link :to="getCreateComponentUrl()">
                  <button class="btn btn-primary px-3"> <plus-icon></plus-icon> Create component</button>
                </router-link>
            </template>
        </dashboard-header>
        <component-filter page="Components" v-on:search="onSearch"></component-filter>
        <div class="mt-3 list-component">
            <div v-if="isLoading" class="text-center py-4">
                <b-spinner label="Spinning"></b-spinner>
            </div>
            <table class="table dash-table">
                <thead>
                    <th>Name</th>
                    <th>Project</th>
                    <th>Coder</th>
                    <th>Manager</th>
                    <th>Type</th>
                    <th>Price</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Actions</th>
                </thead>
                <tbody v-if="components">
                    <tr v-for="(c,i) in components" :key="i" style="cursor: auto">
                        <td class="compo-name">{{c.name}}</td>
                        <td class="compo-name">{{ c.projectName }}</td>
                        <td><span v-if="c.coder">{{c.coder.firstName}} {{c.coder.lastName}}</span></td>
                        <td><span v-if="c.manager">{{c.manager.firstName}} {{c.manager.lastName}}</span></td>
                        <td class="compo-price-icon">
                            <span>
                                <tag-icon v-if="c.type === STATUS_CODERS_FIXED_PRICE" class="p-1"></tag-icon>
                                <inbox-icon v-else class="p-1"></inbox-icon>
                            </span>
                        </td>
                        <td class="compo-price">
                            <span class="mx-1">{{c.currency==='EUR'?'€':'$'}}{{ c.fixedPrice || c.dayRate }}{{c.dayRate ? ' / day' : null}}</span>
                        </td>
                        <td class="compo-manager">{{getDate(c.startDate)}}</td>
                        <td class="font-accent compo-status">
                            <span class="mx-1">{{c.status}}</span>
                        </td>
                      <td>
                        <router-link :to="getEditComponentUrl(c)">
                          <span v-if="isAdmin || (c.manager && c.manager.uuid === userId)"><edit2-icon class="p-1 ml-2"></edit2-icon></span>
                          <span v-else><eye-icon class="p-1 ml-2"></eye-icon></span>
                        </router-link>
                      </td>
                    </tr>
                    <tr v-if="!components.length">
                        <td colspan="8">No component to show</td>
                    </tr>
                </tbody>
            </table>
            <b-pagination v-model="currentPage" :total-rows="count" :per-page="filter.limit" align="center" @change="onPaginationChange" />
        </div>
    </div>
</template>

<script>
    import gql from 'graphql-tag';
    import { mapGetters, mapActions } from 'vuex';
    import { InboxIcon, TagIcon, ChevronLeftIcon, ChevronRightIcon, PlusIcon, EyeIcon, Edit2Icon } from 'vue-feather-icons';
    import { CODERS_FIXED_PRICE, dateFormat } from '../../../constants';
    import ComponentFilter from "../../../components/admin/ComponentFilter";
    import { BSpinner } from "bootstrap-vue";

    export default {
        name: 'DashboardComponenets',
        components: {
            InboxIcon,
            TagIcon,
            ComponentFilter,
            ChevronLeftIcon,
            ChevronRightIcon,
            BSpinner,
            PlusIcon,
            EyeIcon,
            Edit2Icon,
        },
        data: function() {
            return {
                currentPage: 1,
                timer: null
            };
        },
        created: function(){
            this.getComponents(this.filter);
        },
        computed: {
            ...mapGetters({
                isLoading: 'components/isLoading',
                components: 'components/getComponent',
                count: 'components/getCount',
                userId: 'security/id',
            }),
            isAdmin() {
              return this.$store.getters['security/hasRole']('admin');
            },
            STATUS_CODERS_FIXED_PRICE: function(){
                return CODERS_FIXED_PRICE;
            },
            filter() {
                let filter = {
                    coder: "",
                    component: "",
                    projectId: "",
                    cityId: "",
                    limit: 20,
                    offset: 0
                };
                let storedFilter = this.$store.getters['components/componentsFilter'];
                if (storedFilter) {
                    filter = { ...filter, ...storedFilter };
                }
                return filter;
            }
        },
        methods: {
            ...mapActions({
                getComponents: 'components/getComponents'
            }),
            getCreateComponentUrl() {
              let routeData = this.$router.resolve(
                  {
                    name: 'createComponent',
                  });
              return routeData.href;
            },
            onSearch(filter) {
                this.$store.dispatch('components/setComponentsFilter', filter);
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    this.$store.dispatch('components/getComponents', this.filter);
                }, 800);
            },
            isBelongSearchKey(name, type) {
                let condition = name.toLowerCase().indexOf(this.filter[type].toLowerCase())
                if (condition > -1) {
                    return true
                }
                return false
            },
            getDate(startDate) {
                return dateFormat(startDate);
            },
            getEditComponentUrl(component) {
              let routeData = this.$router.resolve(
                  {
                    path: `/dashboard/edit-component/${component.uuid}`
                  });
              return routeData.href;
            },
            onPaginationChange(page) {
                this.currentPage = page ? page : 1;
                this.filter.offset = (page - 1) * this.filter.limit;
                this.getComponents(this.filter);
            }
        }
    }
</script>