<template>
    <div class="mb-5 d-flex py-3">
        <div>
            <h2 class="dash-header-title">{{title ? title : defaultTile}} </h2>
        </div>
        <div class="ml-auto d-flex justify-content-between btn-list">
            <slot name="actions"/>
        </div>
    </div>
</template>

<script>

  export default {
    props: {
      name,
      title: {
        type: String,
        default: ''
      }
    },
    name: 'DashboardHeader',
    computed: {
      pageName() {
        return this.name ? this.name : 'Dashboard'
      },
      isAdmin: function () {
        return this.$store.getters['security/hasRole']('admin');
      },
      isPayer: function () {
        return this.$store.getters['security/hasRole']('payer');
      },
      defaultTile: function () {
        return this.isAdmin ? "Admin / " + this.pageName : (this.isPayer ? "Payer / Dashboard" : "Manager /" + this.pageName)
      }
    },
    methods: {},
    created() {
    },
    mounted() {
    },
  };
</script>

