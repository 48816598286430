var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auth-wrap" }, [
    _c("div", { staticClass: "auth-content center" }, [
      _c("div", { staticClass: "auth-container login" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "auth-form" },
          [
            _c(
              "b-form",
              { on: { submit: _vm.performLogin } },
              [
                _vm.hasError
                  ? _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
                      _vm._v(_vm._s(_vm.error)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "fieldset-username",
                      label: "E-mail",
                      "label-for": "input-username",
                      "invalid-feedback": _vm.validationMsg.username,
                      state: _vm.validateState("username"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: { required: true },
                          expression: "{ required: true }",
                        },
                      ],
                      attrs: {
                        id: "input-username",
                        name: "username",
                        placeholder: "Enter e-mail",
                        state: _vm.validateState("username"),
                        disabled: _vm.isLoading,
                      },
                      model: {
                        value: _vm.credentials.login,
                        callback: function ($$v) {
                          _vm.$set(_vm.credentials, "login", $$v)
                        },
                        expression: "credentials.login",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "fieldset-password",
                      label: "Password",
                      "label-for": "input-password",
                      "invalid-feedback": _vm.validationMsg.password,
                      state: _vm.validateState("password"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: { required: true },
                          expression: "{ required: true }",
                        },
                      ],
                      attrs: {
                        id: "input-password",
                        type: "password",
                        placeholder: "Enter password",
                        name: "password",
                        state: _vm.validateState("password"),
                        disabled: _vm.isLoading,
                      },
                      model: {
                        value: _vm.credentials.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.credentials, "password", $$v)
                        },
                        expression: "credentials.password",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group auth-item my-4" },
                  [
                    _vm.isLoading
                      ? _c(
                          "div",
                          { staticClass: "text-center py-4" },
                          [_c("b-spinner", { attrs: { label: "Spinning" } })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isLoading
                      ? _c(
                          "b-button",
                          {
                            staticClass: "btn-block btn-connect py-2",
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              disabled: _vm.isLoading,
                            },
                          },
                          [_vm._v("Connect")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "auth-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "help-link font-bold",
                        attrs: { to: { name: "confirmEmail" } },
                      },
                      [_c("u", [_vm._v("Reset Password")])]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "auth-logo-wrap" }, [
      _c("img", { attrs: { src: "/images/logo.png" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }