<template>
  <wrapper>
    <section class="p-3 m-3">
      <div>
        <h1 class="text-center">Join Coders - Terms and Conditions</h1>
      </div>
    </section>
    <b-form @submit="onSubmit">
      <div class="row justify-content-center">
        <div class="row">
          <div class="col-md-12">
            <h1 class="form-section-title">Personal Information</h1>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group
              id="fieldset-name"
              label="First Name"
              label-for="input-first-name"
              :state="validateState('form.firstName')"
              :invalid-feedback="validationMsg.firstName"
            >
              <b-form-input
                id="input-first-name"
                v-model="form.firstName"
                class="form-control"
                name="form.firstName"
                placeholder="First name"
                v-validate="{ required: true }"
                :state="validateState('form.firstName')"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group
              id="fieldset-name"
              label="Last Name"
              label-for="input-last-name"
              :state="validateState('form.lastName')"
              :invalid-feedback="validationMsg.lastName"
            >
              <b-form-input
                id="input-last-name"
                v-model="form.lastName"
                class="form-control"
                name="form.lastName"
                placeholder="Last name"
                v-validate="{ required: false }"
                :state="validateState('form.lastName')"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group
              id="fieldset-country"
              label="Country"
              label-for="input-country"
              :invalid-feedback="validationMsg.country"
              :state="validateState('form.country')"
            >
              <b-form-select
                v-model="form.country"
                id="input-country"
                class="form-control"
                name="form.country"
                v-validate="{ required: true }"
                :options="countries"
                :state="validateState('form.country')"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6 col-sm-12">
            <b-form-group
              id="fieldset-city"
              label="City"
              label-for="input-city"
              :state="validateState('form.city')"
              :invalid-feedback="validationMsg.city"
            >
              <b-form-input
                id="input-city"
                v-model="form.city"
                class="form-control"
                name="form.city"
                placeholder="City"
                v-validate="{ required: true }"
                :state="validateState('form.city')"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              id="fieldset-address"
              label="Address *"
              label-for="input-address"
            >
              <b-form-textarea
                id="textarea"
                v-model="form.address"
                placeholder="Enter Address Information"
                rows="3"
                max-rows="6"
                v-validate="{ required: true }"
                :state="validateState('form.address')"
              ></b-form-textarea>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <h1 class="form-section-title">General Terms and Conditions of Use</h1>
            <div class="form-group">
              <div class="terms-area p-3">
                <p> Please note:
                    The English translation of these General Terms and Conditions of Use is provided for the convenience of our non-French-speaking users. Regardless of this, only the original French-language version is legally binding.
                </p>
                <p> Conducting business with THE CODING MACHINE is conditioned by your acceptance of the terms, conditions, restrictions and notices contained herein as well as those d        documents included herein by its reference. These General Terms and Conditions of Use define the rules of THE CODING MACHINE Web Site for all Users.
                    For Developers, these General Terms and Conditions of Use are completed by The Detailed Conditions related on a specific contract for the development of one component. They form together “The Contract”.
                </p>
                <p> "THE CODING MACHINE" (TCM) is registered at the company trade register of Paris ‎ under the number 48325400900051.THE CODING MACHINE office is located on 56 rue de         Londres, 75018 PARIS, France, Phone number: +33 1 85 08 34 98. THE CODING MACHINE is an online community services you can access via: www.thecodingmachine.com.
                    Mr Jean-Guillaume DUJARDIN is in charge of the publication. Mr David NEGRIER is in charge of all technical matters for THE CODING MACHINE.
                </p>
                
                <p> The website coders.thecodingmachine.com is hosted by GCP.
                    The use of coders.thecodingmachine.com website constitutes an agreement to all such terms, conditions, restrictions and notices.
                    For Users and Developers, by checking the box besides the statement “I certify that I am at least 18 years old. I have read and accepted the conditions of use of THE CODING MACHINE”, located on the sign up page, you recognize that you are held to the aforementioned terms, conditions, restrictions, and notices.
                </p>
                
                <h2>Part 1</h2>
                    
                    <h3>Article 1 – Definitions</h3>
                
                <p>Bugs: A bug is a program dysfunction. It’s a fault in the development impacting for instance on the performance or the functionalities of the component. It can also be the non respect of the detailed specifications.
                Blocking bug: this dysfunction has a severe impact. For instance, the component is impossible to use.
                Component: A Component is a part of the total development associated with a Project. Most of the time, a Component is isolated from the other Components. Its operation must meet the needs expressed in the Requirements.
                Account: Space of coders.thecodingmachine.com accessible to the User on indication of his email and his password.
                The Detailed Conditions: The Detailed Conditions complete the General Terms and Conditions of Use (this document) when a Developer has been selected for a component. The Detailed Conditions completed by the General Terms and Conditions of Use constitute a contract between you and TCM, specifying the obligations of both parties (delivery date, guarantee, price…).
                Contract : set of documents described in the “Contractual Documents”
                Developer: a developer is a user (individual or company) selected by THE CODING MACHINE to develop a component. Therefore, a Developer has an account on the site and has access to its components.
                Evolution: An Evolution is a change, an extension or an improvement of the Requirements Document.
                Username: The user can access his user account by specifying his username and his password on the site.
                Delivery: Action of sending to TCM the Component developed, tested and not containing any dysfunction.
                Service: The Service is the development and the test of a Component by a developer. More generally, it is every activity done by the Developer for TCM.
                Project: All the Components lie within the scope of a Project. The Developer selected for the development of a Component can thus communicate with the Developers whose Component refers to the same Project via Ryver or email.
                The component has been developed correctly in regard of the Requirement.
                Site : TCM services are accessible via the web-site coders.thecodingmachine.com
                </p>
                
                <p> Requirements : documents published by THE CODING MACHINE describing:
                •	the business needs of the project,
                •	the functional or technical design of the component.
                This document can be functional while indicating for instance, the detail of a calculation or the graphics of a screen, or can be technical while indicating, for example, the nature of the interfaces between two Components or the technical architecture of the solution.
                User: a User is an individual who has been registered but who has not been selected yet for the development of a Component. A user is deemed to know and respect the General Terms and Conditions of Use. To register, the User has to click on the link he received by email to accept those General terms. He is required to check the information pre-filled in the form and add his real home address used to be put up on the invoice for each component. At the end of this process the user has to accept the General Terms and Conditions of Use. Once accepted, the user can set up his password and access to his dashboard.
                  </p>
                
                    <h3>Article 2 – Contractual Documents</h3>
                
                <p> The Contract is made of three documents by order of importance:
                1. The General Terms and Conditions of Use,
                2. The Component contract,
                3. Collaboration document
                4. Requirements
                In case of contradiction between the documents, the most important document will prevail.
                </p>
                
                <h2>Part 2 – TERMS AND CONDITION OF USE</h2>
                      
                    <h3>Article 3 – Access and Subscription to the website</h3>
                          <h4>3.1 Subscription</h4>
              
               <p>To be eligible for membership with TCM, you must be:
                •	Either an individual at least 18 years of age and have the capacity to form legally binding contracts. Individuals should be entitled to emit invoices as described in Article 121-1 of French Commercial Law,
                •	Or a registered company.
                In order to subscribe to THE CODING MACHINE Website you have to accept the CGU & set up your password
                TCM reserves the right to inquire into the truthfulness of such information and cancel membership if members do not comply with our requirement of truthfulness.
                In the event that any submitted information is modified after the creation of a user account, the User is required to inform TCM in a timely manner.</p>   
                        
                        <h4>3.2 User account</h4>
                
                <p>The User chooses his password. It is recommended to use alternate letters and numbers rather than a word. TCM will make every effort to ensure the data protection relating to its Users and ensure the safety of the User’s data.
                The User is the only authorized person to access his TCM account. All consequences of statements and actions made by an intermediary holding with both his email and password are his sole responsibility. In consequence, the User accepts that the use of his email and password is a proof of his identity and implies his assent for the coming operations while he is logged on.
                The Users and Developers accept expressly that the systems of automatic recording of THE CODING MACHINE are regarded as being worth proof for:
                •	The nature and the content of the requirements,
                •	The nature, the content, the price, the date and the time for their acceptation of the component contract
                •	Their identity,
                •	And more widely, for any information exchanged.
                The User accepts not to sue TCM for any damages resulting from the use by a third party of his email and Password.
                In the event of fraudulent use or loss of his user account, the User is required to immediately inform TCM. The user remains liable for the use of his account until he informs TCM.
                If a user is found disseminating his/her email and password in a way contrary to its intended use, TCM reserves the right to cancel the user’s account without notice. TCM could also sue the User for damages incurred.</p>
                      
                    <h3>Article 4 – Intellectual Property</h3>

              <p>Marks, logos, graphics, photographs, animations, videos, texts, computer programs developed by any and all developers, and texts found on coders.thecodingmachine.com are the property of THE CODING MACHINE. Under penalty of law, the material found on this site may not be reproduced and may not be distributed or otherwise used in any manner except with the express permission of THE CODING MACHINE.
              The rights of use conceded by TCM to the User are strictly limited to access, remote loading, printing, copies on all supports (hard disk, cloud, usb key, etc.) and with the use of these documents for a private and personal use within the framework and for the duration of adhesion with TCM. Any other use by the User is prohibited without the written authorization of TCM.
              In particular, the user agrees that he will not engage in modifying, copying, reproducing, downloading, diffusing, transmitting, exploiting commercially and/or distributing in any way the intellectual properties of THE CODING MACHINE and any computer codes developed for THE CODING MACHINE. All rights are reserved.
              TCM cannot be held responsible for the contents diffused by a User, with his initiative and under his exclusive responsibility, likely to contravene the rights of one or more other Users or the thirds. In particular, for any content the User puts on the website, the User ensures TCM he holds the rights of intellectual ownership or that he has the authorization of relevant owner of the content (information, data, etc.). Furthermore, the User will be held liable for the consequences of the dissemination of these contents on the Site.
              </p>
                    <h3>Article 5 – Account cancellation</h3>

                <p>TCM and You may cancel your account at any time without any reason, especially in case of the non-utilization of the Account for more than 12 months.
                The user may cancel his account by contacting: contact {at} thecodingmachine.com
                The Account is suppressed in a timely manner. A confirmation email is sent to the User.
                If a developer violates any of the provisions of TCM’s General Terms and Conditions of Use, TCM reserves the right to suspend or cancel the developer’s account without prior notice or damages. The User is informed by email that his Account has been suppressed and that the Contract has been cancelled.
                This cancellation will take place immediately and the User remains liable for the damages that could be claimed by TCM.
                TCM can cancel any Account if the information given by the User or the Developer is false or does not comply with French law.
                A cancellation for misconduct implies the cancellation of any Contract in progress. If the Account belongs to a company, one cancellation does not imply the cancellation of every Account hold by the company.
                However, in case of an attempt to hack TCM’s web site, TCM can cancel every Account without prior notice or damages.
                </p>
                     <h3>Article 6 – Responsibilities</h3>
                                
                          <h4>6.1 TCM’s Guarantees</h4>
                
                <p>TCM will not be held responsible for the direct or indirect damage suffered by the User and in particular for the loss of data carried out by the User. It is the responsibility of the User to ensure that are made backup copies of his work.
                TCM cannot be held responsible for deterioration of the developer’s personal hardware or software following the download and/or the execution for Components published on a purely illustrative basis by TCM on its site.
                If TCM liability is engaged as a result of the User’s misuse of rights under this contract or by law, the User commits himself to guarantee TCM of all judgments against it. This guarantee covers any damages as well as any legal fees TCM might incur.
                <p/>
                
                          <h4>6.2 Events beyond Control</h4>
              
              <p>The characteristics and the constraints of the internet make it impossible for TCM to fully guarantee the safety, availability, and the integrity of data transmitted on the Internet. THE CODING MACHINE also cannot fully guarantee that the site will operate without interruption or error. TCM is not responsible for the failures of internet service providers, hardware problems, or anything or anyone external to TCM. Moreover, TCM’s site could be temporarily unavailable due to maintenance, content, or technical improvements. Events beyond TCM’s control may delay website availability. TCM will not be held liable for the consequences of these delays.
              Also, in no way will THE CODING MACHINE be held liable for direct or indirect damages resulting from loss of data beyond the control of TCM.
              </p>
                
                          <h4>6.3 Act of God</h4>
                
              <p>TCM cannot be responsible for any dysfunctions of the website or access problems due to non-compliant equipment, dysfunctions in the Internet provider services or Internet Network.
              </p>

                          <h4>6.4 External Links</h4>
              
              <p>This site may contain links to other websites or resources. Insofar as THE CODING MACHINE cannot control these external sites, TCM will not be held responsible for (i) the availability or accuracy of such sites or resources, or (ii) the content, advertising, or products on or available from such sites or resources. Any difficulty relating to a hypertext link should be addressed to the relevant webmaster. The inclusion of any link on the site does not imply that TCM endorses the linked site.
              </p>

                      <h3>Article 7 – Modifications</h3>
              
              <p>TCM may amend or modify the website at any time.
                THE CODING MACHINE may amend or modify the terms and conditions without prior notice, effective immediately but not retroactively, by updating this document on coders.thecodingmachine.com.
                Users are invited to consult the most recent version of the General Terms and Conditions of use.
                Users and Developers can cancel their Accounts; failing this they will be deemed to have accepted those changes.
                Developers in charge of the development of a component are especially invited to accept those changes which are solely opposable to TCM.
                </p>

                  <h1>Part 3 – OPERATION OF THE CODING MACHINE</h1>

                    <h2>3.1 - ASSIGNEMENT PHASE</h2>

                        <h3>Article 7 – Developers Selection:</h3>

                            <h4>7.1 Assignment</h4>
                
                <p>By subscribing to TCM, the User will be entitled to get assign a component. The developer accepts the component contract.
                    TCM provides:
                    •	The mission
                    •	The component delivery date,
                    •	The component price,.
                    The dates are expressed in French standard time.

                            <h4>7.2 Eligibility</h4>
                <p>THE CODING MACHINE assesses eligibility. An eligible developer:
                    •	Is capable of binding him/herself to the terms of a contract (in other words, you must be 18 years of age),
                    •	knows his/her intra-community VAT number if he/she is a member of the European Union requiring a valid VAT number to emit an Invoice,
                    •	knows his/her SIREN or SIRET number if he/she is a French resident or citizen.
                    Dishonesty with such information is grounds for nullifying any agreement between THE CODING MACHINE and the Developer.
                </p>                         
                            
                            <h4>7.3 Developers skills</h4>

              <p> The Developer answers the acceptance of the component after reading the whole requirements for the project component. Therefore, the developer shall not answer an acceptance if he has not the skills required to complete the component.
                  The Developer guarantees that he is an IT professional. He declares that:
                  •	He has the means and skills necessary to complete the development of the component he has been selected for,
                  •	He has all the necessary information to develop the component. If not, the Developer should contact THE CODING MACHINE to ask for relevant information,
                  •	He has read the requirements thoroughly and understood them,
                  The Developer finally declares that:
                  •	He understands the quality requirement and the results expected as stated in the requirements,
                  •	He agrees to the deadline for the delivery of the component,
                  •	He agrees to respect the confidentiality of the component.
                  If the User thinks he doesn’t have the necessary information for this component, he should contact THE CODING MACHINE atcontact@thecodingmachine.com, to ask the relevant questions and before accepting the component on the platform.
                  Furthermore, if during the component development, the Developer realizes that he lacks information to complete his work, he will contact THE CODING MACHINE at contact@thecodingmachine.com, in order to ask relevant questions, and before submitting the component.

                  In all cases, the Developer will collaborate in good faith with THE CODING MACHINE and with any other developers working on the Project. He will inform, and if necessary warn THE CODING MACHINE about any noticeable event that should be known for the component development or the component integration.
              </p>
                      <h3> Article 8 Contract</h3>
                          <h4> 8.1 Accepting the contract </h4>
              <p> If you have never worked with THE CODING MACHINE before, you will be asked to submit on the CGU that verify that you have fully understood the terms and conditions of working with THE CODING MACHINE. They include:
                  •	the General Term and Conditions of Use
                  If you already worked with THE CODING MACHINE, you will be asked for every component to accept the component contract : with the detailed requirements provided.
              </p>

                          <h4> 8.2 Community</h4>
              <p> In nearly all cases, THE CODING MACHINE will grant access on Ryver where developers can work together as a team. If a developer faces a problem, he cannot seem to solve for whatever reason, he is advised to go to peers working on the same project. The Ryver platform is also where the project manager can be reached. If you have any questions, they can be addressed to the Project manager via the Ryver or by email. Project managers may also announce general project clarifications that may affect every working team-member. As such, please take part in the team and at least “listen in” on the discussions.
              </p>
                    <h2> 3.2 DEVELOPMENT PHASE</h2>

                        <h3> Article 9 Delivery and Acceptation</h3>
              
              <p> The Developer has to deliver the Component developed to TCM at the delivery date given in the component and in the Detailed Conditions.
                  For each Delivery, TCM will check certain elements before accepting it:
                  •	the Component has been developed in compliance with the Requirements,
                  •	readability of the code delivered,
                  •	compatibility to the Development guidelines and coding standards,
                  •	more widely any verification that is deemed technologically necessary.

                  The Developer has to conform to the tools recommended by TCM in the Requirements.
                  During the completion of the Contract, the Developer accepts to assist TCM for:
                  •	Defining and developing test cases which are mandatory for the Acceptation stage,
                  •	Executing the tests,
                    •	Analyzing the results.
              </p>

                        <h3> Article 10 Intellectual Property Law</h3>
              <p> Note: French Intellectual Property Laws are very specific and refer to “le droit d’auteur”.
                  By accepting the General Terms and Conditions of Use, the Developer concedes with THE CODING MACHINE the exclusive property of the Component described in the Detailed Conditions and the whole economic rights attached to it as defined hereafter, worldwide and for the time allowed by law and international agreements, with no limit on the extent of conceded rights.
                  In compliance with the article L.131-4 §5 of French Intellectual Property Law, rights on the components are yielded for an outright and definitive price, estimated at 10% of the component price. These 10% are included in the component price.
                  The Developer specifically renounces to claim any proportional participation to earnings resulting from the use and exploitation of the Component, since these earnings cannot be evaluated the day the Contract has been signed. He therefore renounces to advance the benefit of article L.131-6 of French intellectual property law.
                  The assignment of rights includes:
                  •	The right of reproduction: right to copy all or part of the Component on any existing or future media including, paper, numeric, electronic or online media, for any use and any destination, including commercial or informative use,
                  •	The right to adapt: right to adapt or modify all or part of the Component, whatever the nature and the environment, for any use and any destination, including commercial or informative use,
                  •	The right to bundle: right to bundle all or part of the Component, whatever the nature and the environment, for any use and any destination, including commercial or informative use,
                  •	The right to translate: right to translate all or part of the Component, whatever the nature and the environment, for any use and any destination, including commercial or informative use,
                  •	The right to show: right to show all or part of the Component, on any existing or future media including, without being exhaustive, paper, numeric, electronic or online media, for any use and any destination, including commercial or informative use.

                  THE CODING MACHINE will therefore be the only entitled entity, directly or indirectly, to exploit and therefore to copy, show, use, compile, decompile, analyze, translate, modify, arrange, bundle, load, print, execute, transmit, store, broadcast, concede, adapt and sell, whatever the way, the Component, whatever the terms and conditions, without possible protest of the Developer.
                  Changes, adaptations, developments, evolutions, translations, transcriptions, and so on… of Component source codes and executable files, done by THE CODING MACHINE, are the exclusive property of THE CODING MACHINE.
                  The Developer renounces claiming any intellectual property rights relative to changes, adaptations, developments, evolutions, translations, transcriptions, and so on… or any intellectual property rights relative to the work bundling the Component, whatever the media, during the whole time the Component is protected.
                  For each Delivery, the Developer will provide THE CODING MACHINE with the latest version of the Component (source code and relevant resources), the technical documentation related to the Component, and all the elements needed to execute the Component.
                  The Developer will provide a source code understandable and documented enough so that an IT professional can use the given code to operate, maintain, adapt and extend functionalities in a future version.
                  The Developer is informed that the Component may be bundled with other Components developed by other Developers, by THE CODING MACHINE or by any other person. Therefore, the Developer is informed that the source code documentation quality is a critical part for THE CODING MACHINE to allow the request of the payment.
                  The rights aforementioned are yielded by the Developer to THE CODING MACHINE, including Component intermediate versions, for each Delivery.
                </p>
              
                        <h3> Article 11 Distinctive signs</h3>
              <p> Within the framework of operating the rights on the Component yielded to him in the Contract, THE CODING MACHINE may use any mark, brand, logo or any other distinctive sign it will choose to name all or part of the Component or any part of the Project including the Component. THE CODING MACHINE will be responsible for this naming.</p>

                        <h3> Article 12 Code protection</h3>
              <p>TCM can proceed on his own profit to the deposit of the component to the “Agence pour la Protection des Programmes (A.P.P)” or any other organism entitled 
              </p>
                        <h3> Article 13 Protection</h3>

              <p> The Developer guarantees to have the rights of intellectual property on the Component and thus is able to conclude the Contract.
                  The Developer guarantees that he did not integrate pre-existing programs for which he would not possess the necessary rights.
                  The Developer declares that there does not exist, to his knowledge, any lawsuit filed, relating to the Component.
                  The Developer guarantees TCM against any action or claim by a third party as for the property and/or the exploitation of the Component as defined in the present Contract. The Developer will compensate TCM for any direct and indirect damage related to any claim. The Developer is committed, for this reason, to deal with or to refund, the whole of the amount, expenses, legal fees, damages and costs which could be incurred or to which THE CODING MACHINE could be condemned due to these claims.
                  On the assumption of a negotiation or a legal mediation, the Developer recognizes that he will have to inform and obtain TCM’s agreement prior to the signature of any agreement with third parties.
                  Notwithstanding the other rights of THE CODING MACHINE, in the event of a conviction or a transaction resulting from a disagreement which prevent or prohibit the exploitation of whole or part of the Component, the Developer shall obtain to his own expenses the right of exploitation of the Component for TCM or proceed to any modification needed.
              </p>

                         <h3> Article 14 Cancellation</h3>
              <p>In the event the contractor does not fulfil his duties and after an e-mail stating the problem has remained unanswered for 2 working days, the Contract could be automatically cancelled. The contractor will send an email to confirm this cancellation. This cancellation will not presume of the damages to which TCM could claim because of the noted failures.
              In the case of delay of Delivery of more than 50 % of the initially agreed time and without reasonable justification on behalf of the Developer, the Contract will be terminated by fault of the Developer. The remaining payments will not be paid.
              The amounts paid by TCM to the Developer will be refunded. TCM will be able to retain any amount on the payment of all the other Components the Developer has to develop until complete refunding of payments.
              In case of the cancellation of the Contract, this cancellation will have no effect on the clauses which, by nature, stay active, especially assignment of intellectual property right on the Components developed, the warrantee, the engagement in term of non-competition and the confidentiality.
              Especially, the assigned rights defined in the “Intellectual Property Law”, will remain the asset of TCM. In particular, the clauses of the Contract will continue to apply for the execution of the licences granted by TCM to its customers before the date of cancellation of the Contract.
              The cancellation of the Contract, involves the transfer of the whole economic rights of intellectual property related to the Services provided and delivered to TCM by the Developer.
              </p>

                  <h2> 3.3 PRICE AND PAYMENTS TERMS </h2>

                        <h3> Article 15 Price </h3>
              
              <p> The price of the Component is indicated in the Detailed Conditions. The Contractors agree that this price includes the assignment of Intellectual property rights from the Developer to TCM. This is a fixed price contract. Especially, underestimating or overestimating the workload cannot impact on the price.
              In case of Delegations of skills, the amount of hours declared by the Developer has to be agreed with both part.
              The Developer is solely responsible for his own organization in order to deliver the component for the due date.
              </p>
                        <h3> Article 16 Payment time table </h3>
              
              <p> TCM will pay the Developer the fixed price defined in the Detailed Conditions when the following stages have been passed:
                  •	50% when the component has been delivered on time
                  •	50% when the verification period is over 
              </p>

                        <h3> Article 17 Payment methods and charges </h3>
              
              <p> There are 3 payment options: Paypal, Payoneer and Bank transfer. No other options will be used unless a signed agreement is established between the Developer and TCM
                  The fees of those transactions are at the charge of the Developer.
                  Once this has been allowed by TCM, the Developer must request payment of the amount displayed on the payment tab of the component.
              </p>

                         <h3> Article 18 Invoice's Agency </h3>
              
              <p> It is agreed that the Developer authorizes THE CODING MACHINE to publish the invoices for the provided Services. The Developer remains responsible for the truth of the information provided and keeps his legal obligations The Developer commits himself in particular to the following:
                •	to pay the taxes accordingly to his original country,
                •	to claim a copy of the invoice if he didn’t receive it,
              to communicate any changes in his identification.
              </p>

                  <h1> Part 4 PRIVACY STATMENT</h1>

              <p> THE CODING MACHINE has created this privacy statement in order to demonstrate our firm commitment to privacy. Our reputation and integrity is directly related to how closely we guard sensitive data such as personal information.
                  It is THE CODING MACHINE's policy to assure that Developers are selected and are treated during selection without regard to their race, religion, sex, color, philosophy or political beliefs.
                  Equally important is our concern for the safety of TCM members. Associated with this concern is our goal of creating a harmonious environment in which developers can communicate with one another regarding various project matters.
                  By creating a user account, and thereby becoming a User, you have effectively been made aware of our position on privacy matters.
                  Personally Identifiable Information is considered by THE CODING MACHINE to be Confidential, except for the username (the id) that is considered to be public.
              </p>     
              
                        <h3> Article 19 Data collected by THE CODING MACHINE</h3>

              <p> Visitors, Users and Developers are informed that when creating a user account, THE CODING MACHINE will collect personally identifiable information. The use of personally identifiable information is subject to the French law n° 78-17 of January 6th 1978 related to Information Technology, Files and Freedom, modified by law n° 2004-801 of August 6th 2004.
              THE CODING MACHINE systematically requests everybody interested in participating in THE CODING MACHINE projects to submit personally identifiable information when creating a user account. THE CODING MACHINE also asks the Developers to submit this information when they use TCM billing system for payment. This information includes:
              •	your real first and last name,
              •	email address
              •	home and mailing address
              •	username (id)
              •	company name if the developer is working on behalf of a company
              •	national registration number (the intracommunity VAT number for European citizens, or any other number identifying the legal structure that emits the bill if outside European community).
              The User has an access and modification right to any data provided by him and can request for deletion of any data relative to him by sending a mail to contact@thecodingmachine.com.
              </p>

                        <h3> Article 20 Use of the information</h3>

              <p> Personally, Identifiable Information is used for payment and contact purposes. We may contact you about a project you are or were involved in. We may also email you notices of changes in service or website design and/or invitations to work on a particular project. Personally, Identifiable Information is viewable only to the partners and a limited number of administrative employees of THE CODING MACHINE.
              When registering their user account, Visitors, Users and Developers can accept or decline to receive by e-mail newsletters from THE CODING MACHINE. The newsletters will contain information relative to THE CODING MACHINE services and activities. Users can at any time unsubscribe from this newsletter, either by modifying their user preferences on the Website or by clicking the appropriate link displayed in each newsletter.
              THE CODING MACHINE collects information like IP address, browser version, cookies and user activities on THE CODING MACHINE website.
              THE CODING MACHINE collects this data to conduct statistical usage surveys for its own purposes.
              Collected information allows THE CODING MACHINE to improve on existing ways of availing itself to TCM members.
              THE CODING MACHINE uses cookies to store a technical id if the user in the memory of its computer. This id enables the authentication of the User on the website. Cookies are intended to keep account of login information for the benefit of users. Personal information is not likely to be stored in those cookies.
              Should a user feel uncomfortable with our use of cookies, he has the option of modifying his/her internet navigation settings to prevent our use with the understanding that use of our site will be compromised
              </p>

                        <h3> Protection of personal informations</h3>
              <p> TCM does not rent or sell our users’ personally identifiable information to third parties without the User authorization.
                  HTTPS is currently utilized to guarantee the secure transfer of Personally Identifiable Data over Internet. As long as you are logged in, HTTPS guarantees the security of your data as it travels over the Internet.
                  Your engagement with THE CODING MACHINE assumes your concession to non-disclosure of project details to any unauthorized third parties.
                  In accordance with the French law n° 2004-575 of June 21, 2004 for confidence in the numerical economy:
                  •	The site is declared with the CNIL.
                  - All means are taken to ensure the confidentiality of information. In the case confidentiality is not respected because of acts of a third party (for example, piracy, or any other punishable act of a third party), TCM may take legal action.
              </p>

                  <h1> Part 5 DIVERSE STIPULATIONS</h1>

                        <h3> Article 21 Direct Access to clients</h3>
              <p> The Developers might learn, during the Development or Integration phase the identity of THE CODING MACHINE’s clients who requested the component.
                  The Developer will not compete directly or indirectly with THE CODING MACHINE in its activity of IT services reseller for these clients, during the whole duration of the contract plus an additional period of 24 months after the end of the guarantee period, in the country where the client is based and in France.
                  Furthermore, the Developer will not develop, request to develop, directly or indirectly, publish or sell any software program that might compete with the developed component or the developed project for the whole period of the contract plus an additional 24 months after the end of the guarantee phase, in the country where the client is based and in France, unless a written agreement is established between the Developer and THE CODING MACHINE.
              </p>

                        <h3> Article 22 Confidentiality </h3>
                       
              <p> Except for Requirements and for the General Terms and Conditions of Use, any other document and information transmitted to the Developer will be covered by confidentiality.
                  In particular, any data or document provided by THE CODING MACHINE for the Contract shall be considered Confidential, whether these documents are provided by email or electronically, including without restrictions any concept, industrial strategy, database, meeting report, memo, analysis, prototype, improvement, development, methodology, software… and whether this information is protected by intellectual rights or not, unless they are part of public domain.
                  However, confidentiality does not apply for data that:
                  •	Was known by the Developer (without confidentiality needs) before it has been transmitted by THE CODING MACHINE, or
                  •	Was publicly available or become publicly available if there are no breaches in the Developer engagements.
                  Confidentiality shall be kept during 2 years after the end of the guarantee phase and 2 years after the data has been provided to the Developer.
                  Information the Developers have access to for the development of their Component is confidential. It is forbidden to reveal these pieces of information to unauthorized thirds parties.
                  The Developer is not authorized to diffuse files, scripts, images or any other content developed by other Developers within the framework of the same Project.
                  </p>                
                      
                        <h3> Article 23 Independence of the Contractors</h3>
              <p> THE CODING MACHINE and the Developer act for their own account and responsibility. They preserve all the responsibility for their own acts, engagements, products or Services.
                  Each part to the Contract is an independent person who is liable for his act.
                  This contract is neither an association nor an agency, except for the billing agency given by the developer to THE CODING MACHINE. Each party shall not take any engagement in the name of the other party. A party shall not substitute to the other party.
              </p>


                        <h3> Article 24 Direct Act of God</h3>
              <p> No party shall be held liable for not doing a piece of work or providing this piece of work late in the event of an act of God as usually reckoned by French law (earthquake, flood, tornadoes …)
                  The act of God stops the contract during its whole life. However, if the act of God lasts for more than one month, the contract might be cancelled by any of the parties.
              </p>

                        <h3> Article 25 Contract completeness</h3>
              <p>The General Terms and Conditions of Use are a contract binding the User to THE CODING MACHINE. They cancel and replace any previous document and represent the whole rights and duties of THE CODING MACHINE and the User. Any modification to this Contract will      be effective immediately.
                  The General Terms and Conditions of Use, completed with Detailed Conditions and Component Requirements are a single contract binding the Developer to THE CODING MACHINE. They cancel and replace any previous agreements and represent the whole rights and duties of THE CODING MACHINE and the User.
                  These documents prevail on any communication between the Developer and THE CODING MACHINE relative to the aim of this Contract.
                  Modifications to this Contract will be made by adding written additional clauses. Modifications will be enforced when the two parties will sign the Contract, with a written mention that they wish to change the Contract.
              </p>

                        <h3> Article 26 Non-renonciation</h3>
              <p> If at any point a party to the Contract decides not to take action at a given time regarding the none completion of any obligation, this does not imply the surrender of this right in the future.
              </p>

                        <h3> Article 27 Partial invalidity</h3>
              <p> If one or many parts of the Contract are declared non-valid because of a law or a regulation or a definitive decision of a competent court, the non-valid parts will be ignored and the remaining parts will remain enforced.</p>


                        <h3> Article 28 Contract assignation</h3>
              <p>The Contract is signed intuitu personae, based on the acception of the component contract by the User for the chosen component. The Developer cannot assign the Contract to someone else, totally or partially, for a charge or for free, in any way (amalgamation,     spin-off…) without previous written consent of THE CODING MACHINE.</p>


                        <h3> Article 29 Titles</h3>
              <p> If there are conflicts when interpreting one of the article titles and the article body or between article titles, the titles shall be ignored and the body will be used for the interpretation.</p>


                        <h3> Article 30 Contract language</h3>
              <p> This document is translation into English of the “Conditions Générales d’Utilisation” (General Terms of Use). Another document in English is provided as the translation of the “Conditions Particulières” (Detailed Conditions). The English translations are provided for the convenience of non-French-speaking users. Regardless of this, only the original French-language version is legally binding</p>
                        
                        <h3> Article 31 Application Law</h3>
              <p> Conditions of use are governed, interpreted, and applied by French law.
              </p>
                        
                        <h3> Article 32 Juridiction</h3>
              <p> ISSUES OF VALIDITY, INTERPRETATION, EXECUTION, CANCELLATION, AND/OR ALL CONSEQUENCES ARISING OUT OF SUCH ISSUES WILL FALL SOLELY IN THE JURISDICTION OF THE COURTS IN PARIS, FRANCE. IF A LAWSUIT IS BROUGHT AND A COURT FINDS THE CODING MACHINE TO BE WITHOUT FAULT, THE PLAINTIFF(S) AGREE(S) TO REIMBURSE THE CODING MACHINE ALL EXPENSES ASSOCIATED WITH THE LITIGATION
              </p>
                     

          
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <b-form-group
              id="fieldset-address"
              label-for="input-address"
              :invalid-feedback="validationMsg.agreeTerms"
              :state="validateState('form.agreeTerms')"
            >
              <b-form-checkbox
                id="agree-terms"
                v-model="form.agreeTerms"
                name="form.agreeTerms"
                v-validate="{ required: false }"
                :state="validateState('form.agreeTerms')"
                value="accepted"
                unchecked-value="not_accepted"
                class="custom-control-label-check"
              >
                I acknowledge and agree to the terms and conditions
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
              id="fieldset-address"
              label-for="input-address"
              :invalid-feedback="validationMsg.agreeCoder"
              :state="validateState('form.agreeCoder')"
            >
              <b-form-checkbox
                id="agree-coder"
                v-model="form.agreeCoder"
                name="form.agreeCoder"
                v-validate="{ required: false }"
                :state="validateState('form.agreeCoder')"
                value="accepted"
                unchecked-value="not_accepted"
              >
                I am the coder and I am entitled to sign this contract
              </b-form-checkbox>
            </b-form-group>
            <b-form-invalid-feedback :state="isAcceptable">You have to agree terms, conditions and contract</b-form-invalid-feedback>
          </div>
        </div>
        <div class="row">
          <div class="form-section button-container ">
            <div class="row justify-content-center" v-if="hasError">
              <b-alert show variant="danger">{{error}}</b-alert>
            </div>
            <div class="row justify-content-around">
              <div class=" col-sm-8 d-flex justify-content-around">
                <b-button variant="danger" class="m-2" @click.prevent="onRefuse"><x-icon></x-icon>Refuse</b-button>
                <b-button variant="primary" class="m-2" type="submit" :disabled="!isAcceptable"><check-icon></check-icon>Accept</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </wrapper>
</template>

<script>
import {
  CheckIcon,
  XIcon,
} from "vue-feather-icons";
import {
  CODERS_COUNTRY_LIST
} from '../../../constants';

  export default {
    name: "TermsAndConditions",
    components: {
      CheckIcon,
      XIcon,
    },
    data: function() {
      const address = this.$store.getters['security/address'];
      return {
        form: {
          firstName: this.$store.getters['security/firstName'] || '',
          lastName: this.$store.getters['security/lastName'] || '',
          country: address ? address.country : '',
          city: address ? address.city : '',
          address: address ? address.address : '',
          accountAddress: this.$store.getters['security/accountAddress'] || '',
          agreeTerms: false,
          agreeCoder: false,
        },
        validationMsg: {
          firstName: 'Please input your first name.',
          lastName: 'Please input your last name.',
          country: 'Please select your country',
          city: 'Please input your city',
          address: 'Please input your address.',
          agreeTerms: 'You have to agree terms and conditions',
          agreeCoder: 'You have to agree this contract.',
        },
        countries: CODERS_COUNTRY_LIST.map(country => country.name),
      }
    },
    mounted() {
      if (!this.$store.getters['security/tokenVerified'] || !this.$store.getters['security/token']) {
        this.$store.dispatch('security/logout');
        this.$router.push({path: '/connection'});
      }
    },
    computed: {
      isAcceptable() {
        return this.form.agreeCoder === 'accepted' && this.form.agreeTerms === 'accepted';
      },
      isLoading() {
        return this.$store.getters['security/isLoading'];
      },
      hasError() {
        return this.$store.getters['security/hasError'];
      },
      error() {
        return this.$store.getters['security/error'];
      },
    },
    methods: {
      onSubmit: function(evt) {
        evt.preventDefault();
        this.$validator.validateAll()
          .then(() => {
            if (!this.errors.any()) {
              const coder = {
                token: this.$store.getters['security/token'],
                firstName: this.form.firstName,
                lastName: this.form.lastName,
                country: this.form.country,
                city: this.form.city,
                address: this.form.address,
              };
              this.$store.dispatch('security/confirmTermsAndConditions', {
                coder,
                callback: () => {
                  if (this.$store.getters['security/loginTerm']) {
                    this.$router.push({path: '/'});  
                  } else {
                    this.$router.push({path: '/reset-password'});
                  }
                }
              });
            }
          });
  		},
      onRefuse() {
        this.$store.dispatch('security/logout');
        this.$router.push({path: '/connection'});
      },
      validateState(ref) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.errors.has(ref);
        }
        return null;
      }
    }
  }
</script>
