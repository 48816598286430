<template>
    <coder-wrapper name="Coder">
        <dashboard-header title="Invoice">
            <template slot="actions">
                <button class="btn btn-primary px-3" @click="downloadInvoices()">
                    <arrow-down-icon></arrow-down-icon>
                    Download invoices
                </button>
            </template>
        </dashboard-header>
        <invoice/>
    </coder-wrapper>
</template>
<script>
  import Invoice from '../payer/invoice';
  import {mapState} from "vuex";
  import {ArrowDownIcon, FileTextIcon} from "vue-feather-icons";
  import moment from "moment";


  export default {
    components: {
      Invoice,
      ArrowDownIcon,
      FileTextIcon
    },
    name: "InvoiceCoder",
    computed: {
      ...mapState('invoice', [
        'idFilter',
        'coderFilter',
        'amountFilter',
        'dateFilter',
        'projectFilter',
      ]),
      startDate() {
        return this.dateFilter[0];
      },
      endDate() {
        return this.dateFilter[1];
      }
    },
    methods: {
      downloadInvoices() {
        window.open(`/api/download-invoices?id=${this.idFilter}&coder=${this.coderFilter}&amount=${this.amountFilter}&startDate=${this.getStartDate()}&endDate=${this.getEndDate()}&project=${this.projectFilter}`);
      },
      getStartDate() {
        return this.dateFilter[0] !== null ? moment(this.dateFilter[0]).toISOString() : '';
      },
      getEndDate() {
        return this.dateFilter[1] !== null ? moment(this.dateFilter[1]).toISOString() : '';
      }
    }

  }

</script>