<template>
  <div>
    <b-form @submit="onSavePayment">
      <div class="container">
        <div class="row mb-3 align-items-center" v-for="(payment, index) in payments" :key="index">
          <div class="col-md-8">
            <b-form-group id="fieldset-hours" label-cols="4" label-cols-lg="4" label="Total Hours" :label-for="`payment_hours_${index}`" :state="validateState(`payment_hours_${index}`)" :invalid-feedback="validationMsg.hours">
              <b-form-input :id="`payment_hours_${index}`" v-model="payments[index].hours" :name="`payment_hours_${index}`" class="px-2" v-validate="{ required: true, decimal: true }" :state="validateState(`payment_hours_${index}`)" placeholder="Enter Hours"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-2">
            ${{totalPrice(index)}}
          </div>
          <div class="col-md-2">
            <trash-2-icon class="trash-icon" @click="onRemovePayment(index)"></trash-2-icon>
          </div>
        </div>
        <b-button variant="primary" class="float-right m-2 add-payment-button" @click.prevent="onAddPayment">
          <plus-icon></plus-icon>
        </b-button>
      </div>
    </b-form>
    <div slot="modal-footer" class="w-100">
      <b-button type="submit" variant="primary" size="sm" class="float-right m-2" @click="onSavePayment">
        Save
      </b-button>
      <b-button size="sm" class="float-right m-2" @click.prevent="onCancelPayment">
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
  import {
    Trash2Icon,
    PlusIcon
  } from 'vue-feather-icons'
  
  export default {
    props: {
        rate: {
            type: Number,
            required: true
        },
        originalPayments: {
            type: Array,
            default: () => []
        },
        grandTotal: {
            type: Number,
            required: true
        }
    },
    components: {
      Trash2Icon,
      PlusIcon
    },
    name: 'PaymentDelegationModal',
    data: function() {
      return {
        payments: [{
          hours: this.initializeHours(),
          description: '',
        }],
        validationMsg: {
          hours: 'Please input hours',
          //description: 'Please input description',
        }
      }
    },
    methods: {
      onAddPayment() {
          this.payments = [...this.payments, {
              hours: this.initializeHours(),
              description: '',
          }];
      },
      onSavePayment(evt) {
        evt.preventDefault();
        this.$validator.validateAll()
          .then(() => {
            if (!this.errors.any()) {
              this.$emit('save', this.payments.map((payment) => {
                return {
                  amount: parseFloat(this.rate) * payment.hours,
                  hours: payment.hours
                  //description: payment.description,
                }
              }));
              this.payments = [{
                hours: 0,
                //description: '',
              }];
            }
          });
      },
      onCancelPayment() {
        this.$emit('cancel');
      },
      onRemovePayment(index) {
        const _clone = [...this.payments];
        _clone.splice(index, 1);
        this.payments = _clone;
      },
      validateState(ref) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.errors.has(ref)
        }
        return null
      },
      calculateTotalPayments(payments) {
        let totalPayments = 0;

        payments.forEach(payment => {
            totalPayments += parseFloat(payment.hours);
        });

        return totalPayments;
      },
      initializeHours() {
          if (!this.originalPayments.length >0) {
              return this.grandTotal
          } else {
              return 0
          }
      },
      totalPrice(index) {
        if (this.rate) {
          let total = parseFloat(this.rate) * this.payments[index].hours;
          total = parseFloat(total).toFixed(2)
          return !isNaN(total) ? total : 0;
        }
        return 0;
      }
    }
  };
</script>