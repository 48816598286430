<template>
    <div class="component-form">
        <b-form @submit="onSubmit">
            <div class="d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <b-form-group
                        id="fieldset-name"
                        label="Project Name *"
                        label-for="input-name"
                        :state="validateState('form.projectName')"
                        :invalid-feedback="validationMsg.projectName"
                    >
                      <b-form-input
                          id="input-name"
                          v-model="form.projectName"
                          class="form-control"
                          name="form.projectName"
                          placeholder="Enter project name"
                          v-validate="{ required: true }"
                          :disabled="(mode === 'coder' || checkEdit)"
                          :state="validateState('form.projectName')"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <b-form-group
                              id="fieldset-city"
                              label="Branch *"
                              label-for="input-city"
                              :invalid-feedback="validationMsg.cityId"
                              :state="validateState('form.cityId')"
                    >
                        <v-select :options="cities"
                                  label="name"
                                  placeholder="Select a branch"
                                  class="form-control form-list-project"
                                  v-model="city"
                                  id="input-city"
                                  :name="form.cityId"
                                  @input="handleSelectCity"
                                  :required="!form.cityId"
                                  v-validate="{ required: true }"
                                  :disabled="(mode === 'coder' || checkEdit)"
                                  :state="validateState('form.cityId')"/>
                      </b-form-group>
                  </div>

                  <div class="col-md-12">
                        <b-form-group
                                id="fieldset-name"
                                label="Component Name *"
                                label-for="input-name"
                                :state="validateState('form.name')"
                                :invalid-feedback="validationMsg.name"
                        >
                            <b-form-input
                                    id="input-name"
                                    v-model="form.name"
                                    class="form-control"
                                    name="form.name"
                                    placeholder="Enter component name"
                                    v-validate="{ required: true }"
                                    :disabled="(mode === 'coder' || checkEdit)"
                                    :state="validateState('form.name')"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-md-12">
                        <b-form-group
                                id="fieldset-coder"
                                label="Coder name *"
                                label-for="input-coder"
                                :state="validateState('form.coderId')"
                                :invalid-feedback="validationMsg.coderId"
                        >
                            <v-select :options="allCoders"
                                      label="firstName"
                                      placeholder="Select a coder"
                                      class="form-control form-list-project"
                                      v-model="coder"
                                      id="input-coder"
                                      name="form.coderId"
                                      :required="!coder"
                                      @input="handleSelectCoder"
                                      v-validate="{ required: true }"
                                      :disabled="(mode === 'coder' || checkEdit)"
                                      :state="validateState('form.coderId')">
                                <template slot="selected-option" slot-scope="option">
                                    {{ option.firstName }} {{ option.lastName }}
                                </template>
                                <template slot="option" slot-scope="option">
                                    {{ option.firstName }} {{ option.lastName }}
                                </template>
                            </v-select>
                            <!-- <b-form-select
                               v-model="form.coderId"
                               id="input-coder"
                               class="form-control"
                               name="form.coderId"
                               v-validate="{ required: true }"
                               :disabled="mode === 'coder'"
                               :state="validateState('form.coderId')"
                             >
                               <option :value="null">Select a coder</option>
                               <option v-for="(item,i) in allCoders" :key="i" :value="item.uuid">{{item.lastName}} {{item.firstName}}</option>
                             </b-form-select>-->
                        </b-form-group>
                    </div>
                    <div class="col-md-12" v-show="!isManager">
                        <b-form-group
                                id="fieldset-manager"
                                label="Manager name *"
                                label-for="input-manager"
                                :state="validateState('form.managerId')"
                                :invalid-feedback="validationMsg.managerId"
                        >
                            <v-select :options="managers"
                                      label="firstName"
                                      placeholder="Select a manager"
                                      class="form-control form-list-project"
                                      v-model="manager"
                                      id="input-manager"
                                      name="form.managerId"
                                      @input="handleSelectManager"
                                      :required="!manager"
                                      v-validate="{ required: !isManager }"
                                      :disabled="(mode === 'manager' || mode === 'coder')"
                                      :state="validateState('form.managerId')">
                                <template slot="selected-option" slot-scope="option">
                                    {{ option.firstName }} {{ option.lastName }}
                                </template>
                                <template slot="option" slot-scope="option">
                                    {{ option.firstName }} {{ option.lastName }}
                                </template>
                            </v-select>
                            <!-- <b-form-select
                                    v-model="form.managerId"
                                    id="input-manager"
                                    class="form-control"
                                    name="form.managerId"
                                    v-validate="{ required: true }"
                                    :disabled="(mode === 'manager' || mode === 'coder')"
                                    :state="validateState('form.managerId')"
                            >
                                <option :value="null">Select a manager</option>
                                <option v-for="(item,i) in managers" :key="i" :value="item.uuid">{{item.lastName}}
                                    {{item.firstName}}
                                </option>
                            </b-form-select> -->
                        </b-form-group>
                    </div>
                    <div class="col-md-12">
                        <b-form-group
                                id="fieldset-description"
                                label="Component Description"
                                label-for="input-description"
                        >
                            <b-form-textarea
                                    id="textarea"
                                    v-model="form.description"
                                    placeholder="Enter component description"
                                    rows="3"
                                    max-rows="6"
                                    :disabled="(mode === 'coder' || checkEdit)"
                            ></b-form-textarea>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <b-form-group
                                id="fieldset-start-date"
                                label="Start Date *"
                                label-for="input-start-date"
                                :state="validateState('form.startDate')"
                                :invalid-feedback="validationMsg.startDate"
                        >
                            <datepicker
                                    id="input-start-date"
                                    v-model="form.startDate"
                                    name="form.startDate"
                                    placeholder="Start Date"
                                    format="MM/dd/yyyy"
                                    bootstrap-styling
                                    v-validate="{ required: true }"
                                    :disabled="(mode === 'coder' || checkEdit)"
                                    :state="validateState('form.startDate')"
                                    aria-describedby="start-date-live-feedback"
                            ></datepicker>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <b-form-group
                                id="fieldset-end-date"
                                label="End Date *"
                                label-for="input-end-date"
                                :state="validateState('form.endDate')"
                                :invalid-feedback="validationMsg.endDate"
                        >
                            <datepicker
                                    id="input-end-date"
                                    v-model="form.endDate"
                                    name="form.endDate"
                                    placeholder="End Date"
                                    format="MM/dd/yyyy"
                                    bootstrap-styling
                                    v-validate="{ required: true }"
                                    :disabled="(mode === 'coder' || checkEdit)"
                                    :state="validateState('form.endDate')"
                                    aria-describedby="end-date-live-feedback"
                            ></datepicker>
                        </b-form-group>
                    </div>
                    <div class="col-md-12">
                        <b-form-group
                                id="dateValidate"
                                v-if="!validateDate() && form.startDate && form.endDate"
                        >{{this.validDatesMessage}}
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <div class="form-pills">
                            <div class="form-group">
                                <label class="d-block">Payment Type</label>
                                <div class="d-flex">
                                    <div class="form-check-item mr-2" v-for="(option,i) in paymentOptions" :key="i">
                                        <input type="radio" class="check-input" name="payment-type"
                                               :id="'payment-option-'+i" v-model="form.type" :value="option.value"
                                               :disabled="mode === 'coder' || mode === 'edit'"/>
                                        <label class="  d-fle form-check-label" :for="'payment-option-'+i">
                      <span class="form-control">
                        <inbox-icon v-show="option.text === STATUS_CODERS_DELEGATION_SKILLS" class="p-1"></inbox-icon>
                        <tag-icon v-show="option.text === STATUS_CODERS_FIXED_PRICE" class="p-1"></tag-icon>
                        {{option.text}}
                        </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6" v-show="form.type === STATUS_CODERS_FIXED_PRICE">
                        <b-form-group
                                id="fieldset-price"
                                label="Component Price *"
                                label-for="input-price"
                                :state="validateState('form.fixedPrice')"
                                :invalid-feedback="validationMsg.fixedPrice"
                        >
                            <b-input-group class="currency-addon">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{currency.symbol}}</span>
                                </div>
                                <b-form-input
                                        id="input-price"
                                        min="0"
                                        v-model="form.fixedPrice"
                                        name="form.fixedPrice"
                                        @change="form.hourRate=0"
                                        v-validate="{ required: form.type === STATUS_CODERS_FIXED_PRICE ,decimal: true}"
                                        :disabled="mode === 'coder' || mode === 'edit'"
                                        :state="validateState('form.fixedPrice')"
                                        :readonly="mode==='create' && (firstFixedPaymentStatus !== STATUS_CODERS_WAITING_PAYMENT || secondFixedPaymentStatus !== STATUS_CODERS_WAITING_PAYMENT)"
                                        placeholder="Enter component price"
                                ></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6" v-show="form.type === STATUS_CODERS_DELEGATION_SKILLS">
                        <b-form-group
                                id="fieldset-rate"
                                label="Component Hour Rate"
                                label-for="input-rate"
                                :state="validateState('form.hourRate')"
                                :invalid-feedback="validationMsg.hourRate"
                        >
                          <b-input-group class="currency-addon">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{currency.symbol}}</span>
                            </div>
                            <b-form-input
                                    id="input-rate"
                                    v-model="form.hourRate"
                                    @change="form.fixedPrice=0"
                                    name="form.hourRate"
                                    class="form-control"
                                    v-validate="{ required: form.type === STATUS_CODERS_DELEGATION_SKILLS , decimal: true}"
                                    :disabled="(mode === 'coder' || checkEdit)"
                                    :readonly="mode==='edit' || hourRateIsReadOnly"
                                    :state="validateState('form.hourRate')"
                                    placeholder="Enter component hour rate"
                            ></b-form-input>
                          </b-input-group>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <b-form-group
                                id="fieldset-non-billable-hours"
                                label="Component Non Billable Hours *"
                                label-for="input-non-billable-hours"
                                :state="validateState('form.nonBillableHours')"
                                :invalid-feedback="validationMsg.nonBillableHours"
                        >
                            <b-form-input
                                    id="input-non-billable-hours"
                                    min="0"
                                    v-model="form.nonBillableHours"
                                    name="form.nonbillableHours"
                                    @change="form.nonBillableHours=0"
                                    :state="validateState('form.nonBillableHours')"
                                    :disabled="true"
                                    :readonly="true"
                                    placeholder="Enter component non billable hours"
                                    :class="{ 'input-alert': form.nonBillableHours === 0 }"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <b-form-group
                                id="fieldset-billable-hours"
                                label="Component Billable Hours *"
                                label-for="input-billable-hours"
                                :state="validateState('form.billableHours')"
                                :invalid-feedback="validationMsg.billableHours"
                        >
                            <b-form-input
                                    id="input-non-billable-hours"
                                    min="0"
                                    v-model="form.billableHours"
                                    name="form.billableHours"
                                    @change="form.billableHours=0"
                                    :disabled="true"
                                    :state="validateState('form.billableHours')"
                                    :readonly="true"
                                    placeholder="Enter component billable hours"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <b-form-group
                                id="fieldset-grand-total"
                                label="Component grand total *"
                                label-for="input-grand-total"
                                :state="validateState('form.grandTotal')"
                                :invalid-feedback="validationMsg.grandTotal"
                        >
                            <b-form-input
                                    id="input-grand-total"
                                    min="0"
                                    v-model="form.grandTotal"
                                    name="form.grandTotal"
                                    @change="form.grandTotal=0"
                                    :disabled="true"
                                    :state="validateState('form.grandTotal')"
                                    :readonly="true"
                                    placeholder="Enter component grand total"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <component-payment :key="paymentsUpdtIdx" :symbol="currency.symbol" v-on:add="onAddPayment"
                                       v-on:delete="onDeletePayment" v-on:remove="onRemovePayment"
                                       v-on:remove-fixed="onRemoveFixedPayment" v-on:request="onRequestPayment"
                                       v-on:allow="onAllowPayment" v-on:setFixedPaymentStatus="setFixedPaymentStatus"
                                       v-on:setDelegationSkillsPaymentStatus="setDelegationSkillsPaymentStatus"
                                       :payments="originalPayments" :loading="isLoadingPayments" :mode="mode"
                                       :formType="form.type"
                                       :checkEdit="checkEdit"
                                       :formFixedPrice="form.type===STATUS_CODERS_FIXED_PRICE ? form.fixedPrice : 0"
                                       :formHourRate="form.type===STATUS_CODERS_DELEGATION_SKILLS ? form.hourRate : 0"></component-payment>
                </div>
                <b-alert v-if="!isLoading"
                    :show="dismissCountDown"
                    dismissible
                    fade
                    variant="success"
                    @dismiss-count-down="countDownChanged"
                >
                  Component Saved
                </b-alert>
                <div v-if="isLoading" class="text-center py-4">
                    <b-spinner label="Spinning"></b-spinner>
                </div>
                <div class="form-section button-container d-flex justify-content-center " v-if="mode !== 'coder'">
                    <b-button variant="secondary" class="px-5 py-2 m-2" :disabled="isLoading || isLoadingPayments"
                              @click.prevent="onCancel">
                        <x-icon></x-icon>
                        Cancel
                    </b-button>
                    <b-button type="submit" variant="primary" class="px-5 py-2 m-2"
                              :disabled="isLoading || isLoadingPayments" v-if="mode === 'create'">
                        <plus-icon></plus-icon>
                        Create Component
                    </b-button>
                    <b-button type="submit" variant="primary" class="px-5 py-2 m-2"
                              :disabled="isLoading || isLoadingPayments" v-if="(!checkEdit && mode === 'edit')">
                        <plus-icon></plus-icon>
                        Save Component
                    </b-button>
                  <b-button variant="primary" class="px-5 py-2 m-2" v-if="isAdmin && component && component.status !== 'Finished'"
                            @click.prevent="syncWithZoho">
                    <plus-icon></plus-icon>
                    Sync With Zoho
                  </b-button>
                    <b-button v-if="isAdmin && component && component.status === 'Finished'" variant="success"
                              class="px-5 py-2 m-2" @click.prevent="onArchive">
                        <archive-icon></archive-icon>
                        Archive
                    </b-button>
                  <b-button v-if="isAdmin && component && component.status === 'Created'" variant="danger"
                            class="px-5 py-2 m-2" @click.prevent="onDelete">
                    <trash-2-icon></trash-2-icon>
                    Delete
                  </b-button>
                </div>
            </div>
        </b-form>
       
        <b-modal
                id="modal-payment"
                ref="modal-payment"
                centered
                title="Create Payment"
                hide-footer
        >
            <payment-fixed-modal v-if="isFixed(form)" v-on:save="onSavePayment"
                                 v-on:cancel="onCancelPayment"></payment-fixed-modal>
            <payment-delegation-modal v-if="isHourly(form)" v-on:save="onSavePayment" v-on:cancel="onCancelPayment"
                                      :rate="form.hourRate" :grand-total="form.grandTotal" :original-payments="originalPayments"></payment-delegation-modal>
        </b-modal>
    </div>
</template>

<style>
  .input-alert {
    border-color: red !important;
  }
</style>

<script>
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
  import moment from 'moment';
  import Datepicker from 'vuejs-datepicker';
  import {PlusIcon, XIcon, InboxIcon, TagIcon, ArchiveIcon, Trash2Icon} from 'vue-feather-icons';
  import ComponentPayment from './ComponentPayment';
  import PaymentFixedModal from './PaymentFixedModal';
  import PaymentDelegationModal from './PaymentDelegationModal';

  import {
    CODERS_WAITING_PAYMENT,
    CODERS_ALLOWED_PAYMENT,
    CODERS_REQUESTED_PAYMENT,
    CODERS_PAID_PAYMENT,
    CODERS_FIXED_PRICE,
    CODERS_DELEGATION_SKILLS,
    ARCHIVED_COMPONENT,
    DELETED_COMPONENT,
    ENTER_KEY_CODE,
    DOWN_ARROW_KEY_CODE,
    UP_ARROW_KEY_CODE
  } from '../../constants';

  export default {
    name: "ComponentForm",
    props: ['component', 'isLoading', 'error', 'mode'],
    components: {
      ComponentPayment,
      PaymentFixedModal,
      PaymentDelegationModal,
      Datepicker,
      PlusIcon,
      XIcon,
      InboxIcon,
      TagIcon,
      ArchiveIcon,
      Trash2Icon,
      'v-select': vSelect,
    },
    computed: {
      endDateRestrict() {
        to: new Date(this.form.startDate)
      },
      cities() {
        return this.$store.getters['components/getCities'];
      },
      allCoders() {
        return this.$store.getters['coders/getAllCoders'];
      },
      managers() {
        return this.$store.getters['managers/getManagers'];
      },
      getPayments() {
        return this.originalPayments;
      },
      isLoadingPayments() {
        return this.$store.getters['payments/isLoading'];
      },
      isManager: function () {
        return this.$store.getters['security/hasRole']('project_manager');
      },
      getManagerEmail: function () {
        return this.$store.getters['security/accountAddress'];
      },
      STATUS_CODERS_WAITING_PAYMENT: function () {
        return CODERS_WAITING_PAYMENT;
      },
      STATUS_CODERS_DELEGATION_SKILLS: function () {
        return CODERS_DELEGATION_SKILLS;
      },
      STATUS_CODERS_FIXED_PRICE: function () {
        return CODERS_FIXED_PRICE;
      },
      isAdmin() {
        const role = this.$store.getters['security/getRole'];
        return role === 'admin';
      },
      checkEdit(){
        return !this.isAdmin && this.currentUserId !== this.form.managerId;
      },
      currentUserId: function(){
        return this.$store.getters['security/id'];
      },
      invalidComment() {
        if (!this.feedback.protectedComment.trim()) {
          return 'This field is required.';
        } else {
          return '';
        }
      },
      isComment() {
        if (this.feedback.protectedComment.trim()) {
          return true;
        } else {
          return false;
        }
      }
    },
    data: function () {
      return {
        currencies: [
          {symbol: '€', label: 'EUR'},
          {symbol: '$', label: 'USD'}
        ],
        firstFixedPaymentStatus: CODERS_WAITING_PAYMENT,
        secondFixedPaymentStatus: CODERS_WAITING_PAYMENT,
        validDates: false,
        validDatesMessage: 'End date should be greater than start date.',
        paymentsUpdtIdx: '',
        newDelegationSkillsPaymentsStatus: [],
        hourRateIsReadOnly: false,
        paymentOptions: [
          {text: CODERS_FIXED_PRICE, value: CODERS_FIXED_PRICE,},
          {text: CODERS_DELEGATION_SKILLS, value: CODERS_DELEGATION_SKILLS,},
        ],
        fixedPayments: [{
          amount: 0,
          description: 'Initial Payment',
          required: true,
        }, {
          amount: 0,
          description: 'Full Payment',
          required: true,
        }],
        currency: null,
        originalPayments: [],
        validationMsg: {
          cityId: 'Please select a branch',
          name: 'Please input name.',
          coderId: 'Please select a coder.',
          managerId: 'Please select a manager.',
          startDate: 'Please select start date of the component.',
          endDate: 'Please select end date of the component.',
          fixedPrice: 'Please only input numbers.',
          hourRate: 'Please only input numbers.',
          type: 'Please select payment type.',
        },
        coder: null,
        manager: null,
        paymentId: '',
        form: {},
        feedback: {
          for: '',
          privateComment: '',
          protectedComment: '',
          disableBtn: false
        },
        dismissSecs: 2,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        city: null,
        nonBillableHours: 0,
        billableHours: 0,
        grandTotal: 0,
      }
    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showSuccessAlert() {
        this.dismissCountDown = this.dismissSecs
      },
      getCoderId(component) {
        if (component && component.coder) {
          return component.coder.uuid;
        }
        return null;
      },
      buildForm() {
        this.coder = this.component ? this.component.coder : null;
        this.currency = this.component ? this.component.currency === 'USD'
                            ? {symbol: '$', label: 'USD'}
                            : {symbol: '€', label: 'EUR'}
                          : {symbol: '$', label: 'USD'}
        this.manager = this.component ? this.component.manager : null;
        this.city = this.component ? this.component.city : null;
        this.form = {
          name: this.component ? this.component.name : '',
          coderId: this.getCoderId(this.component),
          managerId: this.component && this.component.manager ? this.component.manager.uuid : null,
          description: this.component ? this.component.description : '',
          startDate: this.component && this.component.startDate ? moment(this.component.startDate).format('MM/DD/YYYY') : '',
          endDate: this.component && this.component.endDate ? moment(this.component.endDate).format('MM/DD/YYYY') : '',
          fixedPrice: this.component ? this.component.fixedPrice : undefined,
          hourRate: this.component && this.component.coder ? this.component.coder.hourlyRate : undefined,
          type: this.component ? this.component.type : CODERS_FIXED_PRICE,
          currency: this.currency.label,
          cityId: this.component && this.component.city ? this.component.city.id : 1,
          projectName: this.component ? this.component.projectName : undefined,
          nonBillableHours: this.component ? this.component.nonBillableHours : 0,
          billableHours: this.component ? this.component.billableHours : 0,
          grandTotal: this.component ? this.component.grandTotal : 0,
        }
      },
      syncWithZoho() {
          let componentId = this.component.uuid;

          this.$store.dispatch('components/syncComponentWithZoho', {
              componentId,
              callback: (res) => {
                  this.form.billableHours = res.billableHours;
                  this.form.nonBillableHours = res.nonBillableHours;
                  this.form.grandTotal = res.grandTotal;
                  this.form.description = res.description
              }
          });
      },
      onSubmit: function (evt) {
        evt.preventDefault();
        this.$validator.validateAll()
         .then(() => {
           if (!this.errors.any() && this.validDates) {
             if (this.form.fixedPrice) {
               this.form.fixedPrice = parseFloat(this.form.fixedPrice);
             }
             if (this.form.hourRate) {
               this.form.dayRate = parseFloat(this.form.hourRate) * 8;
             }
	     this.form.cityId = parseInt(this.form.cityId)
             let paymentsToSave = this.getPayments;
             if (this.mode === 'create' && this.form.fixedPrice !== undefined && this.form.fixedPrice > 0) {
               paymentsToSave = [];
               paymentsToSave.push({
                 amount: this.form.fixedPrice / 2,
                 requestedDate: null,
                 status: this.firstFixedPaymentStatus
               });
               paymentsToSave.push({
                 amount: this.form.fixedPrice / 2,
                 requestedDate: null,
                 status: this.secondFixedPaymentStatus
               });
             }
             this.$emit('save', {component: this.form, payments: paymentsToSave});
             if(this.mode !== 'create') {
               this.showSuccessAlert()
             }
           }
         });
      },
      onCancel: function () {
        this.$router.push({path: '/dashboard'});
      },
      onAddPayment: function () {
        this.$refs['modal-payment'].show();
      },
      onRemovePayment: function (index) {
        let _index = index;
        const _clone = [...this.payments];
        _clone.splice(_index, 1);
        this.payments = _clone;
      },
      onRemoveFixedPayment: function (index) {
        const _clone = [...this.fixedPayments];
        _clone.splice(index, 1);
        this.fixedPayments = _clone;
      },
      onRequestPayment: function (paymentId) {
        this.feedback = {
          for: 'manager',
          privateComment: '',
          protectedComment: '',
          disableBtn: false
        };
        this.paymentId = paymentId;

        store.dispatch('payments/updatePaymentStatus', {
          paymentId,
          status: CODERS_REQUESTED_PAYMENT,
          callback: function () {
            window.location.reload();
          }
        });
      },
      onDeletePayment: function (paymentId) {
        let email = this.getManagerEmail;
        let componentId = this.component.uuid
        this.$store.dispatch('payments/deletePayment', {
          paymentId,
          email,
          componentId,
          callback: function () {
          },
        }).then(res => this.$store.dispatch('payments/getPayments', this.component.uuid).then(res => this.originalPayments = this.$store.getters['payments/getPayments']))
      },
      onAllowPayment: function (paymentId) {
        this.feedback = {
          for: 'coder',
          privateComment: '',
          protectedComment: '',
          disableBtn: false
        };
        this.paymentId = paymentId;

        store.dispatch('payments/updatePaymentStatus', {
          paymentId,
          status: CODERS_ALLOWED_PAYMENT,
          callback: function () {
            window.location.reload();
          }
        });
      },
      saveFeedback() {
        if (!this.isComment) {
          return;
        }
        this.feedback.disableBtn = true;
        let store = this.$store;
        let feedbackModal = this.$refs['modal-feedback'];
        let target = this.feedback.for;

        let paymentId = this.paymentId;
        let protectedManagerComment = target === 'manager' ? this.feedback.protectedComment : '';
        let privateManagerComment = target === 'manager' ? this.feedback.privateComment : '';
        let protectedCoderComment = target === 'coder' ? this.feedback.protectedComment : '';
        let privateCoderComment = target === 'coder' ? this.feedback.privateComment : '';
        this.$store.dispatch('payments/paymentFeedback', {
          paymentId,
          protectedManagerComment,
          privateManagerComment,
          protectedCoderComment,
          privateCoderComment,
          callback: function () {
            store.dispatch('payments/updatePaymentStatus', {
              paymentId,
              status: target === 'coder' ? CODERS_ALLOWED_PAYMENT : CODERS_REQUESTED_PAYMENT,
              callback: function () {
                feedbackModal.hide();
                window.location.reload();
              }
            });
          }
        });
      },
      onCancelPayment: function () {
        this.$refs['modal-payment'].hide();
      },
      onSavePayment: function (payments) {
        this.originalPayments = [...this.originalPayments, ...payments];
        this.$refs['modal-payment'].hide();
        this.hourRateIsReadOnly = true;
      },
      onArchive() {
        const vm = this;
        this.$store.dispatch('coders/updateCoderComponentStatus', {
          componentId: this.component.uuid,
          status: ARCHIVED_COMPONENT,
          callback: function () {
            vm.$router.push({path: '/dashboard'});
          }
        });
      },
      onDelete() {
        const vm = this;
        this.$store.dispatch('coders/updateCoderComponentStatus', {
          componentId: this.component.uuid,
          status: DELETED_COMPONENT,
          callback: function () {
            vm.$router.push({path: '/dashboard'});
          }
        });
      },
      validateDate() {
        if (this.form.startDate && this.form.endDate) {
          if (moment(this.form.endDate, 'MM/DD/YYYY').isSameOrAfter(moment(this.form.startDate, 'MM/DD/YYYY'))) {
            this.validDates = true
          } else {
            this.validDates = false
          }
          return this.validDates
        }
      },
      validateState(ref) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.errors.has(ref);
        }
        return null;
      },
      isFixed(form) {
        return form.type === CODERS_FIXED_PRICE;
      },
      isHourly(form) {
        return form.type === CODERS_DELEGATION_SKILLS;
      },
      setFixedPaymentStatus(idx, status) {
        if (idx === 1) {
          this.firstFixedPaymentStatus = status;
        }
        if (idx === 2) {
          this.secondFixedPaymentStatus = status;
        }
      },
      setDelegationSkillsPaymentStatus(idx, status) {
        this.originalPayments[idx].requestedDate = null;
        this.originalPayments[idx].status = status;
        this.paymentsUpdtIdx++;
      },
      setSelectedCurrency(value) {
        this.currency = value;
      },
      handleSelectCoder(value) {
        if(!value) {
          return;
        }
        this.currency = {symbol: '$', label: 'USD'}
        if (value.currency === 'EUR') {
          this.currency = {symbol: '€', label: 'EUR'}
        }
        this.form.currency = this.currency.label
        this.form.coderId = value.uuid
        this.form.hourRate = value.hourlyRate

      },
      handleSelectManager(manager) {
        this.form.managerId = manager.uuid;
      },
      handleSelectCity(city) {
        this.form.cityId = parseInt(city.id);
      },
      fetchComponentById(uuid) {
        return this.$store.dispatch('payments/getPayments', uuid).then(res => {
          this.originalPayments = this.$store.getters['payments/getPayments'];
          this.buildForm();
          return res;
        });
      }
    },
    created() {
      this.buildForm();
      this.$store.dispatch('coders/getAllCoders');
      this.$store.dispatch('managers/getManagers');
      this.$store.dispatch('components/getCities');
      if (this.mode === 'edit' || this.mode === 'coder') {
        if (this.component.uuid) {
          this.fetchComponentById(this.component.uuid);
        }
      }
      if (this.$store.getters['security/hasRole']('project_manager')) {
        this.form.managerId = this.$store.getters['security/id'];
      }
    },
    mounted() {
      if (this.$store.getters['security/hasRole']('project_manager')) {
        this.form.managerId = this.$store.getters['security/id'];
      }
    }
  }
</script>
