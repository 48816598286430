<template>
	<div class="coder-search d-flex align-items-center justify-content-between px-3">
		<div class="form-group d-flex align-items-center border-bottom mr-3">
			<search-icon class="search-icon"></search-icon>
			<input class="form-control form-control-sm" placeholder="Search a coder" type="text" v-model="coder" @keyup="onChange"/>
		</div>
		<div class="form-group d-flex align-items-center border-bottom ml-3">
			<search-icon class="search-icon"></search-icon>
			<input class="form-control form-control-sm" placeholder="Search a component" type="text" v-model="component" @keyup="onChange"/>
		</div>
		<div v-if="page === 'Components'" class="form-group d-flex align-items-center border-bottom ml-3">
			<search-icon class="search-icon"></search-icon>
			<input class="form-control form-control-sm" placeholder="Search a project" type="text" v-model="projectName" @keyup="onChange"/>
		</div>
		<div v-if="page === 'Components'" class="form-group d-flex align-items-center border-bottom ml-3">
			<search-icon class="search-icon"></search-icon>
			<input class="form-control form-control-sm" placeholder="Search a city" type="text" v-model="cityId" @keyup="onChange"/>
		</div>
		<!-- <div class="sort-wrapper d-flex align-items-center">
			<filter-icon class="filter-icon"></filter-icon>
			<span>Sort By</span>
		</div> -->
	</div>
</template>

<script>
import {
	FilterIcon,
	SearchIcon
} from 'vue-feather-icons';

export default {
	name: 'ComponentFilter',
	components: {
		FilterIcon,
		SearchIcon
	},
	props: ['keyword', 'sort_by', 'page'],
	data: function() {
		return {
			coder: '',
			component: '',
			projectName: '',
			cityId: '',
		}
	},
	computed: {},
	methods: {
		onChange: function() {
			this.$emit('search', {
				coder: this.coder,
				component: this.component,
				projectName: this.projectName,
				cityId: this.cityId,
			});
		}
	},
	created() {
		let storedFilter;
		if (this.page === 'Dashboard') {
			storedFilter = this.$store.getters['components/dashboardFilter'];
		}
		if (this.page === 'Components') {
			storedFilter = this.$store.getters['components/componentsFilter'];
		}
		if (storedFilter) {
			this.coder = storedFilter.coder;
			this.component = storedFilter.component;
			this.projectId = storedFilter.projectId;
			this.cityId = storedFilter.cityId;
		}
 	},
	mounted() {},
};
</script>
