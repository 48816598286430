var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: "metric d-flex metric-" + _vm.metric.key },
    [
      _c(_vm.metric.icon, { tag: "component", staticClass: "icon" }),
      _vm._v(" "),
      _c("div", { staticClass: "metric-data heigh" }, [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "my-0" },
              [_c("b-spinner", { attrs: { label: "Spinning" } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isLoading
          ? _c("h1", { staticClass: "my-0" }, [
              _vm._v(_vm._s(_vm.metric.value)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "font-semibold font-alt-light" }, [
          _vm._v(_vm._s(_vm.metric.description)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }