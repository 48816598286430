<template>
  <admin-wrapper>
    <router-view></router-view>
  </admin-wrapper>
</template>
<script>
export default {
  name: 'AdminContainer',
  components: {
  },
}
</script>