export default {
  namespaced: true,
  state: {
    idFilter: '',
    coderFilter: '',
    amountFilter: null,
    dateFilter: [null, null],
    projectFilter: '',
    currentPage: 1,
    filterOffset: 0
  },
  mutations: {
    ['SET_ID_FILTER'](state, idFilter) {
      state.filterOffset = 0;
      state.currentPage = 1;
      state.idFilter = idFilter;
    },
    ['SET_CODER_FILTER'](state, coderFilter) {
      state.filterOffset = 0;
      state.currentPage = 1;
      state.coderFilter = coderFilter;
    },
    ['SET_AMOUNT_FILTER'](state, amountFilter) {
      state.filterOffset = 0;
      state.currentPage = 1;
      state.amountFilter = amountFilter;
    },
    ['SET_DATE_FILTER'](state, dateFilter) {
      state.filterOffset = 0;
      state.currentPage = 1;
      state.dateFilter = dateFilter;
    },
    ['SET_PROJECT_FILTER'](state, projectFilter) {
      state.filterOffset = 0;
      state.currentPage = 1;
      state.projectFilter = projectFilter;
    },
    ['SET_FILTER_OFFSET'](state, offset) {
      state.filterOffset = offset;
    },
    ['SET_CURRENT_PAGE'](state, page) {
      state.currentPage = page;
    }
  }
};
