import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import SecurityModule from './security';
import ComponentsModule from './components';
import CodersModule from './coders';
import ManagersModule from './managers';
import PaymentsModule from './payments';
import NotificationsModule from './notifications';
import MetricsModule from './metrics';
import Invoice from './invoice';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    security: SecurityModule,
    components: ComponentsModule,
    coders: CodersModule,
    managers: ManagersModule,
    payments: PaymentsModule,
    notifications: NotificationsModule,
    metrics: MetricsModule,
    invoice: Invoice,
  },
  plugins: [createPersistedState()]
});

window.store = store; // debugging purpose

export default store;
