<template>
    <div class="admin-menu d-flex flex-column">
        <div class="nav mt-5">
            <ul class="">
                <li class="active">
                    <router-link :to="{name:'coder'}" class="d-block text-center">
                        <activity-icon class="d-inline-block menu-icon"></activity-icon>
                        <span class="d-block">Dashboard</span>
                    </router-link>
                </li>
                <li class="active">
                    <router-link :to="{name:'InvoiceCoder'}" class="d-block text-center">
                        <dollar-sign-icon class="d-inline-block menu-icon"></dollar-sign-icon>
                        <span class="d-block">Invoice</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="nav nav-auto">
            <ul class="social-icons-side">
                <li><a href="https://www.facebook.com/the.coding.machine/" target="_blank" title="Facebook"><facebook-icon></facebook-icon></a></li>
                <li><a href="https://github.com/thecodingmachine" target="_blank" title="Github"><github-icon></github-icon></a></li>
                <li><a href="https://www.instagram.com/inside_thecodingmachine/" target="_blank" title="Instagram"><instagram-icon></instagram-icon></a></li>
                <li><a href="https://www.linkedin.com/company/the-coding-machine/" target="_blank" title="LinkedIn"><linkedin-icon></linkedin-icon></a></li>
                <li><a href="https://twitter.com/coding_machine" target="_blank" title="Twitter"><twitter-icon></twitter-icon></a></li>
                <li><a href="https://www.youtube.com/channel/UCw6p2FZ2q2Fj1iGFcIQ_73w" target="_blank" title="YouTube"><youtube-icon></youtube-icon></a></li>
                <li><a class="font-bold">{{ version }}</a></li>
            </ul>
        </div>
    </div>
</template>
<script>
import { ActivityIcon, DollarSignIcon, UsersIcon, BarChart2Icon, XIcon } from 'vue-feather-icons'
import PayerIcon from '../icons/PayerIcon';
import {
    FacebookIcon,
    InstagramIcon,
    GithubIcon,
    YoutubeIcon,
    LinkedinIcon,
    TwitterIcon,
} from 'vue-feather-icons'

export default {
    name:'AdminMenu',
    components:{
        ActivityIcon,
        DollarSignIcon,
        UsersIcon,
        BarChart2Icon,
        XIcon,
        PayerIcon,
        FacebookIcon,
        InstagramIcon,
        GithubIcon,
        YoutubeIcon,
        LinkedinIcon,
        TwitterIcon
     },
    computed: {
      version: function() {
        return this.$store.getters["security/version"];
      },
    }
}
</script>
