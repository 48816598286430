<template>
    <div class="admin-menu d-flex flex-column">
        <!-- <span class="d-block text-right my-2 p-2">
            <x-icon class="d-inline-block"></x-icon>
        </span> -->
        <div class="nav mt-5">
            <ul class="">
                <li class="active">
                    <router-link :to="{name:'admin'}" class="d-block text-center">
                        <activity-icon class="d-inline-block menu-icon"></activity-icon>
                        <span class="d-block">Dashboard</span>
                    </router-link>
                </li>
                <li v-if="isAdmin || isManager">
                    <router-link :to="{name:'dashboardUsers'}" class="d-block text-center">
                        <users-icon class="d-inline-block menu-icon"></users-icon>
                        <span class="d-block " >Users</span>
                    </router-link>
                </li>
                <li v-if="isAdmin">
                    <router-link :to="{name:'payment'}" class="d-block text-center">
                        <payer-icon class="d-inline-block menu-icon"></payer-icon>
                        <span class="d-block">Payer Mode</span>
                    </router-link>
                </li>
                <li v-if="isAdmin || isManager">
                    <router-link :to="{name:'dashboardComponents'}" class="d-block text-center">
                        <box-icon class="d-inline-block menu-icon"></box-icon>
                        <span class="d-block">Components</span>
                    </router-link>
                </li>
            </ul>
        </div> 
        <div class="nav nav-auto">
            <ul class="social-icons-side" v-if="!isManager">
                <li><a href="https://www.facebook.com/the.coding.machine/" target="_blank" title="Facebook"><facebook-icon></facebook-icon></a></li>
                <li><a href="https://github.com/thecodingmachine" target="_blank" title="Github"><github-icon></github-icon></a></li>
                <li><a href="https://www.instagram.com/inside_thecodingmachine/" target="_blank" title="Instagram"><instagram-icon></instagram-icon></a></li>
                <li><a href="https://www.linkedin.com/company/the-coding-machine/" target="_blank" title="LinkedIn"><linkedin-icon></linkedin-icon></a></li>
                <li><a href="https://twitter.com/coding_machine" target="_blank" title="Twitter"><twitter-icon></twitter-icon></a></li>
                <li><a href="https://www.youtube.com/channel/UCw6p2FZ2q2Fj1iGFcIQ_73w" target="_blank" title="YouTube"><youtube-icon></youtube-icon></a></li>
                <li><a class="font-bold">{{ version }}</a></li>
            </ul>
        </div>
    </div>
</template>
<script>
import { ActivityIcon, DollarSignIcon, UsersIcon, BarChart2Icon, XIcon, BoxIcon } from 'vue-feather-icons'
import PayerIcon from '../icons/PayerIcon';
import {
    FacebookIcon,
    InstagramIcon,
    GithubIcon,
    YoutubeIcon,
    LinkedinIcon,
    TwitterIcon
} from 'vue-feather-icons'

export default {
    name:'AdminMenu',
    components:{ 
        ActivityIcon, 
        DollarSignIcon, 
        UsersIcon, 
        BarChart2Icon, 
        XIcon,
        PayerIcon,
        FacebookIcon,
        InstagramIcon,
        GithubIcon,
        YoutubeIcon,
        LinkedinIcon,
        TwitterIcon,
        BoxIcon
    },

    computed: {
        isAdmin: function(){
            return this.$store.getters['security/hasRole']('admin');
        },
        isManager: function() {
            return this.$store.getters['security/hasRole']('project_manager');
        },
        version: function() {
            return this.$store.getters["security/version"];
        },
    }
}
</script>
