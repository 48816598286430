<template>
    <div>
        <div class="row d-flex justify-content-around m-0" style="width: 100%;">
            <coders-filter class="col-6" v-on:search="onSearchCoder"></coders-filter>
            <city-filter class="col-6" v-on:search="onSearchCity"></city-filter>
        </div>
        <div class="admin-table mt-4 mb-5">
            <ApolloQuery
                    :query="require('../../../graphql/coder/query/payerCoders.gql')"
                        :variables="{ coder: coderFilter, city: cityFilter }"
                    :options="{notifyOnNetworkStatusChange: true}"
            >
                <template v-slot="{ result: { loading, error, data } }">
                    <div v-if="loading" class="text-center py-4">
                        <b-spinner label="Spinning"></b-spinner>
                    </div>
                    <div v-if="error">An error occured</div>
                    <div class="admin-accordions" v-if="!loading">
                        <payer-accordion
                                v-for="(coder, index) in data.payerCoders.items"
                                :coder="coder"
                                :key="componentKey+'-'+index"
                                v-if="coder.payments.items.length"
                                v-on:updateTotalPayment="updateTotalPayment"
                                :coderToPay="coderToPay"
                                :selectedPayments="selectedPayments"
                                :isPayer="isPayer"
                        ></payer-accordion>
                        <div class="user-table admin-dash-table" v-if="!data.payerCoders.items.length"><h6>No Requested Payments</h6></div>
                    </div>
                    <nav v-if="!loading && isPayer" :key="componentKey" class="navbar fixed-bottom-custom navbar-expand-sm payer-footer-bg p-5 d-flex row">
                        <div class="col-md-2"></div>
                        <div class="col-md-6 row" >
                            <div class="col-md-12 justify-content-center" >
                                <h5 class="font-accent">{{coderToPay.uuid && coderToPay.uuid !== '' ? 'Paying out : '+coderToPay.firstName+' '+coderToPay.lastName   : 'Check components for payment'}}</h5><hr>
                            </div>
                            <div class="col-md-6 justify-content-end align-self-end">
                                <label>TOTAL AMOUNT ({{currentCurrency | symbol}}) </label>
                                <input type="hidden" useriD_total v-model="coderToPay.uuid" />
                                <input type="number" name="usd_total" id="usd_total" class="form-control" readonly v-model="totalPayment" />
                            </div>
                            <div v-if="currentCurrency==='USD'" class="col-md-6 justify-content-end align-self-end">
                                <label>TOTAL AMOUNT (€)</label>
                                <input type="number" name="euro_total" id="euro_total" class="form-control" v-model="totalPaymentInEuro" value="0" />
                            </div>
                        </div>
                        <div class="col-md-2 justify-content-end align-self-end">
                            <button class="btn btn-primary" :disabled="isActive || loading" @click="payAll">$ Pay</button>
                            <button class="btn btn-warning" v-if="isLocked" :disabled="totalPayment===0 || loading" @click.prevent="lock(false)"> <i class="fa fa-unlock"/> Unlock</button>
                            <button class="btn btn-warning" v-else :disabled="totalPayment===0 || loading" @click.prevent="lock(true)"> <i class="fa fa-lock"/> Lock</button>
                        </div>
                        <div class="col-md-2" ></div>
                    </nav>
                </template>
            </ApolloQuery>
        </div>
    </div>
</template>
<script>
  import PayerAccordion from "../../../components/admin/PayerAccordion";
  import CodersFilter from "../../../components/admin/CodersFilter";
  import CityFilter from "../../../components/admin/CityFilter";
  import { DollarSignIcon } from 'vue-feather-icons';
  export default {
    name: "payment",
    components:{
      CodersFilter,
      CityFilter,
      PayerAccordion,
      DollarSignIcon,
    },
    data: function() {
      return {
        coderFilter: '',
        cityFilter: '',
        totalPayment: 0,
        totalPaymentInEuro: 0,
        coderToPay: {},
        selectedPayments: [],
        isLocked: false,
        componentKey: 0,
        lastCurrency:null,
        currentCurrency:'EUR',
        lastCityId: null,
      };
    },
    computed:{
      isPayer: function(){
        return this.$store.getters['security/hasRole']('payer');
      },
      isActive(){
        return this.totalPayment===0 ||  ( (this.totalPaymentInEuro <= 0 || this.totalPaymentInEuro === '') && this.currentCurrency==='USD')
      }

    },
    methods: {
      onSearchCoder(coderFilter) {
        this.coderFilter = coderFilter;
      },
      onSearchCity(cityFilter) {
        this.cityFilter = cityFilter;
      },
      updateTotalPayment({coder2Pay, amount, isSum, isLocked, selectedPayments, currency, cityId}){
        this.currentCurrency = currency
        this.selectedPayments = selectedPayments;
        this.isLocked = isLocked;
        if (isLocked) {
          this.totalPayment = amount;
        } else {
          this.totalPayment = ((this.lastCurrency && this.lastCurrency === currency) && (coder2Pay.uuid === this.coderToPay.uuid) && (this.lastCityId === cityId) ? parseFloat(this.totalPayment) : 0) + (isSum ? parseFloat(amount) : -1*parseFloat(amount));
        }
        if(isSum){
          this.coderToPay = coder2Pay
        }
        else if( this.totalPayment === 0 ){
          this.coderToPay = "";
        }
        this.lastCurrency = currency;
        this.lastCityId = cityId;
      },
      payAll(){
        let usd = this.totalPayment;
        let eur = this.totalPaymentInEuro;
        if (this.currentCurrency === 'EUR') {
          usd = 0;
          eur = this.totalPayment;
        }

        this.totalPayment = this.totalPaymentInEuro = 0;
        this.$store.dispatch('payments/payRequestedPayments', {
          paymentIds: this.selectedPayments,
          totalDollar: usd,
          totalEuro: parseFloat(eur),
          callback: function(){
            window.location.reload();
          }
        });
      },
      lock(lock){
        this.$store.dispatch('payments/lockRequestedPayments', {
          paymentIds: this.selectedPayments,
          lock: lock,
          callback: function(){
            window.location.reload();
          }
        });
      }
    }
  }

</script>