var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-form" },
    [
      _c("b-form", { on: { submit: _vm.onSubmit } }, [
        _c("div", { staticClass: "form-section row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("h1", { staticClass: "form-section-title" }),
            _vm._v(" "),
            _c("div", { staticClass: "row profile-picture" }, [
              _c("div", { staticClass: "col-sm" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Profile Picture")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      !_vm.editMode
                        ? _c("picture-input", {
                            ref: "pictureInput",
                            staticClass: "row profile-picture",
                            attrs: {
                              width: "200",
                              height: "200",
                              margin: "16",
                              accept: "image/jpeg,image/png",
                              size: "10",
                              "button-class": "btn",
                              "custom-strings": {
                                upload: "<h1>Bummer!</h1>",
                                drag: "Drag a profile picture!",
                              },
                            },
                            on: { change: _vm.onChange },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user.avatar && _vm.editMode
                        ? _c("picture-input", {
                            ref: "pictureInput",
                            staticClass: "row profile-picture",
                            attrs: {
                              width: "200",
                              height: "200",
                              margin: "16",
                              accept: "image/jpeg,image/png",
                              size: "10",
                              prefill:
                                "/images/users/" +
                                _vm.user.email +
                                _vm.user.avatar,
                              "button-class": "btn",
                              "custom-strings": {
                                upload: "<h1>Bummer!</h1>",
                                drag: "Drag a profile picture!",
                              },
                            },
                            on: { change: _vm.onChange },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.user.avatar && _vm.editMode
                        ? _c("picture-input", {
                            ref: "pictureInput",
                            staticClass: "row profile-picture",
                            attrs: {
                              width: "200",
                              height: "200",
                              margin: "16",
                              accept: "image/jpeg,image/png",
                              size: "10",
                              prefill:
                                "https://ui-avatars.com/api/?name=" +
                                _vm.user.firstName +
                                "+" +
                                _vm.user.lastName +
                                "&size=200.png",
                              "button-class": "btn",
                              "custom-strings": {
                                upload: "<h1>Bummer!</h1>",
                                drag: "Drag a profile picture!",
                              },
                            },
                            on: { change: _vm.onChange },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8" }, [
            _c("h1", { staticClass: "form-section-title" }, [
              _vm._v("Personal Information"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fieldset-first-name",
                        label: "First Name *",
                        "label-for": "input-first-name",
                        state: _vm.validateState("user.firstName"),
                        "invalid-feedback": _vm.validationMsg.firstName,
                      },
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true },
                            expression: "{ required: true }",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "input-first-name",
                          name: "user.firstName",
                          placeholder: "Enter first name",
                          state: _vm.validateState("user.firstName"),
                        },
                        model: {
                          value: _vm.user.firstName,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "firstName", $$v)
                          },
                          expression: "user.firstName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fieldset-last-name",
                        label: "Last Name *",
                        "label-for": "input-last-name",
                        state: _vm.validateState("user.lastName"),
                        "invalid-feedback": _vm.validationMsg.lastName,
                      },
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true },
                            expression: "{ required: true }",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "input-last-name",
                          name: "user.lastName",
                          placeholder: "Enter last name",
                          state: _vm.validateState("user.lastName"),
                        },
                        model: {
                          value: _vm.user.lastName,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "lastName", $$v)
                          },
                          expression: "user.lastName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fieldset-country",
                        label: "Country *",
                        "label-for": "input-country",
                        "invalid-feedback": _vm.validationMsg.country,
                        state: _vm.validateState("user.address.country"),
                      },
                    },
                    [
                      _c(
                        "b-form-select",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { required: true },
                              expression: "{ required: true }",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "input-country",
                            name: "user.address.country",
                            options: _vm.countries,
                            state: _vm.validateState("user.address.country"),
                          },
                          model: {
                            value: _vm.user.address.country,
                            callback: function ($$v) {
                              _vm.$set(_vm.user.address, "country", $$v)
                            },
                            expression: "user.address.country",
                          },
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("Select a country"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fieldset-city",
                        label: "City *",
                        "label-for": "input-city",
                        state: _vm.validateState("user.address.city"),
                        "invalid-feedback": _vm.validationMsg.city,
                      },
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true },
                            expression: "{ required: true }",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "input-city",
                          name: "user.city",
                          placeholder: "Enter City",
                          state: _vm.validateState("user.address.city"),
                        },
                        model: {
                          value: _vm.user.address.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.user.address, "city", $$v)
                          },
                          expression: "user.address.city",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fieldset-birthday",
                        label: "Birthday *",
                        "label-for": "input-birthday",
                        state: _vm.validateState("user.birthday"),
                        "invalid-feedback": _vm.validationMsg.birthday,
                      },
                    },
                    [
                      _c("datepicker", {
                        attrs: {
                          id: "input-birthday",
                          name: "user.birthday",
                          placeholder: "Birthday",
                          format: "MM/dd/yyyy",
                          "bootstrap-styling": "",
                          state: _vm.validateState("user.birthday"),
                          "aria-describedby": "birthday-live-feedback",
                        },
                        model: {
                          value: _vm.user.birthday,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "birthday", $$v)
                          },
                          expression: "user.birthday",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fieldset-email",
                        label: "Email *",
                        "label-for": "input-email",
                        state: _vm.validateState("user.email"),
                        "invalid-feedback": _vm.validationMsg.email,
                      },
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true, email: true },
                            expression: "{ required: true, email: true }",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "input-email",
                          type: "email",
                          name: "user.email",
                          placeholder: "Enter Email",
                          state: _vm.validateState("user.email"),
                        },
                        model: {
                          value: _vm.user.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "email", $$v)
                          },
                          expression: "user.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fieldset-github",
                        label: "Github Account",
                        "label-for": "input-github",
                        state: _vm.validateState("user.github"),
                        "invalid-feedback": _vm.validationMsg.github,
                      },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          id: "input-github",
                          name: "user.github",
                          placeholder: "Enter Github",
                          state: _vm.validateState("user.github"),
                        },
                        model: {
                          value: _vm.user.github,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "github", $$v)
                          },
                          expression: "user.github",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fieldset-skypeId",
                        label: "Skype ID",
                        "label-for": "input-skypeId",
                        state: _vm.validateState("user.skypeId"),
                        "invalid-feedback": _vm.validationMsg.skypeId,
                      },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          id: "input-skypeId",
                          name: "user.skypeId",
                          placeholder: "Enter SkypeID",
                          state: _vm.validateState("user.skypeId"),
                        },
                        model: {
                          value: _vm.user.skypeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "skypeId", $$v)
                          },
                          expression: "user.skypeId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fieldset-timezone",
                        label: "GMT *",
                        "label-for": "input-timezone",
                        state: _vm.validateState("user.timezone"),
                        "invalid-feedback": _vm.validationMsg.timezone,
                      },
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              required: true,
                              regex: /^-?[0-9]$|^-?1[0-2]$/,
                            },
                            expression:
                              "{ required: true, regex: /^-?[0-9]$|^-?1[0-2]$/ }",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "input-timezone",
                          name: "user.timezone",
                          placeholder: "Enter Timezone",
                          state: _vm.validateState("user.timezone"),
                        },
                        model: {
                          value: _vm.user.timezone,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "timezone", _vm._n($$v))
                          },
                          expression: "user.timezone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fieldset-zohoUserId",
                        label: "Zoho User ID",
                        "label-for": "input-zohoUserId",
                        state: _vm.validateState("user.zohoUserId"),
                        "invalid-feedback": _vm.validationMsg.zohoUserId,
                      },
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true, regex: /^[0-9]{1,11}$/ },
                            expression:
                              "{ required: true, regex: /^[0-9]{1,11}$/}",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "input-zohoUserId",
                          name: "user.zohoUserId",
                          placeholder: "Enter Zoho User ID",
                          state: _vm.validateState("user.zohoUserId"),
                        },
                        model: {
                          value: _vm.user.zohoUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "zohoUserId", $$v)
                          },
                          expression: "user.zohoUserId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fieldset-role",
                        label: "Role *",
                        "label-for": "input-role",
                        "invalid-feedback": _vm.validationMsg.country,
                        state: _vm.validateState("user.address.country"),
                      },
                    },
                    [
                      _c("b-form-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true },
                            expression: "{ required: true }",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "input-role",
                          name: "user.role",
                          options: _vm.options,
                          state: _vm.validateState(""),
                        },
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _vm.isCoder
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-currency",
                            label: "Currency",
                            "label-for": "check-currency",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-check form-check-inline" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.currency,
                                    expression: "user.currency",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  id: "check-currency-eur",
                                  type: "radio",
                                  name: "currency",
                                  value: "EUR",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.user.currency, "EUR"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(_vm.user, "currency", "EUR")
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "check-currency-eur" },
                                },
                                [_c("span", { staticClass: "euro" })]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-check form-check-inline" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.user.currency,
                                    expression: "user.currency",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  id: "check-currency-usd",
                                  type: "radio",
                                  name: "currency",
                                  value: "USD",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.user.currency, "USD"),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(_vm.user, "currency", "USD")
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "check-currency-usd" },
                                },
                                [_c("span", { staticClass: "dollar" })]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "fieldset-intra-communty-vat",
                        label: _vm.isRequired
                          ? "Intra-community vat *"
                          : "Intra-community vat",
                        "label-for": "input-intra-community-vat",
                        "invalid-feedback": _vm.validationMsg.intraCommunityVat,
                        state: _vm.validateState("user.intraCommunityVat"),
                      },
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: _vm.isRequired },
                            expression: "{ required: isRequired }",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "input-intra-community-vat",
                          name: "user.intraCommunityVat",
                          placeholder: "Enter intra-community vat",
                        },
                        model: {
                          value: _vm.user.intraCommunityVat,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "intraCommunityVat", $$v)
                          },
                          expression: "user.intraCommunityVat",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 col-sm-12" },
                [
                  _vm.isCoder
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "fieldset-hourlyRate",
                            label: "Hourly Rate *",
                            "label-for": "input-hourlyRate",
                            state: _vm.validateState("user.hourlyRate"),
                            "invalid-feedback": _vm.validationMsg.hourlyRate,
                          },
                        },
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: _vm.isCoder, decimal: true },
                                expression:
                                  "{ required: isCoder, decimal: true }",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "input-hourlyRate",
                              name: "user.hourlyRate",
                              placeholder: "Enter Hourly Rate",
                              state: _vm.validateState("user.hourlyRate"),
                            },
                            model: {
                              value: _vm.user.hourlyRate,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "hourlyRate", _vm._n($$v))
                              },
                              expression: "user.hourlyRate",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-section row" }, [
          _c("div", { staticClass: "col-md-4 col-sm-12" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-8 col-sm-12" },
            [
              _c("h1", { staticClass: "form-section-title" }, [
                _vm._v("Skills *"),
              ]),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "fieldset-skills",
                    "label-for": "input-textarea",
                    state: _vm.validateState("user.skills"),
                    "invalid-feedback": _vm.validationMsg.skills,
                  },
                },
                [
                  _c("b-form-textarea", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true },
                        expression: "{ required: true }",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Enter skills",
                      id: "input-textarea",
                      name: "user.skills",
                      state: _vm.validateState("user.skills"),
                    },
                    model: {
                      value: _vm.user.skills,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "skills", $$v)
                      },
                      expression: "user.skills",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.isCoder
          ? _c("div", { staticClass: "form-section row" }, [
              _c("div", { staticClass: "col-md-4 col-sm-12" }),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-8 col-sm-12" }, [
                _c("h1", { staticClass: "form-section-title" }, [
                  _vm._v("Payment Method *"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row logos-payment" }, [
                  _c("div", { staticClass: "col-sm" }, [
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.paymentMethod,
                            expression: "user.paymentMethod",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "pm-paypal",
                          type: "radio",
                          name: "user.paymentMethod",
                          checked: "",
                          value: "paypal",
                        },
                        domProps: {
                          checked: _vm._q(_vm.user.paymentMethod, "paypal"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.user, "paymentMethod", "paypal")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "pm-paypal" },
                        },
                        [
                          _c("div", { staticClass: "label-logo" }, [
                            _c("img", {
                              attrs: {
                                alt: "paypal",
                                title: "PayPal",
                                height: "60",
                                src: "/images/logos/paypal.png",
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm" }, [
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.paymentMethod,
                            expression: "user.paymentMethod",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "pm-transferwise",
                          type: "radio",
                          name: "user.paymentMethod",
                          value: "transferwise",
                        },
                        domProps: {
                          checked: _vm._q(
                            _vm.user.paymentMethod,
                            "transferwise"
                          ),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.user,
                              "paymentMethod",
                              "transferwise"
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "pm-transferwise" },
                        },
                        [
                          _c("div", { staticClass: "label-logo" }, [
                            _c("img", {
                              attrs: {
                                alt: "transferwise",
                                title: "TransferWise",
                                height: "22",
                                src: "/images/logos/transferwise.png",
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm" }, [
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.paymentMethod,
                            expression: "user.paymentMethod",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "pm-wire-transfer",
                          type: "radio",
                          name: "user.paymentMethod",
                          value: "wire-transfer",
                        },
                        domProps: {
                          checked: _vm._q(
                            _vm.user.paymentMethod,
                            "wire-transfer"
                          ),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.user,
                              "paymentMethod",
                              "wire-transfer"
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "pm-wire-transfer" },
                        },
                        [
                          _c("div", { staticClass: "label-logo" }, [
                            _c("img", {
                              attrs: {
                                alt: "wire-transfer",
                                title: "Wire Transfer",
                                height: "70",
                                src: "/images/logos/wire-transfer.png",
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm" }, [
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.paymentMethod,
                            expression: "user.paymentMethod",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "pm-western-union",
                          type: "radio",
                          name: "user.paymentMethod",
                          value: "western-union",
                        },
                        domProps: {
                          checked: _vm._q(
                            _vm.user.paymentMethod,
                            "western-union"
                          ),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(
                              _vm.user,
                              "paymentMethod",
                              "western-union"
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "pm-western-union" },
                        },
                        [
                          _c("div", { staticClass: "label-logo" }, [
                            _c("img", {
                              attrs: {
                                alt: "western-union",
                                title: "Western Union",
                                height: "25",
                                src: "/images/logos/western-union.png",
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-section row" }, [
          _c("div", { staticClass: "col-md-4 col-sm-12" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-8 col-sm-12" }, [
            _c("h1", { staticClass: "form-section-title" }, [
              _vm._v("Sync with zoho"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.syncWithZoho,
                  expression: "user.syncWithZoho",
                },
              ],
              attrs: { type: "checkbox", id: "sync-with-zoho" },
              domProps: {
                value: _vm.user.syncWithZoho,
                checked: Array.isArray(_vm.user.syncWithZoho)
                  ? _vm._i(_vm.user.syncWithZoho, _vm.user.syncWithZoho) > -1
                  : _vm.user.syncWithZoho,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.user.syncWithZoho,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.user.syncWithZoho,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.user, "syncWithZoho", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.user,
                          "syncWithZoho",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.user, "syncWithZoho", $$c)
                  }
                },
              },
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "sync-with-zoho" } }, [
              _c("span", [_vm._v("Sync with Zoho.")]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "text-center offset-4 py-4" },
              [_c("b-spinner", { attrs: { label: "Spinning" } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-section button-container" }, [
          _c("div", { staticClass: "row justify-content-around" }, [
            _c("div", { staticClass: "col-4" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-8 d-flex justify-content-around" },
              [
                !_vm.isLoading
                  ? _c(
                      "button",
                      {
                        staticClass: "icon ion-md-create btn btn-primary",
                        attrs: { id: "component" },
                      },
                      [_c("span", [_vm._v("Create Component")])]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isLoading
                  ? _c(
                      "button",
                      {
                        staticClass: "icon ion-md-create btn btn-primary",
                        attrs: { id: "save" },
                      },
                      [
                        !_vm.user || !_vm.user.uuid
                          ? _c("span", [_vm._v("Save Profile")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.user.uuid
                          ? _c("span", [_vm._v("Update Profile")])
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "confirmation-modal",
          attrs: { title: "Confirm the modification", "hide-footer": "" },
        },
        [
          _c("div", { staticClass: "d-block text-center" }, [
            _vm._v(
              "\n      This user has currently components and payments on hold. Are you sure you want to update the email ?\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "b-button",
            {
              on: {
                click: function ($event) {
                  return _vm.closeModal()
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "btn-danger",
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }