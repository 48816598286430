<template>
  <div class="d-flex align-items-center notification px-1 mb-5">
    <info-icon></info-icon>
    <div class="nf-content">
      <p class="mb-0">{{message}}</p>
    </div>
    <div class="nf-controls d-flex ml-auto">
      <button class="btn btn-feather" v-on:click="viewContract(notification)">
        <eye-icon></eye-icon>
      </button>
    </div>
  </div>
</template>

<script>
  import {
    InfoIcon,
    EyeIcon,
    XIcon
  } from 'vue-feather-icons'
  
  export default {
    props: ['notification'],
    components: {
      InfoIcon,
      EyeIcon,
      XIcon,
    },
    name: 'CoderNotification',
    data() {
      return {
        components: []
      }
    },
    computed: {
      message() {
        return `${this.manager()} has just assigned you the component ${this.notification.component.name}. Do you accept?`;
      },
    },
    methods: {
      manager() {
        const { manager } = this.notification.component;
        if (manager) {
          return `${manager.firstName} ${manager.lastName}`;
        }
      },
      viewContract(notification) {
        this.$store.dispatch('components/getComponentsByNotification', {noticationUuid:notification.uuid}).then(res => {
          let components = this.$store.getters['components/getComponentsByNotification'];
          this.$router.push({
            name: 'contract',
            params: {
              id: components[0].uuid,
              notification_id: notification.uuid
            }
          });
        });
        
      }
    }
  };
</script>
