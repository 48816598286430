var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auth-wrap" }, [
    _c("div", { staticClass: "auth-content center" }, [
      _c("div", { staticClass: "auth-container reset" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "auth-form" },
          [
            _vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "text-center py-4" },
                  [_c("b-spinner", { attrs: { label: "Spinning" } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-alert",
              {
                staticClass: "text-center",
                attrs: { variant: "info", show: _vm.isLoading },
              },
              [_vm._v("Validating Token ...")]
            ),
            _vm._v(" "),
            _c(
              "b-alert",
              {
                staticClass: "text-center",
                attrs: {
                  variant: "danger",
                  show: !_vm.isLoading && _vm.hasError,
                },
              },
              [_vm._v("Token Not Valid!")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "auth-logo-wrap" }, [
      _c("img", { attrs: { src: "/images/logo.png" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }