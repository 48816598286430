var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-wrap" },
    [
      _c("div", { staticClass: "auth-content center" }, [
        _c("div", { staticClass: "auth-container login" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "auth-form" },
            [
              !_vm.status.confirmed
                ? [
                    _c(
                      "b-form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.performConfirmation.apply(
                              null,
                              arguments
                            )
                          },
                          input: _vm.resetStatus,
                        },
                      },
                      [
                        _c(
                          "b-alert",
                          {
                            attrs: {
                              show: _vm.status.error,
                              variant: _vm.status.error ? "danger" : "success",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.status.message
                                  ? _vm.status.message
                                  : "error"
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "fieldset-email",
                              label: "Enter your email address",
                              "label-for": "input-email",
                              "invalid-feedback": _vm.validationMsg.email,
                              state: _vm.validateState("email"),
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control",
                              attrs: {
                                id: "input-email",
                                name: "email",
                                placeholder: "Email",
                                type: "email",
                                disabled: _vm.status.loading,
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group auth-item my-4" },
                          [
                            _vm.status.loading
                              ? _c(
                                  "div",
                                  { staticClass: "text-center py-4" },
                                  [
                                    _c("b-spinner", {
                                      attrs: { label: "Spinning" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-block py-2",
                                attrs: {
                                  type: "submit",
                                  variant: "primary",
                                  disabled: _vm.status.loading,
                                },
                              },
                              [_vm._v("Confirm Email")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : [_vm._m(1)],
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("dash-footer"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "auth-logo-wrap" }, [
      _c("img", { attrs: { src: "/images/logo.png" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "py-3 text-center" }, [
      _c("h4", { staticClass: "font-accent" }, [_vm._v("Email sent")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Check your email for further instructions on resetting your password."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }