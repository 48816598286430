<template>
  <div class="table-header d-flex align-items-center py-1 coder-info">
    <div class="d-flex align-items-center justify-content-center p-2 table-control">
      <a href="#" @click.prevent="onToggle" class="coder-info-toggle">
        <chevron-down-icon v-if="count != 0" class="accordion-toggle" :class="{ rotate: isOpen }"></chevron-down-icon>
      </a>
    </div>
    <div class="d-flex align-items-center justify-content-center p-2">
      <div class="img-wrap">
        <img v-if="coder.avatar" alt="coder" :src="'/images/users/'+coder.email+coder.avatar"/>
        <img v-else :src="'https://ui-avatars.com/api/?name=' + coder.firstName + '+' + coder.lastName + '&size=100'"/>
      </div>
    </div>
    <div class="w-100">
      <div class="d-flex align-items-center ml-3">
        <h3 v-if="!coder.firstName && !coder.lastName">(No Name)</h3>
        <router-link 
          :to="{name:'coderDashboard', params: { uuid: coder ? coder.uuid : null }}"
          v-if="coder.firstName || coder.lastName"
          style="text-decoration-color: white"
        >
          <h3 style="color: white">{{coder.firstName + ' ' + coder.lastName}}</h3>
        </router-link>
        <b-badge pill variant="warning" class="ml-2 circle badge-rounded">{{count}}</b-badge>
        <div class="ml-auto px-3 mr-3" v-if="userRole==='admin'">
          <router-link class="preview-btn btn p-0 mr-1" :to="{ name: 'editCoder', params: { uuid: coder ? coder.uuid : null } }">
            <edit-2-icon class="edit-profile-btn"></edit-2-icon>
          </router-link>
        </div>
      </div>
      <div class="d-flex font-extra-small user-details ml-2 mt-2" v-if="coder.address !== null">
        <span class="mr-2 coder-location font-accent d-flex align-items-center" v-if="coder.address.city && coder.address.country"> <map-pin-icon></map-pin-icon>{{coder.address.city + ', ' + coder.address.country}}</span>
        <span class="mr-2 d-flex align-items-center"> <globe-icon></globe-icon>GMT {{coder.timezone > 0 ? `+${coder.timezone}` : `${coder.timezone}`}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ChevronDownIcon,
    ChevronUpIcon,
    GlobeIcon,
    MapPinIcon,
    Edit2Icon,
    UploadIcon,
    Edit3Icon,
    UserIcon,
  } from 'vue-feather-icons';
  import {
    CODERS_REQUESTED_PAYMENT,
  } from '../../constants';
  export default {
    props: ['coder', 'isOpen', 'userRole', 'count'],
    components: {
      ChevronDownIcon,
      ChevronUpIcon,
      GlobeIcon,
      MapPinIcon,
      Edit2Icon,
      UploadIcon,
      Edit3Icon,
      UserIcon,
    },
    name: 'CoderInfo',
    computed: {      
      currentUserId: function(){
        return this.$store.getters['security/id'];
      }
    },
    methods: {
      onUpload() {
        this.$router.push({
          name: 'editCoder',
          params: {
            editCoder: this.coder,
          }
        });
      },
      onToggle() {
        this.$emit('toggle');
      }
    }
  };
</script>
