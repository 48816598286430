var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "d-flex align-items-center page-title mb-3" }, [
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary btn-back",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onBack.apply(null, arguments)
                },
              },
            },
            [
              _c("arrow-left-icon", { staticClass: "custom-class" }),
              _vm._v("Back\n      "),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _vm.forCreate
            ? _c("h2", { staticClass: "dash-header-title m-0" }, [
                _vm._v("Create User"),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.forCreate
            ? _c("h2", { staticClass: "dash-header-title m-0" }, [
                _vm._v("Edit User"),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "border-bottom my-3" }),
      _vm._v(" "),
      _vm.forCreate
        ? _c("user-form", {
            attrs: { viewer: "admin", editMode: false, error: _vm.error },
            on: { submit: _vm.onSubmit },
          })
        : _c("user-form", {
            attrs: {
              viewer: "admin",
              user: _vm.editCoder,
              editMode: true,
              error: _vm.error,
            },
            on: { submit: _vm.onSubmit },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }