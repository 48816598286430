var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card p-2" }, [
    _c("div", {}, [
      _vm.isAdmin
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: "editCoder", params: { editCoder: _vm.coder } },
                  },
                },
                [_c("edit-2-icon", { staticClass: "p-1 ml-2" })],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex align-items-center flex-column user-info" },
      [
        _c("div", { staticClass: "avatar p-2" }, [
          _c("div", { staticClass: "img-wrap" }, [
            _vm.coder.avatar && _vm.coder.email
              ? _c("img", {
                  staticClass: "td-avatar",
                  attrs: {
                    width: "150px",
                    height: "150px",
                    src: "/images/users/" + _vm.coder.email + _vm.coder.avatar,
                    alt: "",
                  },
                })
              : _vm.coder.avatar && _vm.coder.accountAddress
              ? _c("img", {
                  staticClass: "td-avatar",
                  attrs: {
                    width: "150px",
                    height: "150px",
                    src:
                      "/images/users/" +
                      _vm.coder.accountAddress +
                      _vm.coder.avatar,
                    alt: "",
                  },
                })
              : _c("img", {
                  attrs: {
                    src:
                      "https://ui-avatars.com/api/?name=" +
                      _vm.coder.firstName +
                      "+" +
                      _vm.coder.lastName +
                      "&size=150",
                  },
                }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "details p-2 mt-3" }, [
          _c("h3", { staticClass: "mb-3 font-medium" }, [
            _vm._v(_vm._s([_vm.coder.firstName, _vm.coder.lastName].join(" "))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex user-detail" },
            [
              _c("map-pin-icon"),
              _vm._v(" "),
              _vm.coder.address
                ? _c("div", { staticClass: "pl-2" }, [
                    _c("h6", [
                      _vm._v(
                        _vm._s(
                          [
                            _vm.coder.address.city,
                            _vm.coder.address.country,
                          ].join(", ")
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex mt-2 user-detail" },
            [
              _c("globe-icon"),
              _vm._v(" "),
              typeof _vm.coder.timezone === "number"
                ? _c("div", { staticClass: "pl-2" }, [
                    _c("h6", [
                      _vm._v(
                        " GMT " +
                          _vm._s(
                            _vm.coder.timezone > 0
                              ? `+${_vm.coder.timezone}`
                              : `+${_vm.coder.timezone}`
                          )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex mt-2 user-detail" },
            [
              _c("code-icon"),
              _vm._v(" "),
              _c("div", { staticClass: "pl-2" }, [
                _c("h6", [
                  _vm._v(
                    _vm._s(
                      _vm.coder.skills && _vm.coder.skills.substring(0, 200)
                    )
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex mt-2 user-detail" },
            [
              _c("dollar-sign-icon"),
              _vm._v(" "),
              _vm.coder.hourlyRate
                ? _c("div", { staticClass: "pl-2" }, [
                    _c("h6", [
                      _c("strong", [
                        _vm._v(
                          "$" +
                            _vm._s(_vm.coder.hourlyRate) +
                            "/H - $" +
                            _vm._s(_vm.dailyRate) +
                            "/DAY "
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "my-2 d-flex text-center justify-content-center user-stats",
      },
      [
        _c("div", { staticClass: "mx-4" }, [
          _c("div", { staticClass: "user-stat" }, [
            _c("h1", { staticClass: "font-semibold font-accent" }, [
              _vm._v(_vm._s(_vm.components)),
            ]),
            _vm._v(" "),
            _c("h6", { staticClass: "stat-label" }, [_vm._v("COMPONENTS")]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }