<template>
  <div class="my-5 pb-5">
    <div class="d-flex  align-items-center page-title mb-3">
      <div>
        <button class="btn btn-secondary btn-back" @click.prevent="back">
          <arrow-left-icon class="custom-class"></arrow-left-icon>Back
        </button>
      </div>
      <div class="ml-4">
        <h2 class="dash-header-title m-0">View Component</h2>
      </div>
    </div>
    <div class="border-bottom my-3"></div>
    <component-form class="mt-5" mode="coder" v-on:save="onSave" :isLoading="isLoading" :component="component" :error="error" v-if="component"></component-form>
  </div>
</template>

<script>
  import {
    ArrowLeftIcon
  } from 'vue-feather-icons';
  import ComponentForm from '../../../components/admin/ComponentForm';
  import DashHeader from '../../../components/Header';
  
  export default {
    name: "CoderComponent",
    components: {
      ArrowLeftIcon,
      ComponentForm,
      DashHeader,
    },
    data() {
      return {
        component: null
      }
    },
    async mounted() {
      if (!this.$route.params.uuid) {
        await this.$router.push({
          path: '/dashboard'
        });
      } else {
        await this.$store.dispatch('components/fetchComponentById', this.$route.params.uuid);
        this.component = this.$store.getters['components/component'];
      }
    },
    computed: {
      isLoading() {
        return this.$store.getters['components/isLoading'];
      },
      hasError() {
        return this.$store.getters['components/hasError'];
      },
      error() {
        return this.$store.getters['components/error'];
      },
    },
    methods: {
      onSave: function({
        component,
        payments
      }) {
      },
      back() {
        this.$store.dispatch('components/resetStore');
        this.$router.push({path: '/'});
      }
    }
  };
</script>