import axios from 'axios';

export default {
    login (login, password) {
        return axios.post(
            '/api/security/login',
            {
                username: login,
                password: password
            }
        );
    },
    logout () {
        return axios.get(
            '/api/security/logout'
        );
    },
    confirmEmail (email) {
        return axios.post(
            '/api/security/confirm-email',
            {
                email: email,
            }
        );
    },
    reset (token, password) {
        return axios.post(
            '/api/security/token',
            {
                token,
                password
            }
        );
    },
}
