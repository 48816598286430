var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "city-search d-flex align-items-center justify-content-between",
    },
    [
      _c(
        "div",
        { staticClass: "form-group d-flex align-items-center border-bottom" },
        [
          _c("search-icon", { staticClass: "search-icon" }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.text,
                expression: "text",
              },
            ],
            staticClass: "form-control form-control-sm",
            attrs: { placeholder: "Search a city", type: "text" },
            domProps: { value: _vm.text },
            on: {
              keyup: _vm.onChange,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.text = $event.target.value
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }