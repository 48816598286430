var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-header d-flex align-items-center py-1 coder-info" },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-center p-2 table-control",
        },
        [
          _c(
            "a",
            {
              staticClass: "coder-info-toggle",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onToggle.apply(null, arguments)
                },
              },
            },
            [
              _vm.count != 0
                ? _c("chevron-down-icon", {
                    staticClass: "accordion-toggle",
                    class: { rotate: _vm.isOpen },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-items-center justify-content-center p-2" },
        [
          _c("div", { staticClass: "img-wrap" }, [
            _vm.coder.avatar
              ? _c("img", {
                  attrs: {
                    alt: "coder",
                    src: "/images/users/" + _vm.coder.email + _vm.coder.avatar,
                  },
                })
              : _c("img", {
                  attrs: {
                    src:
                      "https://ui-avatars.com/api/?name=" +
                      _vm.coder.firstName +
                      "+" +
                      _vm.coder.lastName +
                      "&size=100",
                  },
                }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "w-100" }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center ml-3" },
          [
            !_vm.coder.firstName && !_vm.coder.lastName
              ? _c("h3", [_vm._v("(No Name)")])
              : _vm._e(),
            _vm._v(" "),
            _vm.coder.firstName || _vm.coder.lastName
              ? _c(
                  "router-link",
                  {
                    staticStyle: { "text-decoration-color": "white" },
                    attrs: {
                      to: {
                        name: "coderDashboard",
                        params: { uuid: _vm.coder ? _vm.coder.uuid : null },
                      },
                    },
                  },
                  [
                    _c("h3", { staticStyle: { color: "white" } }, [
                      _vm._v(
                        _vm._s(_vm.coder.firstName + " " + _vm.coder.lastName)
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-badge",
              {
                staticClass: "ml-2 circle badge-rounded",
                attrs: { pill: "", variant: "warning" },
              },
              [_vm._v(_vm._s(_vm.count))]
            ),
            _vm._v(" "),
            _vm.userRole === "admin"
              ? _c(
                  "div",
                  { staticClass: "ml-auto px-3 mr-3" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "preview-btn btn p-0 mr-1",
                        attrs: {
                          to: {
                            name: "editCoder",
                            params: { uuid: _vm.coder ? _vm.coder.uuid : null },
                          },
                        },
                      },
                      [_c("edit-2-icon", { staticClass: "edit-profile-btn" })],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.coder.address !== null
          ? _c(
              "div",
              { staticClass: "d-flex font-extra-small user-details ml-2 mt-2" },
              [
                _vm.coder.address.city && _vm.coder.address.country
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "mr-2 coder-location font-accent d-flex align-items-center",
                      },
                      [
                        _c("map-pin-icon"),
                        _vm._v(
                          _vm._s(
                            _vm.coder.address.city +
                              ", " +
                              _vm.coder.address.country
                          )
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "mr-2 d-flex align-items-center" },
                  [
                    _c("globe-icon"),
                    _vm._v(
                      "GMT " +
                        _vm._s(
                          _vm.coder.timezone > 0
                            ? `+${_vm.coder.timezone}`
                            : `${_vm.coder.timezone}`
                        )
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }