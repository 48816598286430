import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import apolloProvider from './graphql/index';
import VeeValidate from 'vee-validate';
import HighchartsVue from 'highcharts-vue';
import VueMoment from 'vue-moment';
import BootstrapVue from 'bootstrap-vue';
import Datepicker from 'vuejs-datepicker';
import VueGAPI from "vue-gapi";
import '../theme/index.scss';

window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
require('bootstrap');

[
  HighchartsVue,
  VueMoment,
  BootstrapVue,
  Datepicker
].forEach(plugin => Vue.use(plugin));


Vue.component('wrapper', require('./wrappers/NormalWrapper').default)
Vue.component('admin-wrapper', require('./wrappers/AdminWrapper').default)
Vue.component('coder-wrapper', require('./wrappers/CoderWrapper').default)
Vue.component('dashboard-header', require('./components/admin/Header').default)
const apiConfig = {
    clientId: "102464484237447283876.apps.googleusercontent.com",
    scope: "https://www.googleapis.com/auth/admin.datatransfer.readonly"
};

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields'
});
Vue.use(VueGAPI, apiConfig);

Vue.filter('symbol', function (currency) {
  let currencies = {EUR: '€', USD: '$'};
  return currencies[currency];
});

new Vue({
  template: '<App/>',
  components: { App },
  router,
  store,
  apolloProvider
}).$mount('#app');
