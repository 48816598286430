var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "dashboard-header",
        { staticClass: "border-bottom" },
        [
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "router-link",
                { attrs: { to: _vm.getCreateComponentUrl() } },
                [
                  _c(
                    "button",
                    { staticClass: "btn btn-primary px-3" },
                    [_c("plus-icon"), _vm._v(" Create component")],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("router-link", { attrs: { to: _vm.getCreateCoderUrl() } }, [
                _vm.isAdmin
                  ? _c(
                      "button",
                      { staticClass: "btn btn-primary px-3" },
                      [_c("user-plus-icon"), _vm._v("Create user")],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("component-filter", {
        attrs: { page: "Dashboard" },
        on: { search: _vm.onSearch },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "admin-table mt-4" },
        [
          _c("ApolloQuery", {
            attrs: {
              query: require("../../../graphql/coder/query/coders.gql"),
              variables: {
                coder: _vm.filter.coder,
                component: _vm.filter.component,
              },
              options: { notifyOnNetworkStatusChange: true },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ result: { loading, error, data } }) {
                  return [
                    loading
                      ? _c(
                          "div",
                          { staticClass: "text-center py-4" },
                          [_c("b-spinner", { attrs: { label: "Spinning" } })],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    error
                      ? _c(
                          "b-alert",
                          { attrs: { show: "", variant: "danger" } },
                          [_vm._v(_vm._s(error))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !loading
                      ? _c(
                          "div",
                          { staticClass: "admin-accordions" },
                          [
                            _vm._l(data.coders.items, function (coder, index) {
                              return _vm.hasComponents(coder)
                                ? _c("coders-accordion", {
                                    key: index,
                                    attrs: { coder: coder },
                                  })
                                : _vm._e()
                            }),
                            _vm._v(" "),
                            data.coders.length <= 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "user-table admin-dash-table",
                                  },
                                  [_c("h6", [_vm._v("No Coder To Show")])]
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }