var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-table admin-dash-table" },
    [
      _c("coder-info", {
        attrs: {
          coder: _vm.coder,
          count: _vm.count,
          isOpen: _vm.isOpen,
          userRole: _vm.currentRole,
        },
        on: { toggle: _vm.onToggle },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "coders-accordion-content",
          class: { active: _vm.isOpen },
        },
        [
          _c("payer-payment", {
            attrs: {
              coder: _vm.coder,
              payments: _vm.payerPayments,
              coderToPay: _vm.coderToPay,
              selectedPayments: _vm.selectedPayments,
              isPayer: _vm.isPayer,
            },
            on: { updateTotalPayment: _vm.updateTotalPayment },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }