<template>
    <div>
        <div class="container-fluid px-2 component-info-list">
            <table class="table rounded-table dash-table">
                <thead class>
                <th>Component Name</th>
                <th class>
                    <span class="d-block">Team</span>
                </th>
                <th>Deadline</th>
                <th>Price</th>
                <th>Type</th>
                <th class="font-accent">Status</th>
                <th>Actions</th>
                </thead>
                <tbody>
                  <tr v-for="(component, index) in components" :key="index" :class="isPaymentAllowed(component) ? 'highlight-darkred' : ''" style="cursor: auto">
                    <td>{{component.name}}</td>
                    <td>
                        <!--<span class="d-block"
                              v-for="project in projects.filter(p => parseInt(p.id)===parseInt(component.projectId))">{{component.manager.firstName +' '+ component.manager.lastName}}</span>-->
                    </td>
                    <td>
                        <!-- <alert-circle-icon class="alert-icon" v-if="isOverdue(component.endDate)"></alert-circle-icon> -->
                        {{component.endDate | moment("DD/MM/YYYY")}}
                    </td>
                    <td>
                        <span v-if="isHourly(component)">{{component.currency | symbol}}{{ component.dayRate }}</span>
                        <span v-if="isFixed(component)">{{component.currency | symbol}}{{ component.fixedPrice }}</span>
                    </td>
                    <td>
                        <div class="d-flex align-items-center justify-content-center component-view">
                            <inbox-icon v-show="isHourly(component)"></inbox-icon>
                            <tag-icon v-show="isFixed(component)"></tag-icon>
                        </div>
                    </td>
                    <td>
                        <div class="component-view font-accent">
                            <span class="d-block">{{component.status}}</span>
                        </div>
                    </td>
                    <td>
                      <div @click.prevent="getComponentUrl(component)">
                        <span v-if="component.manager && component.manager.uuid === userId"><edit2-icon class="p-1 ml-2"></edit2-icon></span>
                        <span v-else><eye-icon class="p-1 ml-2"></eye-icon></span>
                      </div>
                    </td>
                  </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
  import {InboxIcon, TagIcon, EyeIcon, AlertCircleIcon, Edit2Icon} from "vue-feather-icons";
  import moment from "moment";
  import {
    CREATE_COMPONENT,
    CODERS_DELEGATION_SKILLS,
    CODERS_FIXED_PRICE, CODERS_ALLOWED_PAYMENT, CODERS_WAITING_PAYMENT
  } from '../../constants';
  import {mapGetters} from "vuex";

  export default {
    components: {
      InboxIcon,
      EyeIcon,
      Edit2Icon,
      AlertCircleIcon,
      TagIcon,
    },
    props: ["user_uuid"],
    name: "CoderComponentList",
    data() {
      return {
        components: []
      }
    },
    computed: {
      ...mapGetters({
        userId: 'security/id',
      }),
      STATUS_CREATE_COMPONENT: function () {
        return CREATE_COMPONENT;
      },
      STATUS_CODERS_WAITING_PAYMENT: function () {
        return CODERS_WAITING_PAYMENT;
      },
      sortedItems() {
        return this.components.sort((a, b) => moment(a.endDate) - moment(b.endDate))
      },
      currentUserId() {
        return this.$store.getters['security/id'];
      }
    },
    methods: {
      isPaymentAllowed(component) {
        let payments = component.payments.items;
        for (let payment of payments) {
          if (payment.status === CODERS_ALLOWED_PAYMENT) {
            return true;
          }
        }
        return false;
      },
      isOverdue(date) {
        return moment().isAfter(moment(date));
      },
      handleView() {
      },
      isHourly(component) {
        return component.type === CODERS_DELEGATION_SKILLS
      },
      isFixed(component) {
        return component.type === CODERS_FIXED_PRICE;
      },
      getComponentUrl(component) {
        this.$store.commit('components/SET_FETCH_COMPONENTS', {component: component});
        this.$router.push({
          path: `/dashboard/edit-component/${component.uuid}`
        })
      }
    },
    created() {
      this.$store.dispatch('components/getComponentList', {uuid: this.user_uuid}).then(res => {
        this.components = res;
        this.$emit('update-length', this.components.length);
      });
    },
    mounted() {
    }
  };
</script>
