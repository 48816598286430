<template>
    <div class="fluid-container footer dash-footer">
        <div class="container">
            <div class="footer-inside">
                <div class="navbar-text">
                        Let's code together
                </div>
                <div class="social-icons navbar-brand">
                        <ul>
                            <li><a href="https://www.facebook.com/the.coding.machine/" target="_blank" title="Facebook"><facebook-icon></facebook-icon></a></li>
                            <li><a href="https://github.com/thecodingmachine" target="_blank" title="Github"><github-icon></github-icon></a></li>
                            <li><a href="https://www.instagram.com/inside_thecodingmachine/" target="_blank" title="Instagram"><instagram-icon></instagram-icon></a></li>
                            <li><a href="https://www.linkedin.com/company/the-coding-machine/" target="_blank" title="LinkedIn"><linkedin-icon></linkedin-icon></a></li>
                            <li><a href="https://twitter.com/coding_machine" target="_blank" title="Twitter"><twitter-icon></twitter-icon></a></li>
                            <li><a href="https://www.youtube.com/channel/UCw6p2FZ2q2Fj1iGFcIQ_73w" target="_blank" title="YouTube"><youtube-icon></youtube-icon></a></li>
                        </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        FacebookIcon,
        InstagramIcon,
        GithubIcon,
        YoutubeIcon,
        LinkedinIcon,
        TwitterIcon
    } from 'vue-feather-icons'
    export default {
        name: "Footer",
        components: {
            FacebookIcon,
            InstagramIcon,
            GithubIcon,
            YoutubeIcon,
            LinkedinIcon,
            TwitterIcon
        },
        computed: {},
        methods: {
            goHome() {
                this.$router.push('/');
            }
        },
        created() {},
        mounted() {},
    };
</script>
