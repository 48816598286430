import _ from 'lodash';
import {apolloClient} from '../graphql';
import codersQuery from '../graphql/coder/query/coders.gql';
import getCoderQuery from '../graphql/coder/query/coderById.gql';
import managerCodersQuery from '../graphql/coder/query/managerCoders.gql';
import codersListQuery from '../graphql/coder/query/acceptedCoders.gql';
import coderComponentQuery from '../graphql/coder/query/coder_component.gql';
import adminsQuery from '../graphql/coder/query/get_admins.gql';
import coderMutation from '../graphql/coder/mutation/coder.gql';
import uploadMutation from '../graphql/coder/mutation/upload.gql';
import componentStatusUpdateMutation from '../graphql/coder/mutation/component_status_update.gql';
import deleteCoderMutation from '../graphql/coder/mutation/deleteCoder.gql';
import fetchComponentByUuid from '../graphql/coder/query/fetchComponentByUuid.gql';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    coders: [],
    coder: {},
    allCoders: [],
    components: [],
    admins: [],
    fetchCoders: [],
    codersFilter: ""
  },
  getters: {
    isLoading: state => {
      return state.isLoading;
    },
    hasError: state => {
      return state.error !== null;
    },
    error: state => {
      return state.error;
    },
    getCoders: state => {
      return state.coders;
    },
    getAllCoders: state => {
      return state.allCoders;
    },
    getCoder: state => {
      return state.coder;
    },
    getCoderComponents: state => {
      return state.components;
    },

    getCoderComponentById: state => uuid => {
      const component = state.components.filter(component => component.uuid === uuid);
      return component[0] || {};
    },
    getComponentById: state => uuid => {
      let component;

      for (let key in state.coders) {
        if (state.coders[key].components !== undefined) {
          component = state.coders[key].components.items.filter(component => component.uuid === uuid);
          if (component[0]) {
            break;
          }
        } else {
          component = state.coders[key].payments.filter(payment => {
            return payment.component.uuid === uuid;
          });
        }
      }
      return component[0] || {};
    },
    component: state => {
      return state.component;
    },
    getAdmins: state => {
      return state.admins;
    },
    codersFilter: state => {
      return state.codersFilter;
    }
  },
  mutations: {
    ['GET_CODERS_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['GET_CODERS_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.coders = payload;
    },

    ['GET_CODERS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['GET_CODER_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
      state.coder = {};
    },
    ['GET_CODER_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.coder = payload;
    },
    ['GET_CODER_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },

    ['GET_ALL_CODERS_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['GET_ALL_CODERS_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.allCoders = payload;
    },
    ['GET_ALL_CODERS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['GET_CODER_COMPONENT_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['GET_CODER_COMPONENT_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      const index = state.coders.findIndex(
       coder => coder.uuid === payload.userId
      );
      const coders = [...state.coders];
      if (index === -1) {
        // not found
        coders.push(payload.components);
      } else {
        coders[index].components = payload.components;
      }
      state.coders = coders;
      state.components = payload.components;
      state.admins = this.getAdmins();
    },
    ['GET_CODER_COMPONENT_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['ADD_CODER_COMPONENT'](state, payload) {
      state.isLoading = true;
      state.error = null;
      const index = state.coders.findIndex(
       coder => coder.uuid === payload.userId
      );
      const coders = [...state.coders];
      if (index > -1) {
        // not found
        const items = [payload.component, ...coders[index].components.items];
        coders[index].components.items = items;
      }
      state.coders = coders;
    },
    ['ADD_COMPONENT_PAYMENTS'](state, payload) {
      const index = state.coders.findIndex(
       coder => coder.uuid === payload.userId
      );
      const coders = [...state.coders];
      if (index > -1) {
        // not found
        const components = coders[index].components.items;
        const comIndex = components.findIndex(
         component => component.uuid === payload.componentId
        );
        components[comIndex].payments.items = [...components[comIndex].payments.items, ...payload.payments]
        coders[index].components.items = components;
      }
      state.coders = coders;
    },
    ['UPDATE_CODER_COMPONENT'](state, payload) {
      state.isLoading = true;
      state.error = null;
      const index = state.coders.findIndex(
       coder => coder.uuid === payload.userId
      );
      const coders = [...state.coders];
      if (index > -1) {
        // not found
        const componentItems = coders[index].components.items;
        const updatedComponent = payload.component;
        const compIndex = componentItems.findIndex(
         component => component.uuid === updatedComponent.uuid
        );
        coders[index].components.items[compIndex] = updatedComponent;
      }
      state.coders = coders;
    },
    ['UPDATE_CODER_COMPONENT_STATUS'](state, payload) {
      state.isLoading = true;
      state.error = null;
    },
    ['SAVE_CODER_REQUEST'](state) {
      state.isLoading = true;
      state.error = null;
    },
    ['SAVE_CODER_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.coders = [...state.coders, payload];
    },
    ['SAVE_CODER_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['GET_ADMINS_REQUEST'](state) {
      state.isLoading = false;
      state.error = null;
    },
    ['GET_ADMINS_SUCCESS'](state, payload) {
      state.isLoading = false;
      state.error = null;
      state.admins = payload;
    },
    ['GET_ADMINS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    ['CLEAR_CODER'](state) {
      state.coders = [];
      state.components = [];
      state.codersFilter = "";
    },
    ['DELETE_CODER_SUCCESS'](state, payload) {
      const index = state.coders.findIndex(
       coder => coder.uuid === payload.uuid
      );
      const coders = [...state.coders];
      if (index > -1) {
        coders.splice(index, 1);
      }
      state.coders = coders;
    },
    ['SET_CODERS_FILTER'](state, payload) {
      state.codersFilter = payload;
    }
  },
  actions: {
    getCoders({commit}) {
      commit('GET_CODERS_REQUEST');
      return apolloClient
       .query({
         query: codersQuery
       })
       .then(res => {
         commit('GET_CODERS_SUCCESS', res.data.coders.items);
         apolloClient.cache.reset();
       })
       .catch(err => commit('GET_CODERS_ERROR', err));
    },
    getCoderById({commit}, payload) {
      commit('GET_CODER_REQUEST');
      return apolloClient
       .query({
         query: getCoderQuery,
         variables: {
           coderId: payload.uuid
         }
       })
       .then(res => {
         commit('GET_CODER_SUCCESS', res.data.coderById);
         apolloClient.cache.reset();
       })
       .catch(err => commit('GET_CODER_ERROR', err));
    },
    getManagerCoders({commit}) {
      commit('GET_CODERS_REQUEST');
      return apolloClient
       .query({
         query: managerCodersQuery
       })
       .then(res => {
         let coders = res.data.managerCoders.items.map(function (coder) {
           coder['components'] = coder['managerComponents'];
           delete coder['managerComponents'];
           return coder;
         });
         commit('GET_CODERS_SUCCESS', coders);
         apolloClient.cache.reset();
       })
       .catch(err => commit('GET_CODERS_ERROR', err));
    },
    resetStore() {
      apolloClient.cache.reset();
      apolloClient.resetStore();
    },
    getAllCoders({commit}) {
      commit('GET_ALL_CODERS_REQUEST');
      return apolloClient
       .query({
         query: codersListQuery
       })
       .then(res => commit('GET_ALL_CODERS_SUCCESS', res.data.acceptedCodersList.items))
       .catch(err => commit('GET_ALL_CODERS_ERROR', err));
    },
    getCoderComponent({commit}) {
      commit('GET_CODER_COMPONENT_REQUEST');
      return apolloClient
       .query({
         query: coderComponentQuery
       })
       .then(res => {
          commit('GET_CODER_COMPONENT_SUCCESS', {
            components: res.data.components.items
          });
          apolloClient.cache.reset();
        }
       )
       .catch(err => commit('GET_CODER_COMPONENT_ERROR', err));
    },
    getAdmins({commit}) {
      commit('GET_ADMINS_REQUEST');
      return apolloClient
       .query({query: adminsQuery})
       .then(res => commit('GET_ADMINS_SUCCESS', res.data.admins.items))
       .catch(err => commit('GET_ADMINS_ERROR', err));
    },
    addCoderComponent({commit}, payload) {
      commit('ADD_CODER_COMPONENT', payload);
      apolloClient.cache.reset();
    },
    addComponentPayments({commit}, payload) {
      commit('ADD_COMPONENT_PAYMENTS', payload);
    },
    updateCoderComponent({commit}, payload) {
      commit('UPDATE_CODER_COMPONENT', payload);
      apolloClient.cache.reset();
    },
    updateCoderComponentStatus({commit}, {componentId, status, callback}) {
      commit('UPDATE_CODER_COMPONENT_STATUS');
      return apolloClient
       .mutate({
         mutation: componentStatusUpdateMutation,
         variables: {
           componentId,
           status,
         }
       })
       .then(res => {
         commit('UPDATE_CODER_COMPONENT_STATUS', res.data.notification);
         apolloClient.cache.reset();
         callback();
       })
       .catch(err => commit('UPDATE_CODER_COMPONENT_STATUS', err));
    },
    saveCoder({ commit }, { coder, role, sendEmail, createAccount, callback }) {
      commit('SAVE_CODER_REQUEST');
      return apolloClient
       .mutate({
         mutation: coderMutation,
         variables: {
           coder,
           role,
           sendEmail,
           createAccount
         }
       })
       .then(res => {
         commit('SAVE_CODER_SUCCESS', res.data.saveCoder);
         callback(res.data.saveCoder);
       })
       .catch(err => {
         commit('SAVE_CODER_ERROR', err)
       });
    },
    deleteCoder({commit}, {coderId, callback}) {
      return apolloClient
       .mutate({
         mutation: deleteCoderMutation,
         variables: {
           coderId,
         }
       })
       .then(res => {
         commit('DELETE_CODER_SUCCESS', res.data.deleteCoder);
         callback();
       });
    },
    uploadFile({commit}, {
      file, imageName,
      imageSize,
      imageType, callback
    }) {
      return apolloClient
       .mutate({
         mutation: uploadMutation,
         variables: {
           file,
           imageName,
           imageSize,
           imageType,
         }
       })
    },
    clearCoders({commit}) {
      commit('CLEAR_CODER');
    },
    setCodersFilter({ commit }, payload) {
      commit('SET_CODERS_FILTER', payload);
    }
  }
};
